import Vue from "vue"
import Router from "vue-router"
Vue.use(Router)

const DISABLE_CUSTOM_DOMAINS = process.env.NODE_ENV === "development"

/** Fix duplicate routing freeze on router.push */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
            console.info(error)
        } else {
            console.info(error)
        }
    })
}

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
    const options = routerOptions
        ? routerOptions
        : createDefaultRouter(ssrContext).options
    let routesDirectory = null
    let baseUrl = null
    let baseHostname = null
    let newRoutes = options.routes

    const forcedCustomDomain =
        ssrContext?.runtimeConfig?.public?.forcedCustomDomain ||
        (process.client
            ? window?.__NUXT__?.config?.forcedCustomDomain
            : undefined)

    const hostname =
        forcedCustomDomain ||
        (ssrContext
            ? ssrContext.req.headers.host.replace("www.", "")
            : location.host.replace("www.", ""))

    if (!DISABLE_CUSTOM_DOMAINS || forcedCustomDomain) {
        /*
     The following code sets a routesDirectory variable depending on whether the code is running on the server or client side and whether the ssrContext object is present or not.
     The code also filters and modifies the options.routes array to only include routes that are in the current directory and removes the directory from the path and name properties of the route objects.
     */
        if (process.server && ssrContext?.nuxt && ssrContext?.req) {
            baseUrl = process.env.BASE_URL || "http://localhost"
            const isCustomDomain = !baseUrl.includes(hostname)

            routesDirectory = isCustomDomain ? "customdomain" : ""
            ssrContext.nuxt.routesDirectory = routesDirectory
        }
        if (process.client) {
            const nuxt = window.__NUXT__
            if (nuxt?.routesDirectory) routesDirectory = nuxt.routesDirectory
            if (nuxt?.config.baseURL) baseUrl = nuxt.config.baseURL
        }
        if (baseUrl) {
            baseHostname = new URL(baseUrl).hostname.replace("www.", "")
        }

        if (routesDirectory) {
            const customRootComponent = options.routes.find(
                (r) => r.path === `/${routesDirectory}/*`
            )
            newRoutes = options.routes
                .filter((route) => {
                    // remove routes from other directories
                    const toRemove =
                        routesDirectory === "customdomain" ? "" : "customdomain"
                    return !isUnderDirectory(route, toRemove)
                })
                .map((route) => {
                    // remove directory from path and name
                    if (isUnderDirectory(route, routesDirectory)) {
                        return {
                            ...route,
                            path:
                                route.path.substring(
                                    routesDirectory.length + 1
                                ) || "/",
                            name: route.name || "index",
                        }
                    }
                    if (customRootComponent?.component) {
                        return {
                            ...route,
                            component: customRootComponent.component,
                        }
                    }
                    return route
                })
        }
    }

    let router = new Router({
        ...options,
        routes: newRoutes,
    })
    router.resolve = function (to, current, append) {
        let result = router.constructor.prototype.resolve.call(
            this,
            to,
            current,
            append
        )
        if (baseHostname !== hostname && !DISABLE_CUSTOM_DOMAINS) {
            result.href = baseUrl + result.href
        }
        return result
    }

    return router
}

function isUnderDirectory(route, directory) {
    const path = route.path
    return path === "/" + directory || path.startsWith("/" + directory + "/")
}
