export const LS_RATING_QUEUE = "rating_queue"
export const LS_AUTO_CHAT_SENDING_FLAG = "is_chat_auto_sending_active"

export function getRates(user_id, append_jobs = false) {
    return this.get(`/api/v2/jobs/ratings`, {
        user_id,
        append_jobs: Number(append_jobs),
    })
}
export function rateJob(job_id, user_id, rating) {
    return this.post(
        "/api/v2/jobs/ratings/",
        { job_id, user_id, rating },
        { progress: false }
    )
}

export function rateJobAnonymous(job_id, anonymous_user_id, rating) {
    return this.post(
        "/api/v2/jobs/anonymous_ratings/",
        { job_id, anonymous_user_id, rating },
        { progress: false }
    )
}

export function removeRating(ratingId) {
    return this.del(`/api/v2/jobs/ratings/${ratingId}`)
}
