const regionSearchRouting = {
    getRouting(currentRoute) {
        return [
            {
                name: "Alles",
                route: {
                    name: "region-slug",
                    params: { slug: currentRoute.params.locationSlug },
                },
            },
            {
                name: "Ausbildung",
                route: {
                    name: "region-slug-ausbildung",
                    params: { slug: currentRoute.params.locationSlug },
                },
            },
            {
                name: "Praktikum",
                route: {
                    name: "region-slug-ausbildung",
                    params: { slug: currentRoute.params.locationSlug },
                    query: { praktikum: null },
                },
            },
            {
                name: "Unternehmen",
                route: {
                    name: "region-slug-unternehmen",
                    params: { slug: currentRoute.params.locationSlug },
                },
            },
            {
                name: "Neuigkeiten",
                route: {
                    name: "region-slug-news",
                    params: { slug: currentRoute.params.locationSlug },
                },
            },
        ]
    },
}
export default regionSearchRouting
