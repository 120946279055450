import Vue from "vue"
import {
    OABALYTICS_SURVEY_OPEN,
    OABALYTICS_SURVEY_REMOVE,
} from "@/enums/oabalytics"

const STORAGE_FLAG_NAME = "survey"
const DELAY_TIMER = 120 // seconds

let mountedComponent = null
let Component = null
let timerTimeout = {}
let ctx = null

export const DEFAULT_SURVEY_NAME = "general"
export const BUSINESS_PROFILE_COMPLETED = "business_profile_completed"

export default ({ app }, inject) => {
    if (!process.client) return
    const show = async (name = DEFAULT_SURVEY_NAME) => {
        if (mountedComponent || !app.$auth.loggedIn || _inLS(name) || !ctx)
            return
        if (app.$can.admin()) return
        if (!Component) {
            const Survey = await _getSurveyImport(name)
            const SurveyCard = Vue.component("SurveyCard", Survey.default)
            Component = new SurveyCard({
                parent: ctx,
                data: () => ({ providedName: name }),
            })
        }
        const ele = document.getElementById("app")
        const node = document.createElement("div")
        node.setAttribute("id", "surveyCard")
        if (ele) {
            ele.appendChild(node)
            mountedComponent = Component.$mount("#surveyCard")
            app.$oabalytics.trackEvent(OABALYTICS_SURVEY_OPEN)
            console.info(`Survey "${name}" shown`)
        }
    }

    const remove = () => {
        if (!mountedComponent) return
        mountedComponent.$destroy()
        mountedComponent.$el.parentNode.removeChild(mountedComponent.$el)
        mountedComponent = null
        app.$oabalytics.trackEvent(OABALYTICS_SURVEY_REMOVE)
        console.info("Survey removed")
    }

    /**
     *
     * @param {Number} time is seconds
     */
    const startTimer = (name = DEFAULT_SURVEY_NAME, time = DELAY_TIMER) => {
        if (timerTimeout[name]) return
        timerTimeout[name] = setTimeout(() => {
            show(name)
            delete timerTimeout[name]
        }, time * 1000)
        console.info(`Survey timer for "${name}" started (${time}s)`)
    }
    const cancelTimer = (name = DEFAULT_SURVEY_NAME) => {
        if (!timerTimeout[name]) return
        clearTimeout(timerTimeout[name])
        delete timerTimeout[name]
        console.info("Survey timer canceled")
    }

    const confirm = (name = DEFAULT_SURVEY_NAME) => {
        _addLS(name)
    }

    app.mixins.push({
        mounted() {
            ctx = this
        },
    })
    inject("survey", {
        startTimer,
        cancelTimer,
        show,
        remove,
        confirm,
    })
}

function _getSurveyImport(name) {
    if (name === BUSINESS_PROFILE_COMPLETED) {
        return import("@/components/user/BusinessSurvey")
    }
    return import("@/components/user/Survey")
}

//
// localStorage handlers
//

/** Sets array of values to localstorage */
function _setLS(array) {
    if (!Array.isArray(array)) return
    localStorage.setItem(STORAGE_FLAG_NAME, JSON.stringify(array))
}

/** Adds survey name to localstorage */
function _addLS(name) {
    const lsItems = _getLS()
    /* Avoids duplicates */
    if (lsItems.includes(name)) return
    lsItems.push(name)
    _setLS(lsItems)
}

/**
 * @returns {Array} array of survey names
 */
function _getLS() {
    let lsItems = []
    try {
        lsItems = JSON.parse(localStorage.getItem(STORAGE_FLAG_NAME) ?? "[]")
    } catch (e) {
        console.error(e)
    }
    if (!Array.isArray(lsItems)) return []
    return lsItems
}

/** Checks if survey is already completed in localStorage */
function _inLS(name) {
    return _getLS().includes(name)
}
