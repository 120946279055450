import Vue from "vue"

export default function ({ app }) {
    app.mixins = app.mixins || []
    app.mixins.push({
        async mounted() {
            const RatingSnackbar = await import(
                "@/components/global/RatingSnackbar"
            )
            const SnackbarComponent = Vue.component(
                "ErrorSnackbar",
                RatingSnackbar.default
            )
            const Snackbar = new SnackbarComponent({ parent: this })
            let element = document.getElementById("__nuxt")
            let elementNode = document.createElement("div")
            elementNode.setAttribute("id", "ratingSnackbar")
            element.appendChild(elementNode)
            Snackbar.$mount("#ratingSnackbar")
        },
    })
}
