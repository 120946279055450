export function getLivestreams() {
    return this.get("api/livestreams")
}

export function getLivestream(id) {
    return this.get(`api/livestreams/${id}`)
}

export function createLivestream(data) {
    return this.post("/api/livestreams", data)
}

export function updateLivestream(id, data) {
    return this.put(`/api/livestreams/${id}`, data)
}

export function toogleLivestreamChannel(id, channelSlug, value) {
    return this.post(`/api/livestreams/${id}/channel/${channelSlug}`, {
        active: value,
    })
}

export function uploadSchedule(data) {
    return this.post(`/api/livestream/upload_schedule`, data)
}
