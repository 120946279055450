function update(id, data) {
    return this.$axios.$put(`/api/trainee/${id}`, data).then(({ data }) => data)
}

function getTrainee(id) {
    return this.$axios.$get("/api/trainee/" + id).then(({ data }) => data)
}

const traineeService = {
    update,
    getTrainee,
}

export default traineeService
