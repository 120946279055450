import { v4 as uuidv4 } from "uuid"
import extend from "@/utils/extend-vue-app"
import { campaignUrl } from "@/modules/oabalytics/helper"

const IS_DISABLED = false
const USER_ID_KEY = "session_id"
const CAMPAIGN_ID_KEY = 'oabalytics.campaign'
const SOURCE_KEY = 'oabalytics.source'
const MEDIUM_KEY = 'oabalytics.medium'
let newUserId;
export default ({ store, app, route }, inject) => {
    app.router.after
    const track = (data) => {
        if (IS_DISABLED) return
        if (store.state.account.isImpersonated) return
        if (localStorage.getItem(USER_ID_KEY) === null) {
            newUserId = uuidv4()
            localStorage.setItem(USER_ID_KEY, newUserId)
        }
        data.campaign_id = localStorage.getItem(CAMPAIGN_ID_KEY) || undefined
        data.source = localStorage.getItem(SOURCE_KEY) || undefined
        data.medium = localStorage.getItem(MEDIUM_KEY) || undefined
        app.$axios
            .post(
                "/api/oabalytics",
                {
                    oabalytics_user_id: newUserId ?? localStorage.getItem(USER_ID_KEY),
                    screen_width: window?.innerWidth,
                    screen_height: window?.innerHeight,
                    data: [data],
                },
                { progress: false }
            )
            .catch((e) => {
                console.warn(e)
            })
    }

    const trackEvent = (
        type,
        payload,
        entityType = undefined,
        entityId = undefined
    ) =>
        track({
            event: type,
            entity_type: "event",
            tracked_entity_type: entityType,
            tracked_entity_id: entityId,
            payload: JSON.stringify(payload) || null,
        })

    const trackImpression = (type, id) =>
        track({
            entity_type: "impression",
            tracked_entity_type: type,
            tracked_entity_id: id,
        })

    const trackPageVisit = (route) => {
        const campaign = route?.query?.utm_id
        const medium = route?.query?.utm_medium
        const source = route?.query?.utm_source

        if (campaign || medium || source) {
            updateLocalStorage(CAMPAIGN_ID_KEY, campaign)
            updateLocalStorage(SOURCE_KEY, source)
            updateLocalStorage(MEDIUM_KEY, medium)
        }

        track({
            route: route.fullPath,
            entity_type: "page_visit",
        })
    }

    const resetUser = () => {
        localStorage.removeItem(USER_ID_KEY)
        localStorage.removeItem(MEDIUM_KEY)
        localStorage.removeItem(SOURCE_KEY)
        localStorage.removeItem(CAMPAIGN_ID_KEY)
    }

    inject("oabalytics", {
        trackPageVisit,
        trackEvent,
        trackImpression,
        resetUser,
        campaignUrl
    })

    extend(app, {
        mounted() {
            app.$oabalytics.trackPageVisit(route)
        },
        watch: {
            $route(to) {
                if (process.client) {
                    trackPageVisit(to)
                }
            },
        },
    })
}

function updateLocalStorage(key, value) {
    if (value) {
        localStorage.setItem(key, value)
    } else {
        localStorage.removeItem(key)
    }
}
