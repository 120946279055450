let isRequestingPermission = false
let swRegistration = null
let inRegistration = false
const legacyNotifications = {}
let actions = {}

/**
 * [local] Registers & awaits serviceWorker if supported and not yet available
 * @returns void
 */
async function _registerServiceWorker() {
    if (swRegistration || inRegistration) return
    inRegistration = true
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.addEventListener("message", _callbackExecutor)
        swRegistration = await navigator.serviceWorker.ready

        inRegistration = false
    }
}

/**
 * [local] Executed by the seriveWorker to execute preserved action with context from here
 * @param {Object} data action identifiers and execution payload
 */
function _callbackExecutor({ data }) {
    if (data.action === "" || data.action === undefined) data.action = "default" // Use default action for unclear case (e.g. firefox)
    actions[data.identifier]?.[data.action]?.(data.payload)
    delete actions[data.identifier]
}

/**
 * Requests & awaits permission if not accepted or denied
 * @returns if permission is granted
 */
async function requestPermission() {
    await _registerServiceWorker()
    if (!("Notification" in window)) {
        console.warn("This browser does not support desktop notification")
        return false
    }
    if (Notification.permission === "denied") return false
    if (Notification.permission !== "granted") {
        isRequestingPermission = true
        await Notification.requestPermission()
        isRequestingPermission = false
    }
    if (Notification.permission === "granted") return true
    return false
}

/** Sends a notificaton using the available method
 * Actions: Use callback functions.
 *   Add "default: true" to use action as default on notification-box click.
 */
async function send(identifier, message) {
    if (!(await requestPermission())) return
    if (swRegistration) {
        /* Maps message-actions to local variable to preserve context. 
           Adds default action if set */
        if (message.actions) {
            actions[identifier] = {}
            message.actions.map((action, index) => {
                actions[identifier][index] = action.action
                if (action.default === true) {
                    actions[identifier]["default"] = action.action
                }
                action.action = index
                return action
            })
        }
        /* Sends notification to serviceWorker */
        swRegistration.active.postMessage({
            action: "sendNotification",
            data: { identifier, message },
        })
    } else {
        const defaultAction = message.actions.find((action) => action.default)
        if (defaultAction) {
            message.onclick = defaultAction // legacy action click listener.
        }
        delete message.actions // Action buttons are not supported by legacy implementation
        legacyNotifications[identifier] = new Notification(
            message.title,
            message
        )
    }
}

/**
 * Cancels active notification(s) and deletes actions by identifier
 * @param {*} identifier notification identifier previously provided with send call.
 */
async function cancel(identifier) {
    if (swRegistration) {
        swRegistration.active.postMessage({
            action: "cancelNotification",
            data: { identifier },
        })
    } else {
        const notification = legacyNotifications[identifier]
        notification?.close()
        delete legacyNotifications[identifier]
    }
    delete actions[identifier]
}

export default function (_, inject) {
    inject("notification", {
        requestPermission,
        send,
        cancel,
        isRequestingPermission,
    })
}
