export default {
    sendAdminBroadcast(channel, body, type, sendMessage) {
        return this.$axios
            .post("/api/admin/chat/broadcast", {
                channel: channel,
                body: body,
                type: type,
                send_messages: sendMessage,
            })
            .then(({ data }) => data)
    },
    /**
     *
     * @param {*} senderId user id of sender
     * @param {*} type
     * @param {*} content Object of body, image_id and/or file_id
     * @param {*} user_ids Array of user ids to send boardcast to
     * @returns Axios
     */
    sendBroadcast(senderId, type, content, userIds = undefined) {
        return this.$axios
            .post("/api/chat/messages/broadcast", {
                sender: senderId,
                body: content.body || undefined,
                file_id: content.file_id || undefined,
                image_id: content.image_id || undefined,
                type: type,
                user_ids: userIds,
            })
            .then(({ data }) => data)
    },
    getConversationOf(receiverId) {
        return this.$axios
            .$post("/api/chat/conversations", {
                receiver_id: receiverId,
            })
            .then(({ data }) => data)
    },
    sendMessage({ body, file_id, image_id, receiver_id, conversation_id }) {
        return this.$axios
            .$post(`/api/chat/messages/`, {
                body,
                file_id,
                image_id,
                conversation_id,
                receiver_id,
            })
            .then(({ data }) => data)
    },
}
