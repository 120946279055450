export default {
    getAllThemes() {
        return this.$axios.$get("/api/v2/themes").then(({ data }) => data)
    },
    createTheme(businessId, type, theme) {
        return this.$axios
            .$post("/api/v2/themes", {
                business_id: businessId,
                type: type,
                theme: theme,
            })
            .then(({ data }) => data)
    },
    updateTheme(themeId, theme) {
        return this.$axios
            .$put(`/api/v2/themes/${themeId}`, { theme: theme })
            .then(({ data }) => data)
    },
    deleteTheme(jobSlug, themeId) {
        return this.$axios.$delete(`/api/v2/jobs/${jobSlug}`, themeId)
    },
}
