export const campaignUrl = (
    url,
    { campaignId = undefined, source = undefined, medium = undefined }
) => {
    let urlObject = new URL(url)
    const searchparams = urlObject.searchParams
    updateSearchParam(searchparams, "utm_id", campaignId)
    updateSearchParam(searchparams, "utm_source", source)
    updateSearchParam(searchparams, "utm_medium", medium)
    return urlObject.toString()
}

function updateSearchParam(searchParams, key, value) {
    if (value) {
        searchParams.set(key, value)
    } else {
        searchParams.delete(key)
    }
}
