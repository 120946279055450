
/**
 * https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VProgressCircular/VProgressCircular.ts
 *
 * Mostly, a copy of the Vuetify component, but with a default slot and a gradient.
 */
import { Intersect } from "vuetify/lib/directives"

export default {
    name: "ProgressCircular",
    directives: { Intersect },
    props: {
        /** Rotating animation - no percentage displayed by default */
        indeterminate: {
            type: Boolean,
            default: false,
        },
        /** For a dark background */
        dark: {
            type: Boolean,
            default: false,
        },
        /** Initial rotation (deg) */
        rotate: {
            type: [Number, String],
            default: -90,
        },
        /** Diameter (pixels) */
        size: {
            type: [Number, String],
            default: 70,
        },
        /** Width (pixels) */
        width: {
            type: [Number, String],
            default: 4,
        },
        /** Value (percentage 0..100) */
        value: {
            type: [Number, String],
            default: 0,
        },
    },
    data: () => ({
        radius: 20,
        isVisible: true,
    }),
    computed: {
        calculatedSize() {
            return Number(this.size)
        },

        circumference() {
            return 2 * Math.PI * this.radius
        },

        classes() {
            return {
                "progress-circular--visible": this.isVisible,
                "progress-circular--indeterminate": this.indeterminate,
            }
        },

        normalizedValue() {
            if (this.value < 0) {
                return 0
            }

            if (this.value > 100) {
                return 100
            }

            return parseFloat(this.value)
        },

        strokeDashArray() {
            return Math.round(this.circumference * 1000) / 1000
        },

        strokeDashOffset() {
            return (
                ((100 - this.normalizedValue) / 100) * this.circumference + "px"
            )
        },

        strokeWidth() {
            return (Number(this.width) / +this.size) * this.viewBoxSize * 2
        },

        styles() {
            return {
                height: this.convertToUnit(this.calculatedSize),
                width: this.convertToUnit(this.calculatedSize),
            }
        },

        svgStyles() {
            return {
                transform: `rotate(${Number(
                    this.indeterminate ? 0 : this.rotate
                )}deg)`,
            }
        },

        viewBoxSize() {
            return this.radius / (1 - Number(this.width) / +this.size)
        },
    },
    methods: {
        convertToUnit(str, unit = "px") {
            if (str == null || str === "") {
                return undefined
            } else if (isNaN(+str)) {
                return String(str)
            } else {
                return `${Number(str)}${unit}`
            }
        },
        /* Exported to be defined in SVGGradient.vue */
        // genGradient() {
        //     return this.$createElement("defs", {}, [
        //         this.$createElement(
        //             "linearGradient",
        //             {
        //                 attrs: {
        //                     id: "gradient",
        //                     x1: "0%",
        //                     y1: "0%",
        //                     x2: "0%",
        //                     y2: "100%",
        //                 },
        //             },
        //             [
        //                 this.$createElement("stop", {
        //                     attrs: {
        //                         offset: "0%",
        //                         // $colorGradient1
        //                         "stop-color": "#3643EF",
        //                     },
        //                 }),
        //                 this.$createElement("stop", {
        //                     attrs: {
        //                         offset: "100%",
        //                         // $colorGradient3
        //                         "stop-color": "#9400D1",
        //                     },
        //                 }),
        //             ]
        //         ),
        //     ])
        // },
        genCircle(name, offset) {
            return this.$createElement("circle", {
                class: {
                    [`progress-circular__${name}`]: true,
                    [`progress-circular__${name}__dark`]: this.dark,
                    [`progress-circular__${name}__light`]: !this.dark,
                },
                attrs: {
                    fill: "transparent",
                    cx: 2 * this.viewBoxSize,
                    cy: 2 * this.viewBoxSize,
                    r: this.radius,
                    "stroke-width": this.strokeWidth,
                    "stroke-dasharray": this.strokeDashArray,
                    "stroke-dashoffset": offset,
                },
            })
        },
        genSvg() {
            const children = [
                this.indeterminate || this.genCircle("underlay", 0),
                this.genCircle("overlay", this.strokeDashOffset),
                /* Exported to be defined in SVGGradient.vue */
                // this.genGradient()
            ]

            return this.$createElement(
                "svg",
                {
                    style: this.svgStyles,
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: `${this.viewBoxSize} ${this.viewBoxSize} ${
                            2 * this.viewBoxSize
                        } ${2 * this.viewBoxSize}`,
                    },
                },
                children
            )
        },
        genInfo() {
            return this.$createElement(
                "div",
                {
                    staticClass: "progress-circular__info",
                    class: {
                        [`progress-circular__info__dark`]: this.dark,
                        [`progress-circular__info__light`]: !this.dark,
                    },
                },
                this.genDefaultSlot()
            )
        },
        genDefaultSlot() {
            if (this.$slots.default) return this.$slots.default
            if (this.indeterminate) return undefined
            return `${this.value}%`
        },
        onObserve(entries, observer, isIntersecting) {
            this.isVisible = isIntersecting
        },
    },
    render(h) {
        return h(
            "div",
            {
                staticClass: "progress-circular",
                attrs: {
                    role: "progressbar",
                    "aria-valuemin": 0,
                    "aria-valuemax": 100,
                    "aria-valuenow": this.indeterminate
                        ? undefined
                        : this.normalizedValue,
                },
                class: this.classes,
                directives: [
                    {
                        name: "Intersect",
                        value: this.onObserve,
                    },
                ],
                style: this.styles,
                on: this.$listeners,
            },
            [this.genInfo(), this.genSvg()]
        )
    },
}
