export default {
    createJobInterest(traineeId, jobId, message, file_ids) {
        return this.$axios.$post("/api/job_interests", {
            job_id: jobId,
            trainee_id: traineeId,
            message: message,
            file_ids,
        })
    },
    hasJobInterest(traineeId, jobId) {
        return this.$axios
            .$get("/api/job_interests", {
                params: {
                    job_id: jobId,
                    trainee_id: traineeId,
                },
            })
            .then(({ data }) => data)
    },
    getJobBySlug(slug) {
        return this.$axios.$get("/api/jobs/" + slug).then(({ data }) => data)
    },
    updateJob(slug, data) {
        return this.$axios.put(`/api/jobs/${slug}`, data)
    },
    createJob(data) {
        return this.$axios.post("/api/jobs/", data)
    },
    getJobs(businessSlug) {
        return this.$axios
            .$get("/api/jobs", {
                params: {
                    business: businessSlug,
                },
            })
            .then(({ data }) => data)
    },
    getJobsWithAdditionsalParams(params) {
        return this.$axios
            .$get("/api/v2/jobs", {
                params: params,
            })
            .then(({ data }) => data)
    },
    getSystemJobs() {
        return this.$axios.$get("/api/system_jobs").then(({ data }) => data)
    },
    checkLimit() {
        return this.$axios.$get("/api/v2/jobs/check_limit")
    },
    createJobv2(data) {
        return this.$axios.post("/api/v2/jobs/", data)
    },
    updateJobv2(slug, data) {
        return this.$axios.put(`/api/v2/jobs/${slug}`, data)
    },
    getJobStatistics(jobSlug, platform) {
        return this.$axios
            .$get(`/api/v2/jobs/${jobSlug}/statistics`, {
                params: { platform },
            })
            .then(({ data }) => data)
    },
    getJobFeatures(permissions) {
        return this.$axios
            .$get("/api/v2/jobs/features", {
                params: {
                    permissions,
                },
            })
            .then(({ data }) => data)
    },
}
