import { STATUS_REJECTED, STATUS_APPROVED } from "@/services/v2/review.service"

/**
 * Generates a preview message for (e.g. ChatList component) for every action-message.
 */
const messagePreviews = {
    /**
     * @param message The full message Object delivered by the backend
     * @param isGroupMessage
     * @return The generated preview String
     */
    getPreview(message, isGroupMessage = false) {
        if (message && Object.keys(message).length === 0) {
            return ""
        }

        let prefixSender = ""
        if (
            isGroupMessage &&
            message?.type !== "system" &&
            message?.sender_name
        ) {
            prefixSender = message.sender_name + ": "
        }
        if (message?.type === "regular" && message.is_deleted) {
            return "🚫 Nachricht gelöscht"
        }
        if (message?.type === "image") {
            if (message.is_deleted) {
                return "🚫 Foto gelöscht"
            }
            return prefixSender + "📷 Foto"
        }
        if (message?.type === "file") {
            if (message.is_deleted) {
                return "🚫 Dokument gelöscht"
            }
            return prefixSender + "🖇 Dokument"
        }
        if (message?.type != "action") {
            return prefixSender + message.body || ""
        }
        let jsonBody = JSON.parse(message.body)
        switch (jsonBody.action) {
            case "schoolBusinessSiteNetworkRequest":
                return (
                    "🌐 Netzwerk Anfrage" +
                    (jsonBody.params?.additional_message
                        ? ": " + jsonBody.params?.additional_message
                        : "")
                )
            case "eventCreated":
                return (
                    "✨ Neues Event" +
                    (jsonBody.params?.event.title
                        ? ": " + jsonBody.params?.event.title
                        : "")
                )
            case "jobInterest":
                return (
                    "📝 Neue Anfrage" +
                    (jsonBody.params?.job.name
                        ? " für " + jsonBody.params?.job.name
                        : "")
                )
            case "jobPublishmentRequest":
                return `📤 Review Anfrage für ${jsonBody.params?.job.name}`
            case "jobReviewCreated":
                return `📤 Review Anfrage für ${jsonBody.params?.review.job.name}`
            case "jobPublished": // Legacy
                return `🚀 Stelle veröffentlicht: ${jsonBody.params?.job.name}`
            case "internshipWeekCreated": // Legacy
                return `🚀 ${jsonBody.params?.internshipWeek.name} eingetragen`
            case "jobReviewed":
                if (jsonBody.params?.review.status === STATUS_APPROVED) {
                    return `🚀 Stelle veröffentlicht: ${jsonBody.params?.review.job.name}`
                }
                if (jsonBody.params?.status === STATUS_REJECTED) {
                    return `Veröffentlichung abgelehnt: ${jsonBody.params?.review.job.name}`
                }
                return `Stelle reviewed: ${jsonBody.params?.review.job.name}` // Unexpected case
            case "articlePublishmentRequest":
                return `Artikel zur Veröffentlichung angefragt`
            default:
                return "Neue Nachricht"
        }
    },
}
export default messagePreviews
