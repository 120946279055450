import { COOKIE_CONSENT, COOKIE_LIFETIME_LONG } from "@/enums/cookies"

export const state = () => ({
    consentCookie: null,
    showCookieConsent: false,
    showCookieSettings: false,
})

export const actions = {
    init({ state, commit }) {
        let cookie = this.$cookies.get(COOKIE_CONSENT)
        if (cookie) {
            commit("SET_CONSENT_COOKIE", cookie)
        }
        if (state.consentCookie === null) {
            commit("SHOW_COOKIE_CONSENT", true)
        }
    },
    /* Expects object with all set: "marketing, functional, performance" */
    setCookie({ commit }, cookie) {
        commit("SET_CONSENT_COOKIE", cookie)
        this.$cookies.set(COOKIE_CONSENT, cookie, {
            maxAge: COOKIE_LIFETIME_LONG,
        })
    },
    showCookieConsent({ commit }, value) {
        commit("SHOW_COOKIE_CONSENT", value)
    },
    showCookieSettings({ commit }, value) {
        commit("SET_SHOW_COOKIE_SETTINGS", value)
    },
}

export const mutations = {
    SHOW_COOKIE_CONSENT(state, value) {
        state.showCookieConsent = value
    },
    SET_SHOW_COOKIE_SETTINGS(state, value) {
        state.showCookieSettings = value
    },
    SET_CONSENT_COOKIE(state, { marketing, performance, functional }) {
        state.consentCookie = {
            marketing,
            performance,
            functional,
        }
        state.showCookieConsent = false
    },
}

export const getters = {
    cookie: (state) => (state.consentCookie ? state.consentCookie : false),
    showCookieConsent: (state, _, rootState) => {
        return !rootState.auth.loggedIn && state.showCookieConsent
    },
    showCookieSettings: (state, _, rootState) => {
        return !rootState.auth.loggedIn && state.showCookieSettings
    },
    functionalCookieAllowed: (state, _, rootState) => {
        if (!state.consentCookie) return false
        return rootState.auth.loggedIn || state.consentCookie.functional
    },
    performanceCookieAllowed: (state, _, rootState) => {
        if (!state.consentCookie) return false
        return rootState.auth.loggedIn || state.consentCookie.performance
    },
    marketingCookieAllowed: (state, _, rootState) => {
        if (!state.consentCookie) return false
        return rootState.auth.loggedIn || state.consentCookie.marketing
    },
}
