function getProducts() {
    return this.$axios.$get(`/api/store/products`).then(({ data }) => data)
}

function buyProduct(product, { business_id }) {
    return this.$axios
        .$post(`/api/store/products/${product}/buy`, { business_id })
        .then(({ data }) => data)
}

function addSubscription(businessSlug, subscription) {
    return this.$axios
        .$post(`/api/v2/business/${businessSlug}/subscriptions`, {
            product_id: subscription.product.id,
            price: subscription.price,
            ends_at: subscription.ends_at,
            starts_at: subscription.starts_at,
            trial_before_start: subscription.trial_before_start,
            start_new_contract_period: subscription.start_new_contract_period,
            send_confirmation_mail: subscription.send_confirmation_mail,
        })
        .then(({ data }) => data)
}
function cancelSubscription(businessSlug, subscriptionId) {
    return this.$axios
        .$delete(
            `/api/v2/business/${businessSlug}/subscriptions/${subscriptionId}`
        )
        .then(({ data }) => data)
}

function updateSubscription(businessSlug, subscription) {
    return this.$axios
        .$put(
            `/api/v2/business/${businessSlug}/subscriptions/${subscription.id}`,
            {
                price: subscription.price,
                ends_at: subscription.ends_at,
                starts_at: subscription.starts_at,
            }
        )
        .then(({ data }) => data)
}

export default {
    getProducts,
    buyProduct,
    addSubscription,
    cancelSubscription,
    updateSubscription,
}
