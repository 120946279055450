function getSchool(slug) {
    return this.$axios.get("/api/schools/" + slug).then((response) => {
        return response.data.data
    })
}
function updateNetworkRequest(school_slug, business_site_id, accepted) {
    return this.$axios
        .put(`/api/schools/${school_slug}/business_site/${business_site_id}`, {
            accepted,
        })
        .then((response) => {
            return response.data
        })
}
function getNetworkRequestStatus(school_slug, business_site_slug) {
    return this.$axios
        .get(`/api/schools/${school_slug}/business_site/${business_site_slug}`)
        .then((response) => {
            return response.data
        })
}
function getBaseSchools({ regionSlug = undefined } = {}) {
    return this.$axios
        .$get("/api/schoolbases", {
            params: {
                region_slug: regionSlug,
            },
        })
        .then((response) => {
            return response.data
        })
}
function createBaseSchool(name) {
    return this.$axios
        .$post(`/api/school_bases/`, { name })
        .then((response) => {
            return response.data
        })
}
function putSchool(slug, data) {
    return this.$axios.$put(`/api/schools/${slug}`, data).then((response) => {
        return response.data
    })
}

function sendSchoolNetworkRequest(
    schoolSlug,
    business_site_slug,
    schoolNetworkRequestMessage
) {
    return this.$axios.post(
        `/api/schools/${schoolSlug}/business_site/${business_site_slug}`,
        {
            message: schoolNetworkRequestMessage,
            sent_by_type: "school",
        }
    )
}

const schoolService = {
    getSchool,
    updateNetworkRequest,
    getNetworkRequestStatus,
    getBaseSchools,
    createBaseSchool,
    putSchool,
    sendSchoolNetworkRequest,
}
export default schoolService
