export default {
    generate(template, inputs) {
        return this.$axios
            .$post("/api/v2/magic/generate", {
                template,
                inputs,
            })
            .then(({ data }) => data)
    },
}
