function getBusiness(slug) {
    return this.$axios.$get("/api/v2/business/" + slug).then((response) => {
        return response.data
    })
}
function getBusinessSite(slug) {
    return this.$axios.$get("/api/business_sites/" + slug).then((response) => {
        return response.data
    })
}

function updateBusiness(slug, data) {
    return this.$axios.$put("/api/business/" + slug, data).then((response) => {
        return response.data
    })
}

function updateBusinessContactPerson(id, businesContactPerson) {
    return this.$axios
        .$put("/api/contactpersons/" + id, businesContactPerson)
        .then((response) => {
            return response.data
        })
}

function storeBusinessContactPerson(businessContactPerson) {
    return this.$axios
        .$post("/api/contactpersons", businessContactPerson)
        .then((response) => {
            return response.data
        })
}

function storeActivities(businessSlug, activities) {
    return this.$axios
        .$post(`/api/business/${businessSlug}/activities`, activities)
        .then(({ data }) => {
            return data
        })
}

function updateBusinessSite(businessSlug, businessSite) {
    return this.$axios
        .$put(
            "/api/business/" +
                businessSlug +
                "/business_sites/" +
                businessSite.slug,
            businessSite
        )
        .then((response) => {
            return response.data
        })
}

function storeBusinessSite(businessSlug, businessSite) {
    return this.$axios
        .$post(
            "/api/business/" + businessSlug + "/business_sites",
            businessSite
        )
        .then((response) => {
            return response.data
        })
}

function getBusinessSites(businessSlug) {
    return this.$axios
        .$get("/api/business/" + businessSlug + "/business_sites")
        .then((response) => {
            return response.data
        })
}

function getBusinessSubscriptions(businessSlug) {
    return this.$axios
        .$get("/api/v2/business/" + businessSlug + "/subscriptions")
        .then(({ data }) => data)
}

function getSchoolsInNetwork(businesSlug) {
    return this.$axios
        .$get(`/api/business/${businesSlug}/schools`)
        .then(({ data }) => data)
}

function getEmptyContactPerson() {
    return {
        profile_picture: null,
        gender: "Frau",
        firstname: null,
        lastname: null,
    }
}

function updateBillingAddress(
    businessSlug,
    {
        businessName,
        contactPersonGender,
        contactPersonName,
        contactPersonPhone,
        contactPersonEmail,
        street,
        postalCode,
        city,
        countryCode,
    }
) {
    return this.$axios
        .$post(`/api/v2/business/${businessSlug}/billing_address`, {
            business_name: businessName,
            contact_person_gender: contactPersonGender,
            contact_person_name: contactPersonName,
            contact_person_phone: contactPersonPhone,
            contact_person_email: contactPersonEmail,
            street: street,
            postal_code: postalCode,
            city: city,
            country_code: countryCode,
        })
        .then((response) => {
            return response.data
        })
}

const businessAPI = {
    getBusiness,
    getBusinessSubscriptions,
    getBusinessSite,
    updateBusiness,
    updateBusinessContactPerson,
    storeBusinessContactPerson,
    storeActivities,
    updateBusinessSite,
    storeBusinessSite,
    getBusinessSites,
    getSchoolsInNetwork,
    getEmptyContactPerson,
    updateBillingAddress,
}
export default businessAPI
