async function getMaterials(channel) {
    return await this.$axios.$get(`/api/materials`, {
        params: { channel },
    })
}

async function getMaterial(id) {
    return await this.$axios.$get(`/api/materials/${id}`)
}

async function postMaterial(fileId, channelId, title, description) {
    return await this.$axios.$post(`/api/materials`, {
        file_id: fileId,
        channel_id: channelId,
        title,
        description,
    })
}

async function putMaterial(materialId, fileId, title, description) {
    return await this.$axios.$put(`/api/materials/${materialId}`, {
        file_id: fileId,
        title,
        description,
    })
}

async function deleteMaterial(id) {
    return await this.$axios.$delete(`/api/materials/${id}`)
}

const materialService = {
    getMaterial,
    getMaterials,
    putMaterial,
    postMaterial,
    deleteMaterial,
}
export default materialService
