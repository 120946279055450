export const state = () => ({
    businessesAround: [],
    trainers: [],
    displayedID: "419",
    displayedProfile: {
        apprenticeships: [],
        traineeships: [],
        jobs: [],
        applications: {
            apprenticeships: [],
            traineeships: [],
        },
        /** dummy data */
        baseInfo: {
            name: "",
            profile_picture: "",
            images: [],
            primary_address: [],
            lang: [{ about_us: "", motto: "", videoID: "" }],
        },
        reviews: [],
        reviewCount: 3,
        reviewAverage: 3.3,
    },
    search: {
        where: null,
        who: null,
    },
    businesses: [],
    systemJobs: [],
    successfullVerification: false,
    verifiedBusiness: null,
    verifiedHashCode: null,
    newLogoFile: null,
})

export const actions = {
    updateJobLike({ commit }, value) {
        commit("UPDATE_JOB_LIKE", value)
        this.$oabalytics.trackEvent("like", { slug: value.slug, type: "job" })
    },
    deleteJob({ commit }, { jobSlug, jobType }) {
        this.$axios
            .delete("/api/jobs/" + jobSlug)
            .then(() => {
                commit("DELETE_BUSINESS_OWN_JOB", { jobSlug, jobType })
            })
            .catch((error) => {
                console.error(error)
            })
    },
    deleteVideo({ commit }, businessID) {
        return this.$axios
            .post("/api/business/delete-video", { businessID: businessID })
            .then((result) => {
                commit("RESET_BUSINESS_VIDEO")
                return result
            })
    },
    getBusinessAround({ commit }) {
        var user = JSON.parse(localStorage.getItem("user"))
        var businessID = user.business_id
        this.$axios
            .post("/api/business/" + businessID + "/getBusinessesAround/", {
                distance: 30,
            })
            .then((response) => {
                commit("SET_BUSINESSES_AROUND", response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    },

    getUsedDataForJob({ commit, rootState }) {
        // for convenience, we load already used advantages and attributes in order the business can fill the form faster
        this.$axios
            .get("/api/jobattributes?slug=" + rootState.auth.user.business.slug)
            .then((response) => {
                commit("job/SET_USED_ATTRIBUTES", response.data.attributes, {
                    root: true,
                })
            })
        this.$axios
            .get("/api/jobadvantages?slug=" + rootState.auth.user.business.slug)
            .then((response) => {
                commit("job/SET_USED_ADVANTAGES", response.data.advantages, {
                    root: true,
                })
            })
    },

    loadApplications({ commit, rootState }) {
        var user = rootState.auth.user
        var businessID = user.business_id
        this.$axios
            .get("/api/application/getbybusiness/" + businessID)
            .then((r) => r.data)
            .then((response) => {
                commit("SET_BUSINESS_APPLICATIONS", response)
            })
            .catch((error) => {
                console.error(error)
            })
    },
    loadCurrentOfferedJobs({ commit }, businessID) {
        this.$axios
            .get("/api/job/getbybusiness/" + businessID)
            .then((r) => r.data)
            .then((response) => {
                commit("SET_BUSINESS_JOBS", response)
            })
            .catch((error) => {
                console.error(error)
            })
    },
    loadJobs({ commit }, businessSlug) {
        return this.$axios
            .$get("/api/jobs", {
                params: {
                    business: businessSlug,
                },
            })
            .then((response) => {
                commit("SET_BUSINESS_JOBS", response.data)
                return response.data
            })
    },
    loadJobsBySite({ commit }, businessSiteSlug) {
        return this.$axios
            .$get("/api/jobs", {
                params: {
                    business_site: businessSiteSlug,
                },
            })
            .then((response) => {
                commit("SET_BUSINESS_JOBS", response.data)
                return response.data
            })
    },
    loadBusiness({ commit }, businessName) {
        this.$axios
            .get("/api/business/seo/" + encodeURIComponent(businessName))
            .then((response) => {
                commit("SET_DISPLAYED_PROFILE", response.data)
            })
    },
    async setCurrentDisplayedProfile({ state, commit }, slug) {
        if (
            state.displayedProfile.baseInfo !== undefined &&
            state.displayedProfile.baseInfo.slug === slug &&
            !state.displayedProfile.baseInfo.business_site_slug
        ) {
            return state.displayedProfile.baseInfo
        }
        return await this.$axios
            .$get("/api/v2/business/" + slug)
            .then((response) => {
                commit("SET_DISPLAYED_PROFILE", response.data)
                return response.data
            })
    },
    async setCurrentDisplayedProfileBySite({ state, commit }, slug) {
        if (
            state.displayedProfile.baseInfo !== undefined &&
            state.displayedProfile.baseInfo.slug === slug
        ) {
            return state.displayedProfile.baseInfo
        }
        return await this.$axios
            .$get("/api/business_sites/" + slug)
            .then((response) => {
                commit("SET_DISPLAYED_PROFILE", response.data)
                return response.data
            })
    },
    setSearchParams({ commit }, { param1, param2 }) {
        commit("SET_SEARCH_PARAMS", { param1, param2 })
    },
    setBusinessLiked({ commit }, liked) {
        commit("SET_BUSINESS_LIKED", liked)
    },
    updateBusiness({ commit, state }, { slug: slug, business: business }) {
        return this.$axios
            .$put("/api/business/" + slug, {
                name: business.name,
                motto: business.motto,
                about_us: business.about_us,
                main_images: business.main_images,
                gallery_images: business.gallery_images,
                video_id: business.video_id,
                logo_image_id: business.logo_image_id,
            })
            .then((response) => {
                if (
                    this.$auth.user &&
                    this.$auth.user.business &&
                    this.$auth.user.business.id === response.data.id
                ) {
                    this.$auth.fetchUser()
                }
                if (state.displayedProfile.baseInfo.id === response.data.id) {
                    commit("SET_DISPLAYED_PROFILE", response.data)
                }
            })
    },

    async getSystemJobs({ commit }) {
        await this.$axios.get("/api/system_jobs").then((response) => {
            commit("SET_ALL_SYSTEM_JOBS", response.data.data)
        })
    },
    resetLikes({ commit }) {
        commit("RESET_LIKES")
    },
}

export const mutations = {
    DELETE_BUSINESS_OWN_JOB(state, { jobSlug, jobType }) {
        let displayedProfile = state.displayedProfile
        switch (jobType) {
            case 1:
                displayedProfile.apprenticeships =
                    displayedProfile.apprenticeships.filter(
                        (apprenticeship) => apprenticeship.slug !== jobSlug
                    )
                break
            case 2:
                displayedProfile.traineeships =
                    displayedProfile.traineeships.filter(
                        (traineeship) => traineeship.slug !== jobSlug
                    )
                break
            case 3:
                displayedProfile.jobs = displayedProfile.jobs.filter(
                    (job) => job.slug !== jobSlug
                )
                break
        }
        state.displayedProfile = displayedProfile
    },
    SET_LOGO_FILE(state, { index: index, file: file, name: name, url: url }) {
        state.newLogoFile = {
            index: index,
            fileData: file,
            name: name,
            url: url,
        }
    },
    SET_BUSINESS_LOGO_ID(state, image) {
        state.displayedProfile.baseInfo.logo_image_id = image.id
        state.displayedProfile.baseInfo.logo = image
    },
    RESET_LOGO_FILE(state) {
        state.newLogoFile = null
    },
    RESET_BUSINESS_VIDEO(state) {
        state.displayedProfile.baseInfo.lang[0].videoID = null
    },
    SET_BUSINESSES_AROUND(state, businesses) {
        state.businessesAround = businesses
    },
    SET_VERIFICATION_SUCCESS(state, result) {
        state.successfullVerification = true
        state.verifiedBusiness = result.business
        state.verifiedHashCode = result.hashCode
        state.newBusiness.business = result.business
    },
    SET_ALL_SYSTEM_JOBS(state, jobs) {
        state.systemJobs = jobs
    },
    SET_FOLLOWED_BUSINESSES(state, businesses) {
        state.businesses = businesses
    },
    SET_VIDEO_ID(state, id) {
        state.displayedProfile.baseInfo.lang[0].videoID = id
    },
    SET_SEARCH_PARAMS({ state }, { param1, param2 }) {
        state.search.where = param1
        state.search.who = param2
    },
    SET_USERID(state, userID) {
        state.displayedProfile.userID = userID
    },
    ADD_REVIEWS(state, reviews) {
        for (let r of reviews) {
            if (state.displayedProfile.reviews.indexOf(r) == -1) {
                state.displayedProfile.reviews.push(r)
            }
        }
    },
    SET_DISPLAYED_PROFILE(state, business) {
        state.displayedProfile.baseInfo = business
    },
    SET_BUSINESS_JOBS(state, items) {
        const apprenticeships = []
        const traineeships = []
        const jobs = []
        for (let i = 0; i < items.length; i++) {
            let job = items[i]
            if (job.type_id === 1) {
                apprenticeships.push(job)
            } else if (job.type_id === 2) {
                traineeships.push(job)
            } else if (job.type_id === 3) {
                if (job.content_blocks?.length > 0) {
                    const legacyData = job.content_blocks.find(
                        (el) => el.component === "legacy"
                    )
                    if (legacyData) {
                        job = { ...job, ...legacyData.data }
                    }
                }
                jobs.push(job)
            }
        }

        state.displayedProfile.apprenticeships = apprenticeships
        state.displayedProfile.traineeships = traineeships
        state.displayedProfile.jobs = jobs
    },
    SET_BUSINESS_APPLICATIONS(state, applications) {
        state.displayedProfile.applications.apprenticeships =
            applications.applicationsApprenticeships
        state.displayedProfile.applications.traineeships =
            applications.applicationTraineeships
    },
    UPDATE_JOB_LIKE(state, { slug, data }) {
        let apprenticeship = state.displayedProfile.apprenticeships.find(
            (apprenticeship) => apprenticeship.slug === slug
        )
        if (apprenticeship) {
            apprenticeship.is_liked = data
            return
        }
        let traineeship = state.displayedProfile.traineeships.find(
            (traineeship) => traineeship.slug === slug
        )
        if (traineeship) {
            traineeship.is_liked = data
        }
    },
    SET_BUSINESS_LIKED(state, value) {
        state.displayedProfile.baseInfo.is_liked = value
    },
    RESET_LIKES(state) {
        state.displayedProfile.apprenticeship &&
            state.displayedProfile.apprenticeship.forEach(
                (item) => (item.is_liked = false)
            )
        state.displayedProfile.traineeships &&
            state.displayedProfile.traineeships.forEach(
                (item) => (item.is_liked = false)
            )
    },
}

export const getters = {
    displayedBusinessID: (state) => {
        return state.displayedProfile.baseInfo.id
    },
    displayedBusiness: (state) => state.displayedProfile,
    apprenticeships: (state) => {
        return state.displayedProfile.apprenticeships
    },
    traineeships: (state) => {
        return state.displayedProfile.traineeships
    },
    jobs: (state) => {
        return state.displayedProfile.jobs
    },
    displayedBusinessName: (state) =>
        state.displayedProfile ? state.displayedProfile.baseInfo.name : "",
}
