/** ONLY used for caching storyblok instance between channel routes */
export const state = () => ({
    story: null, // storyblok story
    key: null, // identification for instance validation
})

export const mutations = {
    SET_STORY(state, story) {
        state.story = story
    },
    SET_KEY(state, key) {
        state.key = key
    },
    CLEAR(state) {
        state.key = null
        state.story = null
    },
}

export const actions = {
    setInstance({ dispatch, commit }, { story, key }) {
        dispatch("validate", key).then(() => {
            commit("SET_STORY", story)
        })
    },
    validate({ state, commit }, key) {
        if (state.key === key) return true
        commit("CLEAR")
        commit("SET_KEY", key)
        return false
    },
}

export const getters = {
    instance: (state) => ({
        story: _deepCopy(state.story),
    }),
}

/** Deepcopy object */
function _deepCopy(obj) {
    if (!obj) return obj
    return JSON.parse(JSON.stringify(obj))
}
