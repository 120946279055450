import messagePreviews from "@/components/chat/messages/actions/messagePreviews"
import sanitizeText from "@/utils/sanitize-text"
import Vue from "vue"
import {
    EVENT_CHAT_MESSAGE_SENT,
    EVENT_CHAT_MESSAGE_UPDATED,
    EVENT_CHAT_USER_TYPING,
} from "@/enums/events"
const processMessage = (message) => {
    if (message.type !== "regular") return message
    return {
        ...message,
        body: sanitizeText(message.body, true),
    }
}
export const state = () => ({
    conversations: [],
    typingEvents: {},
    conversation: null,
    notifications: [],
    messages: [],
    user: null,
    profile: null,
    business: null,
    active: false,
    loadingMessages: false,
    loadingConversation: false,
    loadingConversations: true,
    showUsers: true,
    searchQuery: "",
    searchResult: [],
    loadingSearch: false,
    start_messages: [
        {
            body: "Hallo,\nwenn du Interesse an einem unserer Berufe haben solltest und diesen ausprobieren möchtest, kannst du uns hier gerne jederzeit kontaktieren.",
            id: 0,
            sender_id: -1,
            showProfile: true,
        },
        {
            body: "Hallo,\nwie ich sehe interessierst du dich für unseren Betrieb oder unsere Berufe. Wenn du eine Frage haben solltest kannst du sie hier gerne jederzeit stellen.",
            id: 0,
            sender_id: -1,
            showProfile: true,
        },
    ],
    startMessageIndex: 0,
    isConversationPaginating: false,
    isPaginating: false,
    pagination: {
        first: null,
        last: null,
        next: null,
        prev: null,
    },
})

export const getters = {
    //Computed Getters
    isUserTyping:
        (state) =>
        ({ conversationId, userId }) => {
            const key = `typingEvents.${conversationId}.users.${userId}`
            return !!state.typingEvents[key]
        },
    getConversationById: (state) => (id) =>
        state.conversations.find((conv) => conv.id === id),
    isSelected: (state) => state.conversation != null,
    isBusiness: (state) => state.user && state.user.business != null,
    unreadMessages: (state) =>
        state.conversation && state.conversation.number_of_unread_messages,
    getTotalUnreadMessages: (state) => {
        let unreadMessages = 0
        state.conversations.forEach((conv) => {
            unreadMessages += conv.number_of_unread_messages
        })
        return unreadMessages
    },
    getUnreadMessagesOfId: (_, getters) => (id) => {
        const conv = getters.getConversationById(id)
        return conv ? conv.number_of_unread_messages : 0
    },
    displayedUser: (state) => {
        if (!state.user) return {}
        return state.user
    },
    showBusinessReplyHint: (state, _, rootState) => {
        if (!state.conversation || !state.messages) return false // No Conversation/messages selected
        if (!(rootState.auth.loggedIn && !!rootState.auth.user.business)) {
            return false // Not Business
        }
        if (state.messages.length == 0) return false // Only if messages exist
        const selfId = rootState.auth.user?.id
        if (state.user.school || state.user.business || state.user.region)
            return false // Not for non-trainee conversations
        if (
            state.messages.some(
                (msg) => msg.sender_id == selfId || msg.type == "action"
            )
        )
            return false // Not if already replied or is application
        return true
    },
    //State Getters
    isPaginating: (state) => state.isPaginating,
    isConversationPaginating: (state) => state.isConversationPaginating,
    loadingConversation: (state) => state.loadingConversation,
    loadingConversations: (state) => state.loadingConversations,
    loadingMessages: (state) => state.loadingMessages,
    conversations: (state) =>
        state.searchQuery ? state.searchResult : state.conversations,
    conversation: (state) => state.conversation,
    searchQuery: (state) => state.searchQuery,
    user: (state) => state.user,
    business: (state) => state.business,
    profile: (state) => state.profile,
    showUsers: (state) => state.showUsers,
    isBlocked: (state) => Boolean(state.conversation?.is_blocked),
    sortedConversations: (_, getters) => {
        return [...getters.conversations].sort((a, b) => {
            if (a.latest_message && b.latest_message) {
                return (
                    new Date(b.latest_message.created_at) -
                    new Date(a.latest_message.created_at) +
                    (a.id - b.id) * 0.0001 // deterministic list even for identical timesamps
                )
            }
            return -1
        })
    },
    unreadConversations(_, getters) {
        return getters.sortedConversations.filter(
            (conv) => conv.number_of_unread_messages > 0
        )
    },
    messages: (state, getters, rootState) => {
        const user = rootState.auth.user
        const selfId = user?.id
        const filteredMessages = state.messages.filter(
            (msg) =>
                typeof msg.visible_to_sender == "undefined" ||
                msg.visible_to_sender ||
                msg.sender_id !== selfId
        )
        if (state.loadingMessages) return []
        if (getters.showBusinessReplyHint) {
            filteredMessages.push({
                type: "form",
                name: "BusinessReplySuggestion",
                noTimestamp: true,
                data: state.user,
            })
        }
        if (
            filteredMessages.some(
                (msg) => msg.sender_id !== selfId || msg.type === "action"
            )
        ) {
            /* Return messages without welcomeMessage, if:
              - Business wrote message OR
              - Action message in converstaion */
            return filteredMessages
        }

        if (user?.trainee) {
            let startMessage = state.start_messages[state.startMessageIndex]
            startMessage.created_at = new Date()
            return [startMessage, ...filteredMessages]
        }

        return filteredMessages
    },
}

export const actions = {
    terminate({ commit }) {
        commit("clear")
        this.$echo.disconnect()
        console.info("Chat: Terminated")
    },
    triggerStoreSelected({ commit }) {
        commit("storeSelected")
    },
    async searchConversation({ commit }, query) {
        commit("setSearchQuery", query)
        if (!query) {
            commit("setSearchResult", [])
            return
        }
        const response = await this.$axios
            .get(`/api/chat/search/conversations?query=${query}`)
            .catch((error) => {
                console.error("ERROR Chat @ searchConversation ", error)
            })
        commit("setSearchResult", response.data.data)
    },
    loadNextConversations({ state, commit, rootState }) {
        if (!rootState.auth.loggedIn) return false
        if (!state.pagination.next) return false
        if (state.loadingConversations) return false
        commit("setConversationPaginating", true)
        return this.$axios
            .get(state.pagination.next)
            .then((response) => {
                commit("setConversationsPagination", response.data.links)
                commit("addConversations", response.data.data)
                commit("setConversationPaginating", false)
                return response.data.data
            })
            .catch((error) => {
                commit("setConversationPaginating", false)
                console.error("ERROR Chat @ loadNextConversations ", error)
                throw new Error(error)
            })
    },
    initialize({ state, commit, dispatch, rootState }) {
        if (rootState.auth.loggedIn && !state.active) {
            this.$echo
                .private("chatmessage." + rootState.auth.user.id)
                .listen(EVENT_CHAT_MESSAGE_SENT, (payload) => {
                    dispatch("receivedMessage", payload.message)
                })
                .listen(EVENT_CHAT_MESSAGE_UPDATED, (payload) => {
                    dispatch("receivedUpdatedMessage", payload.message)
                })
                .listen(EVENT_CHAT_USER_TYPING, (payload) => {
                    dispatch("receivedUserTyping", payload)
                })
            try {
                Notification.requestPermission()
            } catch (e) {
                console.info("Chat: Could not request Notification permission")
            }
            commit("INITIALIZED", true)
            dispatch("loadConversations")
            return true
        }
    },
    loadConversations({ state, commit, dispatch, rootState }) {
        if (!rootState.auth.loggedIn) return
        commit("setLoadingConversations", true)
        return this.$axios
            .$get("/api/chat/conversations")
            .then((response) => {
                commit("setConversationsPagination", response.links)
                commit("setConversations", response.data)
                commit("setLoadingConversations", false)
                if (state.conversation) {
                    const conv = response.data.find((conversation) => {
                        return conversation.id === state.conversation.id
                    })
                    if (conv) {
                        commit("setConversation", conv)
                        dispatch("loadMessages")
                        dispatch("readConversation")
                    } else if (state.conversation.dummy) {
                        /**
                         * This is true when a user sends a job request.
                         * Conversation is still dummy and the actual conversation is coming in the response.data.
                         * We need to set this conversation to active by comparing dummy conversation's business and reponse's conversation business.
                         */
                        for (const conversation of response.data) {
                            const business =
                                conversation.participants[0].business ||
                                conversation.participants[1].business
                            if (
                                business &&
                                business.slug ===
                                    state.conversation.user.business?.slug
                            ) {
                                commit("setConversation", conversation)
                                dispatch("loadMessages")
                                dispatch("readConversation")
                                break
                            }
                        }
                    }
                }
                return response.data
            })
            .catch((error) => {
                console.error("ERROR Chat @ loadConversations ", error)
                throw new Error(error)
            })
    },
    blockConversation({ commit }, { id: conversationId, value = false }) {
        commit("setBlockedState", { id: conversationId, value })
    },
    async selectConversation(
        { rootState, state, commit, dispatch },
        { id, business }
    ) {
        if (rootState.auth.loggedIn) {
            commit("storeSelected")
            if (state.conversation === null || state.id !== id) {
                commit("setLoadingConversation", true)
                commit("storeSelected")
                let conversation
                if (business) {
                    conversation = state.conversations.find((conv) => {
                        return (
                            conv.type === "single" &&
                            conv.participants.includes(business)
                        )
                    })
                }
                if (conversation) {
                    commit("setConversation", conversation)
                } else if (id) {
                    conversation = await dispatch("loadConversation", id).catch(
                        () => {
                            commit("setLoadingConversation", false)
                            commit("setShowUsers", true)
                        }
                    )
                    if (conversation) {
                        commit("setConversation", conversation)
                    }
                } else if (business) {
                    conversation = state.conversations.find(
                        (conv) => conv.user.business?.slug === business.slug
                    )
                    if (!conversation) {
                        conversation = {
                            user: {
                                id: business.user_id,
                                name: business.name,
                                profile_picture: business.logo,
                                public_contact_person:
                                    business.public_contact_person,
                                profile_url: "/betriebe/" + business.slug,
                                business: business,
                            },
                            number_of_unread_messages: 0,
                            messages: [],
                            dummy: true,
                        }
                    }
                    commit("setConversation", conversation)
                }
                commit("setLoadingConversation", false)
                dispatch("loadMessages")
                dispatch("readConversation")
            }
        } else {
            if (business) {
                const dummyConversation = {
                    user: {
                        id: business.user_id,
                        name: business.name,
                        profile_picture: business.logo,
                        profile_url: "/betriebe/" + business.slug,
                        business: business,
                        public_contact_person: business.public_contact_person,
                    },
                    number_of_unread_messages: 0,
                    messages: [],
                    dummy: true,
                }
                commit("setConversation", dummyConversation)
            }
        }
    },
    async loadConversation({ commit }, id) {
        if (!id) {
            return null
        }
        return await this.$axios
            .$get(`/api/chat/conversations/${id}`)
            .then((response) => {
                commit("addConversation", response.data)
                return response.data
            })
            .catch((error) => {
                console.error("ERROR Chat @ loadConversation ", error)
                throw new Error(error)
            })
    },
    async loadMessages({ state, commit, rootState }) {
        if (!rootState.auth.loggedIn) return
        if (
            state.conversation &&
            !state.conversation.messages &&
            !state.loadingMessages
        ) {
            if (
                !state.conversation.page ||
                state.conversation.page < state.conversation.max_page
            ) {
                commit("setLoadingMessages", true)
                let page = state.conversation.page + 1
                if (!page) {
                    page = 1
                } else {
                    commit("setPaginating", true)
                }
                return this.$axios
                    .get(
                        `/api/chat/conversations/${state.conversation.id}/messages`,
                        {
                            params: {
                                page: page,
                            },
                        }
                    )
                    .then((response) => {
                        commit("addMessages", response.data.data.reverse())
                        commit("setPagination", {
                            page: response.data.meta.current_page,
                            max: response.data.meta.last_page,
                        })
                        commit("setLoadingMessages", false)
                        setTimeout(() => {
                            commit("setPaginating", false)
                        }, 100)

                        return true
                    })
                    .catch((error) => {
                        console.error("ERROR Chat @ loadMessages", error)
                        commit("setLoadingMessages", false)
                        throw new Error(error)
                    })
            }
        }
        return false
    },
    async readConversation(
        { state, commit, getters, rootState },
        { id = null, force = false } = {}
    ) {
        if (rootState.account.isImpersonated) return
        if (this.$can.admin() && !force) return
        if (!id && state.conversation) {
            id = state.conversation.id
        }
        if (id) {
            if (
                getters.getConversationById(id)?.number_of_unread_messages > 0
            ) {
                return this.$axios
                    .post(`/api/chat/conversations/${id}/setread`)
                    .then(() => {
                        commit("setAsRead", id)
                        this.$notification.cancel(id)
                        return true
                    })
                    .catch((error) => {
                        console.error(
                            "ERROR Chat @ readSelectedMessages ",
                            error
                        )
                        throw new Error(error)
                    })
            }
        }
        return false
    },
    async readAllConversation({ state, dispatch }) {
        state.conversations.forEach((conv) => {
            dispatch("readConversation", { id: conv.id, force: true })
        })
    },
    async sendMessage(
        { state, commit, dispatch },
        { text, file_id, image_id }
    ) {
        if (state.conversation) {
            dispatch("readConversation", { force: true })
            let response = await this.$axios
                .post(`/api/chat/messages/`, {
                    body: text,
                    file_id: file_id ? file_id : undefined,
                    image_id: image_id ? image_id : undefined,
                    conversation_id: state.conversation.id,
                    receiver_id: state.user.id,
                })
                .catch((error) => {
                    console.error("ERROR Chat @ sendMessage ", error)
                    throw new Error(error)
                })
            if (!state.conversation.id) {
                let conversation = await dispatch(
                    "loadConversation",
                    response.data.data.conversation_id
                )
                commit("setConversation", conversation)
            } else {
                commit("addMessage", response.data.data)
            }

            return true
        }
        return false
    },
    //TODO - Feature not available
    async editMessage({ commit }, message) {
        commit("editMessage", message)
    },
    async deleteMessage({ commit }, message) {
        try {
            await this.$axios.delete(`/api/chat/messages/${message.id}`)
        } catch (e) {
            return false
        }
        commit("updateMessage", { ...message, is_deleted: true })
        return true
    },
    async receivedMessage({ state, dispatch, commit, getters }, message) {
        const id = message.conversation_id
        commit("setUserNotTyping", {
            conversationId: message.conversation_id,
            userId: message.sender_id,
        })
        let conversation = getters.getConversationById(id)

        if (!conversation) {
            conversation = await dispatch("loadConversation", id)
        }
        if (
            isConversationVisible(state.conversation, id) &&
            !this.$can.admin()
        ) {
            await this.$axios
                .post(`/api/chat/conversations/${id}/setread`)
                .then(() => {
                    commit("setAsRead", id)

                    this.$notification.cancel(id)
                    return true
                })
                .catch((error) => {
                    console.error("ERROR Chat @ readSelectedMessages ", error)
                    throw new Error(error)
                })
        }
        if (
            !conversation.latest_message ||
            conversation.latest_message.id !== message.id
        ) {
            commit("addMessage", message)
            if (
                !isConversationVisible(state.conversation, id) &&
                !isMessageReadByReceiver(message)
            ) {
                dispatch("notify", {
                    conversation: conversation,
                    message: message,
                })
            }
        }
    },
    async receivedUserTyping({ state, commit }, message) {
        const key = `typingEvents.${message.conversation_id}.users.${message.user_id}`
        if (state.typingEvents[key]) {
            clearTimeout(state.typingEvents[key])
        }
        const timeout = setTimeout(() => {
            commit("setUserNotTyping", {
                conversationId: message.conversation_id,
                userId: message.user_id,
            })
        }, 6000)
        commit("setUserTyping", {
            conversationId: message.conversation_id,
            userId: message.user_id,
            timeout: timeout,
        })
    },
    async receivedUpdatedMessage({ dispatch, commit, getters }, message) {
        const id = message.conversation_id
        let conversation = getters.getConversationById(id)
        if (!conversation) {
            conversation = await dispatch("loadConversation", id)
        }
        commit("updateMessage", processMessage(message))
    },
    async notify(_, { conversation, message }) {
        const preview = messagePreviews.getPreview(message)
        const count = conversation.number_of_unread_messages
        const prefix =
            count > 1 ? `${count} neue Nachrichten` : "Eine neue Nachricht"
        try {
            this.$notification.send(conversation.id, {
                title: `${prefix} von ${conversation.user.name}`,
                body: preview,
                tag: conversation.id,
                data: {
                    conversationId: conversation.id,
                    actionFallbackUrl: `${this.$config.baseURL}/chat?conversation_id=${conversation.id}`,
                },
                renotify: true,
                actions: [
                    {
                        title: "Antworten",
                        default: true,
                        action: () =>
                            this.$router.push(
                                `/chat?conversation_id=${conversation.id}`
                            ),
                    },
                    {
                        title: "Ok",
                        action: () => {},
                    },
                ],
            })
        } catch (e) {
            /* Notification is not avilable on Safari and will cause error */
            console.warn(e)
        }
    },
}

export const mutations = {
    INITIALIZED(state, value) {
        state.active = value
    },
    setSearchQuery(state, query) {
        state.searchQuery = query
    },
    setSearchResult(state, result) {
        state.searchResult = result
    },
    setLoadingConversations(state, value) {
        state.loadingConversations = value
    },
    setLoadingConversation(state, value) {
        state.loadingConversation = value
    },
    setLoadingProfile(state, value) {
        state.loadingProfile = value
    },
    setLoadingMessages(state, value) {
        state.loadingMessages = value
    },
    setShowUsers(state, value) {
        state.showUsers = value
    },
    setConversations(state, conversations) {
        state.conversations = conversations
    },
    addConversations(state, conversations) {
        const unique = conversations.filter(
            (item) =>
                state.conversation?.id !== item.id &&
                !state.conversations.some((conv) => conv.id === item.id)
        )
        state.conversations = state.conversations.concat(unique)
    },
    addConversation(state, conversation) {
        if (!conversation.latest_message) {
            conversation.latest_message = conversation.messages.reduce(
                (acc, element) => {
                    const first = new Date(element.created_at)
                    const second = new Date(acc.created_at)
                    if (first.valueOf() > second.valueOf()) return element
                    return acc
                },
                conversation.messages[0]
            )
        }
        conversation.messages = conversation.messages.map((message) =>
            processMessage(message)
        )
        if (!state.conversations.some((conv) => conv.id === conversation.id)) {
            state.conversations.push(conversation)
        }
    },
    setConversationsPagination(state, data) {
        state.pagination = data
    },
    setConversation(state, conversation) {
        state.conversation = conversation
        state.user = conversation.user
        state.business = conversation.user.business //nullable
        if (!conversation.latest_message && conversation.messages) {
            conversation.latest_message =
                conversation.messages[conversation.messages.length - 1]
        }
        if (conversation.messages) {
            state.messages = conversation.messages
        }
        state.user.public_contact_person =
            conversation.user.public_contact_person
    },
    setAsRead(state) {
        if (!state.conversation) return
        state.conversation.number_of_unread_messages = 0
        const convInStore = state.conversations.find(
            (conv) => conv.id === state.conversation.id
        )
        if (convInStore) {
            convInStore.number_of_unread_messages = 0
        }
    },
    setUserTyping(state, { conversationId, userId, timeout }) {
        const key = `typingEvents.${conversationId}.users.${userId}`
        Vue.set(state.typingEvents, key, timeout)
    },
    setUserNotTyping(state, { conversationId, userId }) {
        const key = `typingEvents.${conversationId}.users.${userId}`
        Vue.delete(state.typingEvents, key)
    },
    addMessages(state, messages) {
        if (state.messages) {
            state.messages = messages
                .concat(state.messages)
                .filter((value, index, array) => {
                    return (
                        index ===
                        array.findIndex((item) => item.id === value.id)
                    )
                })
        } else {
            state.messages = messages
        }
    },
    storeSelected(state) {
        if (!state.conversation) return
        if (!state.conversation.dummy) {
            state.conversation.messages = state.messages
            state.conversations = state.conversations.filter((conv) => {
                return conv.id !== state.conversation.id
            })
            if (state.conversation) {
                state.conversations.push(state.conversation)
            }
        }
        state.messages = []
        state.user = null
        state.business = null
        state.conversation = null
    },
    addMessage(state, message) {
        const id = message.conversation_id
        let conv = state.conversations.find((conv) => conv.id === id)
        if (state.conversation && state.conversation.id === id) {
            if (document.hidden) {
                state.conversation.number_of_unread_messages += 1
                conv.number_of_unread_messages += 1
            }
            state.messages.push(processMessage(message))
            state.conversation.latest_message = message
            return
        }
        if (conv) {
            if (conv.messages) {
                conv.messages.push(processMessage(message))
            }
            if (
                (!state.conversation || id !== state.conversation.id) &&
                !isMessageReadByReceiver(message)
            ) {
                conv.number_of_unread_messages += 1
            }
            conv.latest_message = message
        }
    },
    updateMessage(state, message) {
        const newMessageConversationId = message.conversation_id
        if (
            state.conversation &&
            state.conversation.id === newMessageConversationId
        ) {
            const messageToUpdate = state.messages.find(
                (m) => m.id === message.id
            )
            if (messageToUpdate !== undefined) {
                Object.assign(messageToUpdate, message)
            }
            if (state.conversation.latest_message.id === message.id) {
                state.conversation.latest_message = message
            }
        }

        let conv = state.conversations.find(
            (conv) => conv.id === newMessageConversationId
        )
        if (conv) {
            if (conv.messages) {
                const messageToUpdate = conv.messages.find(
                    (m) => m.id === message.id
                )
                if (messageToUpdate !== undefined) {
                    Object.assign(messageToUpdate, message)
                }
            }
            if (conv.latest_message.id === message.id) {
                conv.latest_message = message
            }
        }
    },
    setBlockedState(state, { id: conversationId, value }) {
        if (state.conversation && state.conversation.id === conversationId) {
            state.conversation.is_blocked = value
            return
        }
        let conv = state.conversations.find(
            (conv) => conv.id === conversationId
        )
        if (conv) {
            conv.is_blocked = value
        }
    },
    setPagination(state, { page, max }) {
        if (state.conversation) {
            state.conversation.page = page
            state.conversation.max_page = max
        }
    },
    clear(state) {
        state.conversations = []
        state.conversation = null
        state.messages = []
        state.user = null
        state.business = null
        state.active = false
        state.loadingMessages = false
        state.loadingConversation = false
        state.loadingConversations = false
    },
    setPaginating(state, value) {
        state.isPaginating = value
    },
    setConversationPaginating(state, value) {
        state.isConversationPaginating = value
    },
    setStartMessageIndex(state, startMessageIndex) {
        state.startMessageIndex = startMessageIndex
    },
}

/** Helpers */
function isMessageReadByReceiver(message) {
    const receiverMeta = message.meta.find((meta) => meta.is_sender == 0)
    return !!receiverMeta.read_at
}

function isConversationVisible(selectedConversation, id) {
    if (!selectedConversation) return false // No conversation selected
    if (selectedConversation.id !== id) return false // Other conversation selected
    if (document.hidden) return false // Other tab selected
    return true
}
