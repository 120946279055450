import postService from "@/services/post.service"

export const state = () => ({
    posts: [],
    displayedPost: null,
    displayType: null,
})

export const getters = {
    displayedPost: (state) => state.displayedPost,
}

export const actions = {
    setDisplayedPost({ commit }, { post, displayType }) {
        commit("SET_DISPLAYED_POST", { post, displayType })
    },
    async getPostBySlug({ commit }, slug) {
        const post = await postService.getPost(slug)
        commit("SET_DISPLAYED_POST", { post })
        return post
    },
    async getPostsByBusiness({ commit }, businessSlug) {
        const posts = await postService.getPosts({
            business: businessSlug,
        })
        commit("SET_POSTS", posts)
        return posts
    },
}

export const mutations = {
    SET_DISPLAYED_POST(state, { post, displayType }) {
        state.displayedPost = post
        state.displayType = displayType
    },
    SET_POSTS(state, posts) {
        state.posts = posts
    },
}
