const schoolSearchRouting = {
    getRouting(currentRoute) {
        return [
            {
                name: "Alles",
                route: {
                    name: "schule-slug-schwarzes-brett",
                    params: { slug: currentRoute.params.locationSlug },
                },
            },
        ]
    },
}
export default schoolSearchRouting
