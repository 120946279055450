function requestOTP(phone_number) {
    return this.$axios.$post("/api/auth/verify/send-otp", {
        phone_number,
    })
}

function verifyOTP(phone_number, code) {
    return this.$axios.$post("/api/auth/verify-otp", {
        phone_number,
        code,
    })
}

function checkUser(mode, identifier) {
    return this.$axios.$post("/api/auth/check", {
        mode,
        identifier,
    })
}

function verifyEmail(email, password, code) {
    return this.$axios.$post("/api/auth/verify", {
        email: email,
        password: password,
        code: code,
    })
}

function requestEmailCode(email, password) {
    return this.$axios.$post("/api/auth/verify/resend", {
        email: email,
        password: password,
    })
}

function requestPasswordRest(email) {
    return this.$axios.$post("/api/password_request", {
        email: email,
    })
}

/** @string service: Expects google, facebook or apple */
function getSocialUrl(service) {
    return this.$axios
        .$get("/api/auth/social/" + service)
        .then(({ redirectURL }) => redirectURL)
}

function registerBusiness(newBusiness, channel = null) {
    return this.$axios.post("/api/businesses", {
        newBusiness: newBusiness,
        channel: channel?.slug,
    })
}

const authService = {
    requestOTP,
    verifyOTP,
    checkUser,
    verifyEmail,
    requestEmailCode,
    requestPasswordRest,
    getSocialUrl,
    registerBusiness,
}
export default authService
