import userService from "@/services/user.service"
import postService from "@/services/post.service"
import schoolAPI from "@/services/school.service"
import businessAPI from "@/services/business.service"
import fileService from "@/services/file.service"
import regionService from "@/services/region.service"
import chatAPI from "@/services/chat.service"
import eventService from "@/services/event.service"
import livestreamService from "@/services/livestream.service"
import adminService from "@/services/admin.service"
import searchService from "@/services/search.service"
import authService from "@/services/auth.service"
import jobService from "@/services/job.service"
import traineeService from "@/services/trainee.service"
import locationService from "@/services/location.service"
import jobFairService from "@/services/jobFair.service"
import materialService from "@/services/material.service"
import contactService from "@/services/contact.service"
import tutorialService from "@/services/tutorial.service"
import storeService from "@/services/store.service"
import listsService from "@/services/lists.service"
import videoService from "@/services/video.service"
import themesService from "@/services/themes.service"
import surveyService from "@/services/survey.service"
import hesseService from "@/services/hesse.service"

export default function (app) {
    if (!app.$axios) {
        console.error("Please make sure $axios module is added")
    } else {
        livestreamService.$axios = app.$axios
        searchService.$axios = app.$axios
        listsService.$axios = app.$axios
        userService.$axios = app.$axios
        fileService.$axios = app.$axios
        eventService.$axios = app.$axios
        regionService.$axios = app.$axios
        postService.$axios = app.$axios
        schoolAPI.$axios = app.$axios
        businessAPI.$axios = app.$axios
        adminService.$axios = app.$axios
        chatAPI.$axios = app.$axios
        authService.$axios = app.$axios
        jobService.$axios = app.$axios
        traineeService.$axios = app.$axios
        locationService.$axios = app.$axios
        jobFairService.$axios = app.$axios
        materialService.$axios = app.$axios
        contactService.$axios = app.$axios
        tutorialService.$axios = app.$axios
        storeService.$axios = app.$axios
        videoService.$axios = app.$axios
        themesService.$axios = app.$axios
        surveyService.$axios = app.$axios
        hesseService.$axios = app.$axios
    }
}
