const jobFairSearchRouting = {
    getRouting(currentRoute) {
        return [
            {
                name: "Alles",
                route: {
                    name: "messe-locationSlug",
                    params: { locationSlug: currentRoute.params.locationSlug },
                },
            },
            {
                name: "Ausbildung",
                route: {
                    name: "messe-locationSlug-ausbildung",
                    params: { locationSlug: currentRoute.params.locationSlug },
                },
            },
            {
                name: "Praktikum",
                route: {
                    name: "messe-locationSlug-praktikum",
                    params: { locationSlug: currentRoute.params.locationSlug },
                },
            },
            {
                name: "Unternehmen",
                route: {
                    name: "messe-locationSlug-unternehmen",
                    params: { locationSlug: currentRoute.params.locationSlug },
                },
            },
            {
                name: "Neuigkeiten",
                route: {
                    name: "messe-locationSlug-news",
                    params: { locationSlug: currentRoute.params.locationSlug },
                },
            },
        ]
    },
}
export default jobFairSearchRouting
