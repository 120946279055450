// Autogenerated file! Do not modify!

export const EVENT_LIVESTREAM_MESSAGE_DELETED = "Livestream\\MessageDeleted"
export const EVENT_LIVESTREAM_NEW_LIVESTREAM_EVENT =
    "Livestream\\NewLivestreamEvent"
export const EVENT_LIVESTREAM_VIEWER_COUNT_CHANGED =
    "Livestream\\ViewerCountChanged"
export const EVENT_LIVESTREAM_ALL_USER_MESSAGES_DELETED =
    "Livestream\\AllUserMessagesDeleted"
export const EVENT_LIVESTREAM_UPDATED = "Livestream\\Updated"
export const EVENT_LIVESTREAM_LIVESTREAM_POLL_UPDATED =
    "Livestream\\LivestreamPollUpdated"
export const EVENT_LIVESTREAM_NEW_MESSAGE = "Livestream\\NewMessage"
export const EVENT_OABALYTICS_BUSINESS_NEW_BUSINESS_VIEW =
    "Oabalytics\\Business\\NewBusinessView"
export const EVENT_NEW_ADMIN_MESSAGE = "NewAdminMessage"
export const EVENT_DASHBOARD_NEW_TICKER_ELEMENT = "Dashboard\\NewTickerElement"
export const EVENT_CHAT_MESSAGE_UPDATED = "Chat\\MessageUpdated"
export const EVENT_CHAT_MESSAGE_SENT = "Chat\\MessageSent"
export const EVENT_CHAT_USER_TYPING = "Chat\\UserTyping"
export const EVENT_SCHOOL_BULLETIN_BOARD_UNLOCKED =
    "School\\BulletinBoardUnlocked"
export const EVENT_USER_VERIFIED = "UserVerified"
