
const keyCodes = Object.freeze({
    up: 38,
    down: 40,
    left: 37,
    right: 39,
})

import { VDialog } from "vuetify/lib/components"
export default {
    name: "FixedDialog",
    extends: VDialog,
    props: {
        /** Allows scrolling of other components which are not parents of the v-dialog, while v-dialog is opened */
        overlayScrolling: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        scrollListener: function scrollListener(e) {
            if (e.type === "keydown") {
                if (
                    ["INPUT", "TEXTAREA", "SELECT"].includes(
                        e.target.tagName
                    ) ||
                    e.target.isContentEditable
                )
                    return

                const up = [keyCodes.up, keyCodes.pageup]
                const down = [keyCodes.down, keyCodes.pagedown]

                if (up.includes(e.keyCode)) {
                    e.deltaY = -1
                } else if (down.includes(e.keyCode)) {
                    e.deltaY = 1
                } else {
                    return
                }
            }

            if (
                e.target === this.overlay ||
                (e.type !== "keydown" && e.target === document.body) ||
                (this.checkPath(e) && !this.overlayScrolling)
            )
                e.preventDefault()
        },
        shouldScroll: function shouldScroll(el, e) {
            if (el.hasAttribute("data-app")) return false
            var dir = e.shiftKey || e.deltaX ? "x" : "y"
            var delta = dir === "y" ? e.deltaY : e.deltaX || e.deltaY
            var alreadyAtStart
            var alreadyAtEnd
            const style = window.getComputedStyle(el)

            if (dir === "y") {
                if (style.flexDirection === "column-reverse") {
                    alreadyAtEnd = el.scrollTop === 0
                    alreadyAtStart =
                        el.scrollTop - el.clientHeight === -1 * el.scrollHeight
                } else {
                    alreadyAtStart = el.scrollTop === 0
                    alreadyAtEnd =
                        el.scrollTop + el.clientHeight === el.scrollHeight
                }
            } else {
                alreadyAtStart = el.scrollLeft === 0
                alreadyAtEnd = el.scrollLeft + el.clientWidth === el.scrollWidth
            }

            var scrollingUp = delta < 0
            var scrollingDown = delta > 0
            if (!alreadyAtStart && scrollingUp) return true
            if (!alreadyAtEnd && scrollingDown) return true

            if (alreadyAtStart || alreadyAtEnd) {
                return this.shouldScroll(el.parentNode, e)
            }

            return false
        },
    },
}
