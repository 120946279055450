import Vue from "vue"
import { EVENT_SCHOOL_BULLETIN_BOARD_UNLOCKED } from "@/enums/events"

export const state = () => ({
    isInit: false,
    isListenerInit: false,

    /** Current School */
    name: null,
    type: null,
    slug: null,
    city: null,
    about_us: null,
    video_id: null,
    logo_image_id: null,
    logo: null,
    main_image: {},
    main_images: [],
    trainee_invitation_link: null,
    gallery_images: [],
    primary_address: [],
    user_id: null,
    profile_picture: null,
    images: [],
    business_sites_in_network: [],
    is_minibat_unlocked: false,
    created_at: "",
})

export const actions = {
    reset({ commit }) {
        commit("RESET")
    },
    initUpdateListener({ state, commit }) {
        if (
            !state.isListenerInit &&
            this.$auth.user &&
            this.$auth.user.school
        ) {
            this.$echo
                .private("school." + this.$auth.user.school.id)
                .listen(EVENT_SCHOOL_BULLETIN_BOARD_UNLOCKED, (payload) => {
                    commit("UPDATE_DISPLAYED_PROFILE", {
                        is_minibat_unlocked: payload.is_minibat_unlocked,
                        trainee_invitation_link:
                            payload.trainee_invitation_link,
                        trainee_invitation_qr_code:
                            payload.trainee_invitation_qr_code,
                    })
                })
            commit("SET_LISTENER_STATUS", true)
        }
    },
    async setCurrentDisplayedProfile({ state, dispatch }, slug) {
        if (state.isInit && state.slug === slug) {
            return state
        }
        return await dispatch("loadProfile", slug)
    },
    async loadProfile({ commit }, slug) {
        try {
            let response = await this.$axios.$get("/api/schools/" + slug)
            commit("SET_DISPLAYED_PROFILE", response.data)
            return response.data
        } catch (exception) {
            console.warn("School has no bulletin board: ", slug, exception)
            commit("RESET")
            throw new Error(exception)
        }
    },
    updateLocal({ commit }, data) {
        commit("UPDATE_DISPLAYED_PROFILE", data)
    },
    uploadImage(_, image) {
        return this.$axios.post("/api/images", { image }).then((response) => {
            return response.data.data
        })
    },
    async updateSchool({ state, commit }, slug) {
        const data = state
        if (!data) return

        let requestObject = {
            about_us: data.about_us ?? undefined,
        }
        if (data.main_image) {
            requestObject.images = data.main_image.id
        }

        const school = await this.$axios.put(
            "/api/schools/" + slug,
            requestObject
        )
        commit("SET_DISPLAYED_PROFILE", school.data.data)
    },
    changeSchool({ commit }, value) {
        commit("UPDATE_DISPLAYED_PROFILE", value)
    },
}

export const mutations = {
    UPDATE_DISPLAYED_PROFILE(state, data) {
        for (const [key, value] of Object.entries(data)) {
            Vue.set(state, key, value)
        }
    },
    SET_DISPLAYED_PROFILE(state, school) {
        for (var member in state) delete state[member]
        for (const [key, value] of Object.entries(school)) {
            Vue.set(state, key, value)
        }
        state.isInit = true
    },
    SET_ABOUT_US(state, aboutUsText) {
        state.about_us = aboutUsText
    },
    RESET(state) {
        state.isInit = false
        state.isListenerInit = false
        state.name = null
        state.type = null
        state.slug = null
        state.city = null
        state.about_us = null
        state.video_id = null
        state.logo_image_id = null
        state.logo = null
        state.channel = {}
        state.main_image = null
        state.main_images = []
        state.gallery_images = []
        state.primary_address = []
        state.user_id = null
        state.profile_picture = null
        state.images = []
        state.business_sites_in_network = []
        state.is_minibat_unlocked = null
        state.created_at = ""
    },
    SET_LISTENER_STATUS(state, isInit) {
        state.isListenerInit = isInit
    },
}

export const getters = {
    displayedSchool: (state) => state,
    mainImage: (state) => state.main_image,
    aboutUs: (state) => state.about_us,
    name: (state) => state.name,
    city: (state) => state.city,
    slug: (state) => state.slug,
    channel: (state) => state.channel,
    type: (state) => state.type,
    businessSitesInNetwork: (state) => state.business_sites_in_network,
    traineeInvitationLink: (state) => state.trainee_invitation_link,
    isInit: (state) => state.isInit,
    traineeInvitationQrCode: (state) => state.trainee_invitation_qr_code,
    isBulletinBoardUnlocked: (state) => state.is_minibat_unlocked,
    created_at: (state) => state.created_at,
}
