export const OABALYTICS_STORE_TABS = "storePreview_tab"
export const OABALYTICS_STORE_ADD = "storePreview_selection_add"
export const OABALYTICS_STORE_REMOVE = "storePreview_selection_remove"
export const OABALYTICS_STORE_CLICK = "storePreview_click"
export const OABALYTICS_STORE_ANNUAL = "storePreview_annual"
export const OABALYTICS_STORE_NUMJOBS = "storePreview_numjobs"
export const OABALYTICS_PRODUCT_CLICK = "storeProduct_click"
export const OABALYTICS_ACTIVITY_FILTER = "activity_filter"
export const OABALYTICS_ACTIVITY_SET = "activity_set"
export const OABALYTICS_INDEX_CLICK = "index_click"
export const OABALYTICS_INDEX_SELECT = "index_input_select"
export const OABALYTICS_BTN_CLICK = "button-click"
export const OABALYTICS_SURVEY_OPEN = "survey-open"
export const OABALYTICS_SURVEY_COMPLETE = "survey-complete"
export const OABALYTICS_SURVEY_REMOVE = "survey-remove"
export const OABALYTICS_FIRST_LIKE_OPEN = "first-like_open"
export const OABALYTICS_FIRST_LIKE_CONFIRM = "first-like_confirm"
export const OABALYTICS_FIRST_LIKE_CHAT_SETTING = "first-like_chat-setting"
export const OABALYTICS_LOGIN_LIKE_OPEN = "login-to-like_open"
export const OABALYTICS_LOGIN_LIKE_CHAT_SETTING = "login-to-like_chat-setting"
export const OABALYTICS_LOGIN_LIKE_WARNING = "login-to-like_warning"
export const OABALYTICS_LOGIN_LIKE_WARNING_CLOSE = "login-to-like_warning_close"
export const OABALYTICS_LOGIN_LIKE_WARNING_CONTINUE =
    "login-to-like_warning_continue"
export const OABALYTICS_RATING_CARD_OPEN = "rating-card_open"
export const OABALYTICS_RATING_CARD_CLOSE = "rating-card_close"
export const OABALYTICS_RATING_CARD_RATE = "rating-card_rate"
export const OABALYTICS_EINBLICK_MODAL_OPEN = "einblick_modal_open"
export const OABALYTICS_RATING_SLIDER = "rating_slider"
export const OABALYTICS_RATING_MENU = "rating_menu"
export const OABALYTICS_RATING_CARD = "rating_card"
export const OABALYTICS_APPRENTICESHIP_APPLY_BTN = "apprenticeship_apply_btn"

export const OABALYTICS_SEARCH_LIST_OPEN = "search_list_open"
export const OABALYTICS_APPLY_CLICK = "job_apply_click"
export const OABALYTICS_JOB_EINBLICK_UPGRADE = "job_einblick_upgrade"
export const OABALYTICS_JOB_EINBLICK_UPGRADE_DETAILS =
    "job_einblick_upgrade_details"
export const OABALYTICS_EDITOR_EINBLICK_UPGRADE_DETAILS =
    "editor_einblick_upgrade_details"
export const OABALYTICS_APPLY_SHOW = "job_apply_show"

export const OABALYTICS_SHARE_MODAL_OPEN = "share_modal_open"

export const OABALYTICS_CHAT_WITH_NIKO = "chat_with_niko_click"
export const AUTH_DIALOG_STATE = "auth_dialog_state"
