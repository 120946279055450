import md5 from "js-md5"
import defaultSearchRouting from "@/models/defaultSearchRouting"
import jobFairSearchRouting from "@/models/jobFairSearchRouting"
import schoolSearchRouting from "@/models/schoolSearchRouting"
import regionSearchRouting from "@/models/regionSearchRouting"

function getCategories({ type = "default", route }) {
    let categories = []
    switch (type) {
        case "messe":
            categories = jobFairSearchRouting.getRouting(route)
            break
        case "school":
            categories = schoolSearchRouting.getRouting(route)
            break
        case "region":
            categories = regionSearchRouting.getRouting(route)
            break
        default:
            categories = defaultSearchRouting.getRouting(route)
    }
    return categories
}

export const state = () => ({
    isSearching: false,
    location_search: null,
    searchModal: false,
    location: {
        slug: null,
        name: null,
        latitude: null,
        longitude: null,
    },
    selectedCategory: "Alles",
    categories: [
        {
            name: "Alles",
            route: {
                name: "a-locationSlug",
            },
        },
        {
            name: "Ausbildung",
            route: {
                name: "ausbildung-locationSlug",
            },
        },
        {
            name: "Praktikum",
            route: {
                name: "ausbildung-locationSlug",
            },
            query: { praktikum: null },
        },
        {
            name: "Unternehmen",
            route: {
                name: "unternehmen-locationSlug",
            },
        },
        {
            name: "Neuigkeiten",
            route: {
                name: "news-locationSlug",
            },
        },
    ],
    active: false,
    history: [],
    searches: {},
    filters: {},
    results: [],
    showNavFilterSearch: false,
    filterName: "",
    channel: "oabat",
    jobFairs: null,
    type: "default" /* Search type: default, messe, region or school */,
    scope: "Ausbildung",
})

export const actions = {
    async init(
        { state, commit },
        {
            categories,
            selectedCategory,
            locationSlug,
            query,
            channel,
            name,
            searchComplete = false,
            type = "default",
        }
    ) {
        commit("SET_TYPE", type)
        if (categories !== undefined) {
            commit("SET_CATEGORIES", categories)
        }
        if (selectedCategory !== undefined) {
            commit("SET_SELECTED_CATEGORY", selectedCategory)
        }
        if (channel !== undefined) {
            commit("SET_CHANNEL", channel)
        }
        if (query !== undefined) {
            commit("SET_FILTERS", query)
        }
        if (state.location.slug !== locationSlug) {
            let newLocation = {
                slug: locationSlug,
                name: name || state.location.name,
            }
            if (searchComplete) {
                commit("SET_LOCATION", newLocation)
                commit("SET_IS_ACTIVE", true)
                return true
            } else if (locationSlug === "nearby") {
                newLocation.name = "Umgebung"
                if (
                    query.latitude !== undefined &&
                    query.longitude !== undefined
                ) {
                    newLocation.longitude = query.longitude
                    newLocation.latitude = query.latitude
                }
                commit("SET_LOCATION", newLocation)
                commit("SET_IS_ACTIVE", true)
                return true
            } else {
                if (type == "school" || type === "region" || type == "messe") {
                    newLocation.name = "Umgebung"
                    newLocation.longitude = locationSlug.longitude
                    newLocation.latitude = locationSlug.latitude
                    commit("SET_LOCATION", newLocation)
                    commit("SET_IS_ACTIVE", true)
                    return true
                }
                let zipRegex = /zip-(.*)-([0-9]+)$/
                if (zipRegex.test(newLocation.slug)) {
                    let locationID = newLocation.slug.match(zipRegex)
                    locationID = locationID[2]
                    newLocation.id = locationID
                    await this.$axios
                        .$get("/api/v2/locations/zip/" + locationID)
                        .then((response) => {
                            commit("SET_LOCATION", response.data)
                            commit("SET_FILTERS", {
                                ...state.filters,
                                latitude: response.data.latitude,
                                longitude: response.data.longitude,
                            })
                            commit("SET_IS_ACTIVE", true)
                            return true
                        })
                } else {
                    let regex = /-([0-9]+)$/
                    let locationID = newLocation.slug.match(regex)

                    if (!locationID || !locationID.length > 1) {
                        try {
                            this.$sentry.captureMessage(
                                "Unknown locationSlug: " +
                                    newLocation +
                                    "|| " +
                                    locationID
                            )
                        } catch (err) {
                            console.warn("No sentry", err)
                        }
                        console.error(
                            "Unknown locationSlug:",
                            newLocation,
                            locationID
                        )
                        return
                    }
                    locationID = locationID[1]
                    newLocation.id = locationID
                    await this.$axios
                        .$get("/api/v2/locations/" + locationID)
                        .then((response) => {
                            commit("SET_LOCATION", response.data)
                            commit("SET_FILTERS", {
                                ...state.filters,
                                latitude: response.data.latitude,
                                longitude: response.data.longitude,
                            })

                            commit("SET_IS_ACTIVE", true)
                            return true
                        })
                }
            }
        }
        if (state.location) {
            commit("SET_FILTERS", {
                ...state.filters,
                latitude: state.location.latitude,
                longitude: state.location.longitude,
            })
        }
    },

    async updateFromRoute({ state, commit }, { route }) {
        if (route.name === "index") {
            commit("RESET_SEARCH")
            if (!state.jobFairs) {
                await this.$axios.get("/api/jobfairs").then((response) => {
                    commit("SET_JOBFAIRS", response.data.data)
                })
            }
            return
        }
    },
    async fetchFairs({ state, commit }) {
        if (!state.jobFairs) {
            await this.$axios.get("/api/jobfairs").then((response) => {
                commit("SET_JOBFAIRS", response.data.data)
            })
        }
    },
    async performSearch(
        { commit, state, getters },
        { category, resetCache = false }
    ) {
        if (!state.active) {
            return
        }

        if (resetCache) {
            commit("RESET_SEARCH_HISTORY")
        }
        const searchID = getters.searchID(category)
        let searchObject = state.searches[searchID]

        if (searchObject === undefined) {
            searchObject = new Search(
                searchID,
                category,
                state.filters,
                state.channel,
                state.filters.latitude,
                state.filters.longitude
            )
        }
        if (searchObject.maxPages > searchObject.currentPage || resetCache) {
            await this.$axios
                .get("/api/search", {
                    params: {
                        category: searchObject.category,
                        filters: searchObject.filters,
                        page: searchObject.nextPage,
                        channel: searchObject.channel,
                    },
                })
                .then((response) => {
                    let data = searchObject.data
                    data = data.concat(response.data.result.data)
                    commit("UPDATE_SEARCH", {
                        search: searchObject,
                        data: data,
                        currentPage: response.data.result.current_page,
                        maxPages: response.data.result.last_page,
                        total: response.data.result.total,
                        usedFilters: response.data.filters,
                    })
                })
        }
    },

    saveLastClickedSearchSlide({ commit }, { searchID, index }) {
        commit("SET_SEARCH_INDEX", { searchID, index })
    },
    setShowNavFilterSearch({ commit }, data) {
        commit("SET_SHOW_NAV_FILTER_SEARCH", { data })
    },
    setFilterName({ commit }, data) {
        commit("SET_FILTER_NAME", { data })
    },
    setChannel({ commit }, data) {
        commit("SET_CHANNEL", { data })
    },
    setCategories({ commit }, data) {
        commit("SET_CATEGORIES", data)
    },
    resetLike({ commit }) {
        commit("RESET_LIKES")
    },
    resetSearch({ commit }) {
        commit("RESET_SEARCH")
    },
}

export const mutations = {
    UPDATE_SEARCH_CURRENT_INDEX(state, { searchID, index }) {
        state.searches[searchID] = {
            ...state.searches[searchID],
            currentIndex: index,
        }
    },
    RESET_SEARCH_HISTORY(state) {
        state.searches = []
    },
    SET_SEARCH(state, search) {
        state.searches = { ...state.searches, [search.id]: search }
    },
    UPDATE_SEARCH(
        state,
        { search, data, currentPage, maxPages, total, usedFilters }
    ) {
        if (state.searches[search.id] === undefined) {
            state.searches = { ...state.searches, [search.id]: search }
        }
        state.searches[search.id].data = data
        state.searches[search.id].maxPages = maxPages
        state.searches[search.id].currentPage = currentPage
        state.searches[search.id].total = total
        state.searches[search.id].usedFilters = usedFilters
        if (maxPages > currentPage) {
            state.searches[search.id].nextPage = currentPage + 1
        }
    },
    RESET_SEARCH(state) {
        state.location_search = null
        state.location = {
            slug: null,
            name: null,
            latitude: null,
            longitude: null,
        }
        state.selectedCategory = "Alles"
        state.active = false
        state.channel = "oabat"
    },
    SET_LOCATION(state, location) {
        state.location = location
        state.location_search = location.name
    },
    SET_SELECTED_CATEGORY(state, category) {
        state.selectedCategory = category
    },
    SET_IS_ACTIVE(state, isActive) {
        state.active = isActive
    },
    SET_CATEGORIES(state, categories) {
        state.categories = categories
    },
    START_SEARCH(state) {
        state.isSearching = true
    },
    SET_SLUG(state, slug) {
        state.location.slug = slug
    },
    SET_FILTERS(state, filters) {
        state.filters = filters
    },
    SET_TYPE(state, type) {
        state.type = type
    },
    SET_RESULTS(state, { results, searchHash }) {
        state.results = results
        state.searchHash = searchHash
        state.isSearching = false
    },
    SET_SCOPE(state, scope) {
        state.scope = scope
    },
    UPDATE_HISTORY(state, route) {
        let name = state.location.name
        if (state.selectedCategory !== "Alles") {
            name = name + "&nbsp;&bull;&nbsp;" + state.selectedCategory
        }
        state.history.push({
            name: name,
            route: route,
            selectedCategory: state.selectedCategory,
            location: state.location,
        })
    },
    SET_SEARCH_INDEX(state, { searchID, index }) {
        if (state.searches[searchID] !== undefined) {
            state.searches[searchID].setIndex(index)
        }
    },
    SET_SHOW_NAV_FILTER_SEARCH(state, { data }) {
        state.showNavFilterSearch = data
    },
    SET_FILTER_NAME(state, { data }) {
        state.filterName = data
    },
    SET_SEARCH_MODAL(state, value) {
        state.searchModal = value
    },
    SET_CHANNEL(state, data) {
        state.channel = data
    },
    SET_JOBFAIRS(state, fairs) {
        if (fairs)
            fairs = fairs.map((fair) => {
                fair.start_at = new Date(fair.start_at)
                fair.end_at = new Date(fair.end_at)
                return fair
            })
        state.jobFairs = fairs
    },
    RESET_LIKES(state) {
        Object.values(state.searches).forEach((search) => {
            if (search.data) {
                search.data.forEach((item) => (item.is_liked = false))
            }
        })
    },
}

export const getters = {
    searchModal: (state) => state.searchModal,
    active: (state) => state.active,
    channel: (state) => state.channel,
    results: (state) => state.results,
    location: (state) => state.location,
    history: (state) => state.history,
    categories: (state) => state.categories,
    filter: (state) => state.filters,
    type: (state) => state.type,
    search: (state) => (searchID) => {
        if (state.searches[searchID]) {
            return state.searches[searchID]
        } else {
            return {}
        }
    },
    showNavFilterSearch: (state) => state.showNavFilterSearch,
    filterName: (state) => state.filterName,
    searchID: (state) => (category) => {
        const filters = JSON.stringify(state.filters) || ""
        return md5(state.channel + category + filters)
    },
    selectedCategory: (state) => state.selectedCategory,
    jobFairs: (state) =>
        state.jobFairs
            ? state.jobFairs.filter((fair) => fair.end_at >= new Date())
            : [],
    route:
        (state) =>
        ({ category, slug, filters, type }) => {
            let route = {
                params: { locationSlug: slug || state.location.slug },
                query: filters ? { ...filters } : { ...state.filters },
                name: "",
                meta: {},
            }
            const searchedCategory = category || state.selectedCategory
            const { route: categoryRoute } = getCategories({
                type: type || state.type,
                route,
            }).find((v) => v.name === searchedCategory)
            if (state.filters.longitude) {
                route.query.longitude = state.filters.longitude
                route.query.latitude = state.filters.latitude
            }
            route = { ...route, ...categoryRoute }
            return route
        },
}

class Search {
    constructor(
        id,
        category,
        filters,
        channel,
        latitude = null,
        longitude = null
    ) {
        this.id = id
        this.category = category
        this.currentPage = 0
        this.nextPage = 1
        this.currentIndex = 0
        this.total = null
        this.maxPages = 1000
        this.data = []
        this.channel = channel
        this.filters = latitude ? { ...filters, latitude, longitude } : filters
        this.usedFilters = []
    }

    setIndex(index) {
        this.currentIndex = index
    }
}
