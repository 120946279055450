async function getLocationFromSlug(slug) {
    const zipRegex = /zip-(.*)-([0-9]+)$/
    const defaultRegex = /-([0-9]+)$/
    if (zipRegex.test(slug)) {
        let locationID = slug.match(zipRegex)
        locationID = locationID[2]
        const { data } = await this.$axios.$get(
            "/api/v2/locations/zip/" + locationID
        )
        return data
    } else if (defaultRegex.test(slug)) {
        let locationID = slug.match(defaultRegex)
        locationID = locationID[1]
        const { data } = await this.$axios.$get(
            "/api/v2/locations/" + locationID
        )
        return data
    } else {
        const { data } = await this.$axios.$get("/api/v2/locations/" + slug)
        return data
    }
}

function performSearch({
    latitude,
    longitude,
    category,
    filters,
    radius,
    limit,
    page,
    sort,
    per_page,
    channel,
}) {
    return this.$axios.$get("/api/search", {
        params: {
            latitude,
            longitude,
            category,
            filters,
            sort,
            radius,
            limit,
            per_page,
            page,
            channel,
        },
    })
}

function searchSchool({ latitude, longitude, sort, radius, limit }) {
    return this.$axios
        .$get("/api/search/school", {
            params: {
                filters: {
                    latitude,
                    longitude,
                },
                sort,
                limit,
                radius,
            },
        })
        .then(({ data }) => data)
}

function getPopularCities() {
    return this.$axios
        .$get("/api/search/popular_cities")
        .then(({ items }) => items)
}

function getCities() {
    return this.$axios.$get("/api/search/cities").then(({ items }) => items)
}

function getNearby() {
    return this.$axios.$get("/api/search/nearby").then(({ data }) => data)
}

function searchBaseSchool({ latitude, longitude, name, limit }) {
    return this.$axios
        .$get("/api/search/schoolbase", {
            params: {
                latitude,
                longitude,
                limit,
                name,
            },
        })
        .then(({ data }) => data)
}

function searchJobs({
    type,
    term,
    channel,
    location,
    distance,
    category,
    list,
    duration,
    startYear,
    graduation,
    upperSalary,
    lowerSalary,
    avgResponseTime,
    exclude_job_slug,
    business_slug,
    page,
    authorType,
    onlyPro,
    additionalQueries,
    perPage,
}) {
    const apiUrl = "/api/v2/search/" + type
    return this.$axios.$get(apiUrl, {
        params: {
            term: term,
            channel: channel,
            category: category,
            list: list,
            distance: distance,
            latitude: location?.latitude,
            longitude: location?.longitude,
            duration: duration,
            start_year: startYear,
            graduation: graduation,
            avg_response_time: avgResponseTime,
            exclude_job_slug: exclude_job_slug,
            business_slug: business_slug,
            lower_salary: lowerSalary,
            upper_salary: upperSalary,
            only_pro: onlyPro,
            page: page,
            per_page: perPage,
            author_type: authorType,
            ...additionalQueries,
        },
    })
}

function searchJobsMeta({
    type,
    term,
    channel,
    location,
    distance,
    category,
    authorType,
}) {
    const apiUrl = "/api/v2/search/" + type + "/meta"
    return this.$axios.$get(apiUrl, {
        params: {
            term: term,
            channel: channel,
            category: category,
            distance: distance,
            latitude: location?.latitude,
            longitude: location?.longitude,
            author_type: authorType,
        },
    })
}

function searchJobsSuggest({ type, term, channel, location, distance }) {
    return this.$axios.$get(`/api/v2/search/${type}/suggest`, {
        params: {
            term: term,
            channel: channel,
            distance: distance,
            latitude: location?.latitude,
            longitude: location?.longitude,
        },
    })
}

function autocompleteCity(search) {
    return this.$axios.$post("/api/search/autocomplete_city", {
        search: search,
    })
}

async function getJobCategories() {
    const { data } = await this.$axios.$get("/api/job_categories")
    return data
}

const searchService = {
    getLocationFromSlug,
    performSearch,
    searchSchool,
    getPopularCities,
    searchBaseSchool,
    getCities,
    getNearby,
    searchJobs,
    searchJobsMeta,
    autocompleteCity,
    searchJobsSuggest,
    getJobCategories,
}

export default searchService
