
import { mapGetters } from "vuex"
import { mdiClose, mdiEye, mdiOpenInNew } from "@mdi/js"
export default {
    name: "FloatingWindow",
    components: { Stream: () => import("@/components/livestream/Stream") },
    props: {
        dragable: {
            type: Boolean,
            default: true,
        },
    },
    data: () => {
        return {
            mdiClose: mdiClose,
            mdiEye: mdiEye,
            mdiOpenInNew: mdiOpenInNew,
            isInit: false,
        }
    },
    computed: {
        ...mapGetters("livestream", ["isLive", "viewers", "inWindow", "id"]),
    },
    watch: {
        inWindow(val) {
            if (val && !this.isInit) {
                this.isInit = true
                if (this.$breakpoint.mdAndUp) {
                    this.dragElement(
                        document.getElementById("floating-wrapper")
                    )
                }
            }
        },
    },
    methods: {
        closeWindow() {
            this.$store.dispatch("livestream/closeWindow", false)
        },
        openSite() {
            this.$router.push("/livestream/" + this.id)
        },
        dragElement(elmnt) {
            var dragable = this.dragable
            var pos1 = 0,
                pos2 = 0,
                pos3 = 0,
                pos4 = 0
            if (document.getElementById(elmnt.id + "header")) {
                // if present, the header is where you move the div from:
                document.getElementById(elmnt.id + "header").onmousedown =
                    dragMouseDown
            } else {
                // otherwise, move the div from anywhere inside the div:
                elmnt.onmousedown = dragMouseDown
            }

            function dragMouseDown(e) {
                if (dragable) {
                    e = e || window.event
                    e.preventDefault()
                    // get the mouse cursor position at startup:
                    pos3 = e.clientX
                    pos4 = e.clientY
                    document.onmouseup = closeDragElement
                    // call a function whenever the cursor moves:
                    document.onmousemove = elementDrag
                }
            }

            function elementDrag(e) {
                if (dragable) {
                    e = e || window.event
                    e.preventDefault()
                    // calculate the new cursor position:
                    pos1 = pos3 - e.clientX
                    pos2 = pos4 - e.clientY
                    pos3 = e.clientX
                    pos4 = e.clientY
                    // set the element's new position:

                    elmnt.style.top = elmnt.offsetTop - pos2 + "px"
                    elmnt.style.left = elmnt.offsetLeft - pos1 + "px"
                }
            }

            function closeDragElement() {
                // stop moving when mouse button is released
                document.onmouseup = null
                document.onmousemove = null
            }
        },
    },
}
