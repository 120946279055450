import { mapFairBackendValues } from "@/helpers/fairApiMapping"

function getJobFairs() {
    return this.$axios.$get("/api/jobfairs").then(({ data }) => data)
}

function getJobFair(slug) {
    if (!slug) return
    return this.$axios
        .$get(`/api/jobfairs/${slug}`)
        .then(({ data }) => mapFairBackendValues(data))
}

const jobFairService = {
    getJobFairs,
    getJobFair,
}
export default jobFairService
