/*  Maps backend values to frontend values
    Differnt names come from removed vuex store getters */

export function mapFairBackendValues(fair) {
    return {
        name: fair.name,
        logoFilePath: fair.logo_file_path,
        channel: fair.channel,
        channelObject: fair.channelObject,
        start_at: fair.start_at,
        end_at: fair.end_at,
        businesses: fair.businesses,
        businesses_unpublished: fair.businesses_unpublished,
        location_slug: fair.location_slug,
        jobFairTitle: fair.title,
        jobFairDescription: fair.description,
        livestreams: fair.livestreams,
    }
}
