import Vue from "vue"

let isEnabled = false
export default ({ app, store, $config }, inject) => {
    if (!$config.isProduction) return
    function addScript() {
        if (isEnabled) return
        if (typeof window !== "undefined") {
            isEnabled = true
            console.info("Enabling Hotjar")

            const HOTJAR_ID = 3312401
            const HOTJAR_VERSION = 6
            window._hjSettings = {
                hjid: HOTJAR_ID,
                hjsv: HOTJAR_VERSION,
            }
            const el = document.createElement("script")
            el.async = 1
            el.src = `https://static.hotjar.com/c/hotjar-${HOTJAR_ID}.js?sv=${HOTJAR_VERSION}`
            document.getElementsByTagName("head")[0].appendChild(el)
        }
    }
    app.mixins.push({
        computed: {
            isAllowed() {
                return !!store.$auth.user?.business && !app.$can.admin()
            },
        },
        created() {
            if (this.isAllowed) addScript()
        },
        watch: {
            isAllowed(newVal) {
                if (newVal) addScript()
            },
        },
    })

    inject("hotjar", {
        enable: addScript,
        isEnabled: Vue.observable(isEnabled),
    })
}
