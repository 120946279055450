
import ProgressCircular from "@/components/base/ProgressCircular"
import linkable from "@/mixins/linkable"
import { API_PENDING } from "@/plugins/api"
export default {
    name: "Button",
    components: { ProgressCircular },
    mixins: [linkable],
    emits: ["click"],
    props: {
        to: {
            type: [String, Object],
            required: false,
        },
        /**
         * The size of the button
         * @values tiny, small, regular, large, x-large
         */
        size: {
            type: String,
            default: "regular",
            validator: (val) =>
                ["tiny", "small", "regular", "large", "x-large"].includes(val),
        },
        /**
         * Makes the button round
         */
        round: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        /**
         * Vuetify block property
         */
        block: {
            type: Boolean,
            default: false,
        },
        /**
         * Tag of the root element
         */
        tag: {
            type: String,
            default: "button",
        },
        /**
         * v-ripple property
         * overrides the default ripple effect on the button
         */
        ripple: {
            type: Boolean,
            default: true,
        },
        /**
         * The Styling of the button
         * @values white, black, flat, flat-white, primary, primary-flat, secondary, secondary-flat, primary-frameless, secondary-frameless
         * @default primary
         */
        design: {
            type: String,
            default: "primary",
            validator: (val) =>
                [
                    "white",
                    "black",
                    "flat",
                    "flat-white",
                    "primary",
                    "primary-flat",
                    "secondary",
                    "secondary-flat",
                    "primary-frameless",
                    "secondary-frameless",
                    "transparent",
                ].includes(val),
        },
        dark: {
            type: Boolean,
            default: false,
        },
        request: {
            type: Object,
            default: null,
        },
    },
    computed: {
        classes() {
            return {
                btn: true,
                ["btn-" + this.size]: true,
                ["btn-" + this.design + "-1"]: true,
                "btn--block": this.block,
                "btn--round": this.round,
                "btn--disabled": this.disabled,
                "btn--dark": this.dark,
                "btn--loading":
                    this.loading || this.request?.state === API_PENDING,
                "cursor-default": this.disabled,
            }
        },
        listeners() {
            return { click: this.onClick, ...this.$listeners }
        },
    },
    methods: {
        onClick() {
            this.$emit("click")
            this.$el.blur()
        },
    },
}
