
import Modal from "@/components/base/modal/Modal"
import ProgressLinear from "@/components/base/ProgressLinear"
import { mapGetters, mapActions } from "vuex"

/** Global modal with automatic closing functionality.
 * Manageable using ui-store */
export default {
    name: "MessageHandler",
    components: { Modal, ProgressLinear },
    data: () => ({
        isLoading: true,
        componentName: "",
    }),
    computed: {
        ...mapGetters("ui", [
            "messageHandlerComponent",
            "messageHandlerComponentProps",
            "messageHandlerOptions",
        ]),
        classes() {
            return {
                "rounded-1 white": !this.messageHandlerOptions.transparent,
                "pa-a": !this.messageHandlerOptions.noPadding,
            }
        },
    },
    watch: {
        async messageHandlerComponent(componentImport) {
            if (!componentImport) return
            this.isLoading = true
            if (typeof componentImport === "object") {
                /* Component via import statement */
                const componentResponse = await componentImport
                const component = componentResponse.default
                if (!this.$options.components[component.name]) {
                    this.$options.components[component.name] = component
                }
                this.componentName = component.name
            } else {
                /* default components by name string */
                this.componentName = componentImport
            }
            this.isLoading = false
        },
    },
    methods: {
        ...mapActions("ui", ["clearGlobalComponent"]),
        onClose() {
            const cancel = this.$refs.component?.close?.()
            if (cancel === true) return

            this.clearGlobalComponent()
            this.isLoading = true
        },
    },
}
