import { JOB_V3, PUBLISH_REGULAR_JOBS } from "@/enums/permissions"

export function routeToLivestreamSearch(livestream) {
    if (livestream) return `/livestream/${livestream}/suche`
}

export function routeToLivestream(livestreamId) {
    if (livestreamId) return `/livestream/${livestreamId}`
}

export function routeToDatenschutz() {
    return { path: "/datenschutz" }
}

export function routeToBusiness(business, openChat = false) {
    if (business) {
        let route = {
            name: "betriebe-slug",
            params: { slug: business.slug },
        }
        if (openChat) {
            route.query = {
                chat: true,
            }
        }
        return route
    }
}

export function routeToBusinessRaw(businessSlug) {
    if (businessSlug) {
        let route = {
            name: "betriebe-slug",
            params: { slug: businessSlug },
        }
        return route
    }
}

export function routeToReview(job, review = undefined) {
    if (job) {
        return {
            name: "betriebe-businessSlug-jobs-jobSlug-review",
            params: { jobSlug: job.slug, businessSlug: job.business.slug },
            query: { review: review?.id },
        }
    }
}

export function routeToBusinessSite(businessSite, openChat = false) {
    if (businessSite) {
        let route = {
            name: "betriebe-slug-siteslug",
            params: {
                slug: businessSite.slug,
                siteslug: businessSite.business_site_slug,
            },
        }
        if (openChat) {
            route.query = {
                chat: true,
            }
        }
        return route
    }
}

export function routeToCampaignAdvertisement(mediaId) {
    return {
        name: "admin-kampagne-bewerben-mediaId",
        params: { mediaId },
    }
}

export function routeToBusinessUsers() {
    return {
        name: "betrieb-benutzer",
    }
}

export function routeToEinBlick() {
    return {
        name: "betrieb-einblick",
    }
}
export function routeToNewIFrame() {
    return {
        name: "betrieb-einblick-iframe-id",
        params: { id: "erstellen" },
    }
}

export function routeToIFrame(id) {
    return {
        name: "betrieb-einblick-iframe-id",
        params: { id },
    }
}

export function routeToBusinessLocations() {
    return {
        name: "betrieb-standorte",
    }
}

export function routeToJob(job, query = null) {
    if (job && job.business) {
        return {
            name: "betriebe-slug-jobs-jobSlug",
            params: {
                jobSlug: job.slug,
                slug: job.business.slug,
            },
            query,
        }
    }
}

export function routeToInternshipLandingpage(job, query = null) {
    if (job && job.business) {
        return {
            name: "betriebe-slug-jobs-jobSlug-praktikum",
            params: {
                jobSlug: job.slug,
                slug: job.business.slug,
            },
            query,
        }
    }
}

/**
 * @param {Number} type 1: apprenticeship, 2: traineeship, 3: job
 * @param {Object} query Query params. Currently used:
 *      to:"dashboard" for redirect to dashboard after new jobcreator
 */
export function routeToCreateJob(business, type, query) {
    if (!business) return
    type = Number(type)
    if (type === 3 && !business.permissions.includes(PUBLISH_REGULAR_JOBS))
        return
    if (business.permissions.includes(JOB_V3) || type === 3) {
        return {
            name: "betrieb-job-erstellen",
            query: { type, ...query },
            params: {
                trustworthy: true,
            },
        }
    }
    console.error("routeToCreateJob: job type not supported")
    return
}

export function routeToCreatePost(business) {
    if (business) {
        return {
            name: "betriebe-slug-beitraege-erstellen",
            params: { slug: business.slug },
        }
    }
}

export function routeToCreateAdminEvent() {
    return {
        name: "admin-event-erstellen",
    }
}

export function routeToOabatlyticsDetailedUserStatistics(userId) {
    return {
        name: "admin-oabalytics-users-id-detailed",
        params: {
            id: userId,
        },
    }
}

export function routeToEditProfile(user, step = 1) {
    if (user?.business) {
        return {
            name: "betriebe-slug-bearbeiten",
            params: {
                slug: user.business.slug,
            },
            query: {
                step,
            },
        }
    }
}

export function routeToEditJob(job) {
    if (!job) return
    if (job.type_id === 3 || job.version >= 2) {
        return {
            name: "betrieb-job-slug-bearbeiten",
            params: {
                slug: job.slug,
                trustworthy: true, // no need to check for business permissions in the middleware
                slugAsJob: true, // slug param is job.slug and not business.slug
            },
        }
    }
    console.error("routeToEditJob: job type not supported")
    return
}

export function routeToEditPost(post) {
    if (post && post.author) {
        return {
            name: "betriebe-slug-beitraege-postSlug-bearbeiten",
            params: {
                postSlug: post.slug,
                slug: post.author.slug,
            },
        }
    }
}

export function routeToChat(userID) {
    const route = {
        name: "chat",
    }
    if (userID !== undefined) {
        route.query = {
            user_id: userID,
        }
    }
    return route
}

export function routeToPost(post) {
    if (post) {
        return {
            name: "beitrag-postSlug",
            params: {
                postSlug: post.slug,
            },
        }
    }
}

export function routeToBusinessAdminJobs(user) {
    if (user?.business) {
        return {
            name: "betriebe-slug-jobs-uebersicht",
            params: {
                slug: user.business.slug,
            },
        }
    }
}

export function routeToBusinessAdminPosts(business) {
    if (business) {
        return {
            name: "betriebe-slug-beitraege-uebersicht",
            params: {
                slug: business.slug,
            },
        }
    }
}

export function routeToBusinessProfileVisitors(business) {
    if (business) {
        return {
            name: "betriebe-slug-profilbesucher",
            params: {
                slug: business.slug,
            },
        }
    }
}

export function routeToOabatDashboard() {
    return { name: "statistiken-oabat" }
}
export function routeToOabatlytics() {
    return { name: "admin-oabalytics" }
}
export function routeToAdminDashboard() {
    return { name: "admin-uebersicht" }
}
export function routeToAdminBusinesses() {
    return { name: "admin-betriebe" }
}
export function routeToAdminArticles() {
    return { name: "admin-artikel" }
}
export function routeToAdminConversations(query = null) {
    return { name: "admin-konversationen", query }
}
export function routeToAdminSurveys() {
    return { name: "admin-umfragen" }
}
export function routeToAdminSchools() {
    return { name: "admin-schulen" }
}
export function routeToAdminRegions() {
    return { name: "admin-regionen" }
}
export function routeToAdminJobFairs() {
    return { name: "admin-messen" }
}
export function routeToAdminLivestreams() {
    return { name: "admin-livestreams" }
}
export function routeToAdminJobLists() {
    return { name: "admin-joblists" }
}

export function routeToLivestreamAdminChat(id) {
    return {
        name: "livestream-livestream-admin-chat",
        params: { livestream: id },
    }
}

export function routeToUserOabalytics(id, anonymous = false) {
    return {
        name: "admin-oabalytics-users-id",
        params: {
            id,
        },
        query: {
            anonymous: anonymous || undefined,
        },
    }
}

export function routeToCitySearch(locationSlug) {
    return {
        name: "ausbildung-locationSlug",
        params: {
            locationSlug,
        },
    }
}

export function routeToCityApprenticeships(locationSlug, category) {
    const query = {}

    if (category) {
        query.category = category
    }
    return {
        name: "ausbildung-locationSlug",
        params: {
            locationSlug: locationSlug,
        },
        query,
    }
}

export function routeToCityNews(locationSlug) {
    return {
        name: "news-locationSlug",
        params: {
            locationSlug: locationSlug,
        },
    }
}

export function routeToCityTraineeships(locationSlug) {
    return {
        name: "ausbildung-locationSlug",
        params: {
            locationSlug: locationSlug,
        },
        query: {
            praktikum: null,
        },
    }
}

export function routeToCityBusinesses(locationSlug) {
    return {
        name: "unternehmen-locationSlug",
        params: {
            locationSlug: locationSlug,
        },
    }
}

export function routeToJobFairIndex(jobFairSlug) {
    return {
        name: "messe-locationSlug",
        params: {
            locationSlug: jobFairSlug,
        },
    }
}

export function routeToSchool(schoolSlug) {
    return {
        name: "schule-slug",
        params: {
            slug: schoolSlug,
        },
    }
}

export function routeToSchoolDashboard() {
    return {
        name: "schule",
    }
}

export function routeToBulletinBoard(schoolSlug) {
    return {
        name: "schule-slug-schwarzes-brett",
        params: {
            slug: schoolSlug,
        },
    }
}

export function routeToSchoolNetwork() {
    return {
        name: "schule-firmennetzwerk",
    }
}

export function routeToSettings(user) {
    if (user) {
        if (user.region) {
            return {
                name: "region-einstellungen",
            }
        }
        if (user.school) {
            return {
                name: "schule-einstellungen",
            }
        }
        if (user.trainee) {
            return {
                name: "i-password",
            }
        }
    }
    return {
        name: "einstellungen",
    }
}

export function routeToDashboard(user) {
    if (!user) return "/"
    if (user.school) return { name: "schule" }
    if (user.region) return { name: "region" }
    if (user.business) return { name: "betrieb" }
    return "/"
}

export function routeToProfile(user) {
    if (!user) return "/"
    if (user.region) return { name: "region" }
    if (user.trainee) return { path: "/i/profile" }
    if (user.school)
        return {
            name: "schule-slug",
            params: {
                slug: user.school.slug,
            },
        }
    if (user.business) {
        return {
            name: "betriebe-slug",
            params: { slug: user.business.slug },
        }
    }
    return "/"
}

export function routeToProfileEditing(user) {
    if (!user) return "/"
    if (user.trainee) return { path: "/i/profile/editieren" }
    return "/"
}

export function routeToTraineeFavorites() {
    return { name: "favoriten" }
}

export function routeToRegionDashboard() {
    return { name: "region" }
}

export function routeToMegabat({ slug, v2 = false }) {
    if (v2) {
        return {
            name: "region-v2-slug",
            params: {
                slug: slug,
            },
        }
    }
    if (slug === "inn-salzach") {
        return {
            name: "region-inn-salzach",
        }
    }
    if (slug === "freyung-grafenau") {
        return {
            name: "region-freyung-grafenau",
        }
    }
    if (slug === "passau") {
        return {
            name: "region-passau",
        }
    }
    if (slug === "hauzenberg") {
        return {
            name: "region-hauzenberg",
        }
    }
    if (slug === "altmuehlfranken") {
        return {
            name: "region-altmuehlfranken",
        }
    }
    // if (region.slug === "landshut") {
    //     return {
    //         name: "region-landshut",
    //     }
    // }
    return {
        name: "region-slug",
        params: {
            slug: slug,
        },
    }
}

export function routeToRegionPlattform() {
    return {
        name: "region-plattform",
    }
}

export function routeToRegionChannel() {
    return {
        name: "region-channel",
    }
}
export function routeToRegionSchools() {
    return {
        name: "region-schulen",
    }
}
export function routeToRegionBusinesses() {
    return {
        name: "region-betriebe",
    }
}

export function routeToRegionSchoolOverview({ slug, v2 = false }) {
    if (v2) {
        return {
            name: "region-v2-slug-schulen",
            params: {
                slug: slug,
            },
        }
    }
    return {
        name: "region-slug-schulen",
        params: {
            slug: slug,
        },
    }
}

export function routeToBusinessNetwork({ activities = false } = {}) {
    const query = {}
    if (activities) {
        query.activities = null
    }
    return {
        name: "betriebe-schulnetzwerk",
        query,
    }
}
export function routeToBusinessChannels(user) {
    if (user?.business)
        return {
            name: "betrieb-channels",
        }
}

export function routeToRegionArbeitgeberPage(region) {
    if (region) {
        return {
            name: "region-slug-arbeitgeber",
            params: {
                slug: region.slug,
            },
        }
    }
}

export function routeToCreatobat(templateId, query) {
    return {
        name: "admin-kampagne-erstellen-templateId",
        params: {
            templateId,
        },
        query,
    }
}

export function routeToCampaign(query) {
    return {
        name: "admin-kampagne",
        query,
    }
}

export function routeToRegionEventsPage(region) {
    if (region) {
        return {
            name: "region-slug-arbeitgeber-events",
            params: {
                slug: region.slug,
            },
        }
    }
}

export function routeToArbeitgeberRegistration({
    channel,
    product,
    employees,
    size,
    register,
} = {}) {
    return {
        name: "arbeitgeber-registrierung",
        query: {
            channel,
            product,
            employees,
            size,
            register,
        },
    }
}

export function routeToEvent(event) {
    if (event) {
        return {
            name: "event-eventSlug",
            params: {
                eventSlug: event.slug,
            },
        }
    }
}

export function routePoweredByOabat() {
    return "/"
}

/**
 * @param {Array} products - The list of products
 * @returns {Object} Route Object
 */
export function routeToKasse(products) {
    if (!Array.isArray(products)) return
    return {
        name: "betrieb-store-kasse",
        query: {
            ...products.reduce((acc, curr) => ((acc[curr] = null), acc), {}),
            noScroll: null,
        },
    }
}

export function routeToProduct(product) {
    return {
        name: `betrieb-store-feature-${product}`,
        query: {
            noScroll: null,
        },
    }
}

export function routeToProblem() {
    return {
        name: "betrieb-problem",
    }
}

export function routeToStore() {
    return {
        name: "betrieb-store",
        query: { extensions: null },
    }
}

export function routeToAbonnements() {
    return {
        name: "betrieb-abonnements",
    }
}

export function routeToListCard(list, locationSlug) {
    return {
        name: "ausbildung-locationSlug",
        params: {
            locationSlug,
            list,
        },
    }
}

export function routeToJobList(jobListSlug, location = undefined) {
    let query = undefined
    if (location) {
        query = {
            location: JSON.stringify(location),
        }
    }
    return {
        name: "auswahl-jobListSlug",
        params: {
            jobListSlug,
        },
        query,
    }
}
