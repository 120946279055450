function resetPassword(token, email, password) {
    return this.$axios
        .post("/api/password_reset", {
            token: token,
            email: email,
            password: password,
            password_confirmation: password,
        })
        .then((response) => {
            return response.data
        })
}

function changePassword(oldPassword, password) {
    return this.$axios
        .post("/api/password_change", {
            old_password: oldPassword,
            password: password,
            password_confirmation: password,
        })
        .then((response) => {
            return response.data
        })
}

function passwordResetRequest(email) {
    return this.$axios
        .post("/api/password_request", {
            email: email,
        })
        .then((response) => {
            return response.data
        })
}

function emailChangeRequest(userId, newEmail) {
    return this.$axios
        .$put("/api/v2/auth/request_email_change", {
            user_id: userId,
            email: newEmail,
        })
        .then((response) => {
            return response
        })
}

function changeEmail(userId, newEmail, code) {
    return this.$axios
        .$post("/api/v2/auth/email_change", {
            user_id: userId,
            email: newEmail,
            code: code,
        })
        .then((response) => {
            return response
        })
}

function login(email, password, skipActivation) {
    return this.$axios.post("/api/login", {
        email: email,
        password: password,
        skipActivation: skipActivation,
    })
}

function sendAppLink(phoneNumber) {
    return this.$axios.post("/api/util/app_link", {
        phone_number: phoneNumber,
    })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user")
    localStorage.removeItem("profilePath")
}

function activate(token) {
    this.$axios.defaults.headers.common["Content-Type"] = "application/json"
    return this.$axios.get("activate/" + token).then((response) => {
        return response.data
    })
}

function register({ firstName, lastName, email, password, traineeData }) {
    return this.$axios
        .post("/api/register", {
            email: email,
            password: password,
            firstname: firstName,
            lastname: lastName,
            traineeData: traineeData,
        })
        .then((r) => r.data)
        .then((response) => {
            return response
        })
}

function requestDeletion({ userId, reason }) {
    return this.$axios
        .post("/api/user/request-deactivation", {
            user_id: userId,
            reason_message: reason,
        })
        .then((response) => {
            return response
        })
}

function registerLight({
    firstName,
    lastName,
    email,
    password,
    birthdate,
    phone_number,
}) {
    return this.$axios
        .post("/api/auth/register/trainee", {
            email: email,
            password: password,
            firstname: firstName,
            lastname: lastName,
            birthdate: birthdate,
            phone_number: phone_number,
        })
        .then((r) => r.data)
        .then((response) => {
            return response
        })
}

function getNotificationSettings() {
    return this.$axios
        .$get("/api/settings/notifications/")
        .then(({ notification_settings }) => notification_settings)
}

function putNotificationSettings(id, value) {
    return this.$axios
        .$put(`/api/settings/notifications/${id}`, { subscribed: value })
        .then(({ data }) => data)
}

function update(id, userData) {
    return this.$axios.$put("/api/users/" + id, userData).then((response) => {
        return response.data
    })
}

function getUserAliases(userId) {
    return this.$axios
        .$get(`/api/v2/user_aliases/${userId}`)
        .then((response) => {
            return response.data
        })
}

function updateUserAlias(alias) {
    return this.$axios
        .$put(`/api/v2/user_aliases/${alias.id}`, {
            is_unread_messages_mail_active: Boolean(
                alias.is_unread_messages_mail_active
            ),
            is_job_interest_mail_active: Boolean(
                alias.is_job_interest_mail_active
            ),
        })
        .then(({ data }) => data)
}

const userService = {
    login,
    update,
    changePassword,
    passwordResetRequest,
    emailChangeRequest,
    changeEmail,
    resetPassword,
    logout,
    register,
    activate,
    registerLight,
    requestDeletion,
    sendAppLink,
    getNotificationSettings,
    putNotificationSettings,
    getUserAliases,
    updateUserAlias,
}

export default userService
