const VIMEO_URL = "https://api.vimeo.com/me/videos"

export default {
    getVideos() {
        return this.$axios.$get("/api/videos").then(({ data }) => data)
    },
    postVideo(title, description, vimeo_url) {
        return this.$axios
            .$post(`/api/videos`, { title, description, vimeo_url })
            .then(({ data }) => data)
    },
    putVideo(videoId, { title, description, type }) {
        return this.$axios
            .$put(`/api/videos/${videoId}`, {
                title,
                description,
                type,
            })
            .then(({ data }) => data)
    },
    deleteVideo(videoId) {
        return this.$axios
            .$delete(`/api/videos/${videoId}`)
            .then(({ data }) => data)
    },
    postVideoToChannel(videoId, channel, type, sorting) {
        return this.$axios
            .$post(`/api/videos/${videoId}/channels/${channel}`, null, {
                params: {
                    sorting,
                    type,
                },
            })
            .then(({ data }) => data)
    },
    deleteVideoFromChannel(videoId, channel) {
        return this.$axios
            .$delete(`/api/videos/${videoId}/channels/${channel}`)
            .then(({ data }) => data)
    },
    generateUrls(file) {
        const cancelSource = this.$axios.CancelToken.source()
        return {
            cancel: () => cancelSource.cancel(),
            request: this.getVimeoAxios()
                .$post(
                    VIMEO_URL,
                    {
                        upload: {
                            approach: "tus",
                            size: file.size,
                        },
                        name: file.name,
                    },
                    {
                        cancelToken: cancelSource.token,
                    }
                )
                .then((uploadPre) => ({
                    uploadLink: uploadPre.upload.upload_link,
                    link: uploadPre.link,
                })),
        }
    },
    asClass(video) {
        if (video instanceof OabatVideo) return video
        return new OabatVideo(video, this)
    },
    uploadWithTus(file, url) {
        let resolve, reject
        const obj = {
            error: null,
            request: new Promise((res, rej) => {
                resolve = res
                reject = rej
            }),
            state: "pending",
            progress: 0,
            cancel: () => {
                obj.state = "cancel"
            },
        }
        import("tus-js-client").then((tus) => {
            if (obj.state === "cancel") return
            const tusUpload = new tus.Upload(file, {
                uploadUrl: url,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata: {
                    filename: file.name,
                    filetype: file.type,
                },
                onError: (error) => {
                    obj.state = "error"
                    obj.error = error
                    reject()
                },
                onProgress: (bytesUploaded, bytesTotal) => {
                    const progress = (bytesUploaded / bytesTotal) * 100
                    obj.progress = progress.toFixed(2)
                },
                onSuccess: () => {
                    obj.state = "success"
                    obj.progress = 100
                    resolve()
                },
            }).start()
            obj.cancel = () => {
                obj.state = "cancel"
                tusUpload?.abort()
            }
        })
        return obj
    },
    getVimeoAxios() {
        return this.$axios.create({
            headers: {
                common: {
                    "Content-Type": "application/json",
                    Accept: "application/vnd.vimeo.*+json;version=3.4",
                    Authorization: "bearer b5a291bd1d6ddfce7a21eb15d057792b",
                },
            },
        })
    },
}

class OabatVideo {
    constructor(video, api) {
        if (!video) return
        this.isVideo = true
        this.getApi = () => api // Private value
        this.setSource(video)
    }
    async create() {
        this.cancel()
        if (!this.id) {
            this.request = {
                state: "pending",
                progress: 0,
            }
            try {
                const urlRequest = this.getApi().generateUrls(this.local)
                this.request.cancel = () => urlRequest.cancel()
                const { uploadLink, link } = await urlRequest.request
                this.request = this.getApi().uploadWithTus(
                    this.local,
                    uploadLink
                )
                await this.request.request
                const apiRequest = this.getApi()
                    .postVideo(this.local.name, this.local.type, link)
                    .catch((err) => {
                        this.request.state = "error"
                        this.request.error = err
                    })
                this.request.cancel = () => apiRequest?.cancel?.()
                const video = await apiRequest

                this.setSource(video)
            } catch (error) {
                this.request.state = "error"
                this.request.error = error
            }
        }
    }
    delete() {
        this.cancel()
        if (this.id) {
            this.getApi().deleteVideo(this.id)
        }
    }
    cancel() {
        this.request?.cancel()
    }
    setSource(video) {
        if (!video) return
        this.request = this.request ?? null
        this.local = this.local ?? null
        this.uid = video?.uid ?? this.uid
        this.id = video?.id ?? this.id
        this.link = video?.link ?? this.link
        this.vimeo_url = video?.vimeo_url
        this.vimeo_id = video?.vimeo_id
        this.is_rendered = video.is_rendered
        this.files = video.files
        this.title = video?.title
        this.thumbnail_url = video?.thumbnail_url
        if (typeof video === "object" && "type" in video && "size" in video) {
            this.local = video
            this.size = video.size
            this.type = video.type
        }
    }
    getObject() {
        if (!this.id) return
        return {
            id: this.id,
            title: this.title,
            vimeo_id: this.vimeo_id,
            vimeo_url: this.vimeo_url,
        }
    }
}
