export default async function ({ app }) {
    if (process.env.NODE_ENV !== "production") {
        return
    }

    //init mixins if they doesn't exist yet
    app.mixins = app.mixins || []

    //Mixin that initializes the chat
    app.mixins.push({
        computed: {
            marketingCookieAllowed() {
                return this.$store.getters["cookie/marketingCookieAllowed"]
            },
        },
        watch: {
            marketingCookieAllowed: {
                handler(newValue) {
                    if (newValue) {
                        this.enableMetaPixel()
                    }
                },
                immediate: true,
            },
        },
        methods: {
            enableMetaPixel() {
                this.$fb.setUserData({
                    external_id: localStorage.getItem("session_id"),
                })
                this.$fb.enable()
            },
        },
    })
}
