import Vue from "vue"
import { getLivestream } from "@/services/v2/livestream.service"

const LIVESTREAM_ID = 16 // Zukunftstage Passau hardcoded
/** ATTENTION: Date-Month index is starting with 0! */
const ADVERTISEMENT_START = new Date(2023, 2, 20) // Shows Card in search results
const ADVERTISEMENT_END = new Date(2023, 2, 25)

const IS_DISABLED = true

export default async function ({ app }, inject) {
    app.mixins = app.mixins || []
    if (!IS_DISABLED) {
        app.mixins.push({
            async mounted() {
                const livestream = await app.$api
                    .run(getLivestream, LIVESTREAM_ID)
                    .request.catch(() => null)
                if (!livestream) return
                const willStartToday =
                    new Date(livestream.planned_start).toDateString() ===
                    new Date().toDateString()
                const hasEnded = new Date(livestream.planned_end) < new Date()
                const isUpcoming = willStartToday && !hasEnded
                this.$options.$livestream.isUpcoming =
                    livestream.is_live || isUpcoming
                this.$options.$livestream.entity = livestream
                this.$options.$livestream.isReady = true
            },
        })
    }

    const now = new Date()
    const isAdvertisementActive =
        now > ADVERTISEMENT_START && now < ADVERTISEMENT_END

    inject(
        "livestream",
        Vue.observable({
            id: IS_DISABLED ? null : LIVESTREAM_ID,
            isAdvertisementActive: IS_DISABLED ? false : isAdvertisementActive,
            isReady: false,
            isUpcoming: false,
            entity: {},
            isBadgeClosed: false,
        })
    )
}
