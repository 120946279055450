async function getFile(id) {
    return await this.$axios.$get(`/api/file/${id}`, {
        responseType: "blob",
    })
}

async function getFileInfo(id) {
    return await this.$axios
        .$get(`/api/files_info/${id}`)
        .then(({ data }) => data)
}

function getFiles(type = "") {
    return this.$axios.$get(`/api/files/${type}`).then(({ data }) => data)
}

/** formData
 *    file: file(max:100000)
 *    filename: string
 *    filesize: numeric
 *    mime_type?: string(max:100)
 *    pdf_conversion?: boolean(0,1)
 *  config: axios config
 * */
function uploadFile(formData, config) {
    return this.$axios
        .$post(`/api/file`, formData, config)
        .then(({ data }) => data)
}

function claimFile(fileId, uuid) {
    return this.$axios
        .$post(`/api/claim_file/` + fileId, { uuid: uuid })
        .then(({ data }) => data)
}

function editFile(file) {
    return this.$axios
        .$put(`/api/files/${file.id}`, file)
        .then(({ data }) => data)
}

function deleteFile(id) {
    return this.$axios.$delete(`/api/files/${id}`).then(({ data }) => data)
}

async function downloadFile({ id, mime_type, original_filename }) {
    const response = await this.getFile(id)
    let blob = new Blob([response], {
        type: mime_type,
    })
    const URL = window.URL || window.webkitURL
    // getting the local URL of the downloaded file
    const fileUrl = URL.createObjectURL(blob)
    // creating an invisible <a> element in the root of the page
    const a = document.createElement("a")
    a.style.display = "none"
    a.setAttribute("href", fileUrl)
    // setting the name for the file
    a.setAttribute("download", original_filename)
    // rendering the element
    document.body.appendChild(a)
    // a programmatic click to trigger browser download behaviour
    a.click()
    setTimeout(() => {
        // remove the element and release the URL after the download has started
        document.body.removeChild(a)
        URL.revokeObjectURL(fileUrl)
    }, 100)
}

const fileService = {
    uploadFile,
    editFile,
    downloadFile,
    getFile,
    getFileInfo,
    getFiles,
    deleteFile,
    claimFile,
}
export default fileService
