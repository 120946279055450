import { render, staticRenderFns } from "./FloatingWindow.vue?vue&type=template&id=6f8ec381&scoped=true&"
import script from "./FloatingWindow.vue?vue&type=script&lang=js&"
export * from "./FloatingWindow.vue?vue&type=script&lang=js&"
import style0 from "./FloatingWindow.vue?vue&type=style&index=0&id=6f8ec381&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f8ec381",
  null
  
)

export default component.exports