function postLivestream(data) {
    return this.$axios.$post("/api/livestreams", data).then((response) => {
        return response.data
    })
}

function putLivestream(livestreamId, data) {
    return this.$axios
        .$put(`/api/livestreams/${livestreamId}`, data)
        .then((response) => {
            return response.data
        })
}

function postLivestreamPoll(data) {
    return this.$axios
        .$post("/api/livestream_polls/", data)
        .then((response) => {
            return response.data
        })
}

function putLivestreamPoll(pollId, data) {
    return this.$axios
        .$put(`/api/livestream_polls/${pollId}`, data)
        .then((response) => {
            return response.data
        })
}

function deleteLivestreamPoll(pollId) {
    return this.$axios
        .$delete(`/api/livestream_polls/${pollId}`)
        .then((response) => {
            return response.data
        })
}

function postLivestreamPollOption(data) {
    return this.$axios
        .$post("/api/livestream_poll_options/", data)
        .then((response) => {
            return response.data
        })
}

function putLivestreamPollOption(optionId, data) {
    return this.$axios
        .$put(`/api/livestream_poll_options/${optionId}`, data)
        .then((response) => {
            return response.data
        })
}

function deleteLivestreamPollOption(optionId) {
    return this.$axios
        .$delete(`/api/livestream_poll_options/${optionId}`)
        .then((response) => {
            return response.data
        })
}

function postLivestreamPollOptionVotes(data) {
    return this.$axios
        .$post("/api/livestream_poll_option_votes/", data)
        .then((response) => {
            return response.data
        })
}

function putLivestreamPollProposal(proposalId, data) {
    return this.$axios
        .$put("/api/livestream_poll_proposal/" + proposalId, data)
        .then((response) => {
            return response.data
        })
}

function verifyLogin(livestreamId, password) {
    return this.$axios.$post(`/api/livestreams/${livestreamId}/verify`, {
        password,
    })
}

const livestreamService = {
    postLivestream,
    putLivestream,
    postLivestreamPoll,
    putLivestreamPoll,
    deleteLivestreamPoll,
    postLivestreamPollOption,
    putLivestreamPollOption,
    deleteLivestreamPollOption,
    postLivestreamPollOptionVotes,
    putLivestreamPollProposal,
    verifyLogin,
}
export default livestreamService
