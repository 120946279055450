export const state = () => ({
    meta: {},
    declinedAppDownload: false,
})

export const getters = {
    meta: (state) => state.meta,
    declinedAppDownload: (state) => state.declinedAppDownload,
}

export const actions = {
    setMeta({ commit }, { ...meta }) {
        if (!meta) {
            meta = {}
        }
        commit("SET_META", meta)
    },
    addMeta({ commit, state }, { ...meta }) {
        if (!meta) {
            meta = {}
        }
        commit("SET_META", { ...state.meta, ...meta })
    },
    setDeclinedAppDownload({ commit }) {
        commit("SET_DECLINED_APP_DOWNLOAD", true)
    },
}

export const mutations = {
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_DECLINED_APP_DOWNLOAD(state, value) {
        state.declinedAppDownload = value
    },
}
