import Vue from "vue"
import MessageHandlerClass from "@/components/global/MessageHandler"

export default async function ({ app }) {
    app.mixins = app.mixins || []
    app.mixins.push({
        mounted() {
            const MessageHandler = Vue.component(
                "MessageHandler",
                MessageHandlerClass
            )
            const Component = new MessageHandler({ parent: this })
            let ele = document.getElementById("__nuxt")
            var node = document.createElement("div")
            node.setAttribute("id", "messageHandler")
            ele.appendChild(node)
            Component.$mount("#messageHandler")
        },
    })
}
