import Vue from "vue"

/* Refactored to manage the trainee profile stepper.
   Needs further refactor to split the trainee object apart from auth.user */
export const state = () => ({
    isInit: false,
    /* Data */
    id: null,
    updated_at: "",
    created_at: "",
    /* Personal Data */
    gender: "",
    firstname: "",
    lastname: "",
    phone_number: null,
    primary_address: {},
    school_base: null,
    highest_graduation: null,

    birthdate: null,
    temp_email: "",
    /* Settings */
    number_trialwork: 0,
    number_trialwork_missing: 0,
    /* Further Objects*/
    referal_code: null,
    profile_picture: {},
    onboarding_completed: true,
    interest: null,
    onboarding: {
        longitude: null,
        latitude: null,
        jobType: "apprenticeship",
    },
})

export const actions = {
    async init({ commit, state, rootState }, id) {
        if (state.isInit || !id) return
        commit("INIT")
        let res = await this.$axios.$get("/api/trainee/" + id)
        // check if trainee has interest object
        if (!res.data.interest) {
            res = await this.$axios.$post("/api/interests/" + id)
            const updatedUser = { ...rootState.auth.user }
            updatedUser.trainee.interest = res.data.interest
            this.$auth.setUser(updatedUser)
        }
        res.data.temp_email = rootState.auth.user.email
        if (res.data.birthdate) {
            res.data.birthdate = new Intl.DateTimeFormat("de", {
                dateStyle: "medium",
            }).format(new Date(res.data.birthdate))
            // reformatting the backend birthdate value
        }
        commit("SET_TRAINEE", res.data)
    },
    updateOnboarding({ commit }, data) {
        commit("UPDATE_ONBOARDING", data)
    },
    async updateTrainee({ rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            this.$axios
                .put(`/api/trainee/${rootState.auth.user.trainee.id}`, data)
                .then((result) => {
                    commit("SET_TRAINEE", result.data.data)
                    resolve(result)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    async updateInterest({ rootState }, data) {
        const traineeID = rootState.auth.user.trainee.id
        const interestID = rootState.auth.user.trainee.interest.id
        return new Promise((resolve, reject) => {
            this.$axios
                .put(`/api/trainee/${traineeID}/interests/${interestID}`, data)
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    aboutMe({ commit }, data) {
        commit("ABOUT_ME", data)
    },
    contactMe({ commit }, data) {
        commit("CONTACT_ME", data)
    },
    hobbys({ commit }, data) {
        commit("HOBBYS", data)
    },
    education({ commit }, data) {
        commit("EDUCATION", data)
    },
    experience({ commit }, data) {
        commit("EXPERIENCE", data)
    },
    profilePic({ commit }, data) {
        commit("PROFILE_PICTURE", data)
    },

    updateNotificationSettings({ state, dispatch }, data) {
        const newTrainee = { ...state, ...data }
        return dispatch("upload", {
            data: newTrainee,
            profile_picture: false,
        })
    },

    uploadImage({ state, commit }) {
        let dataurl = state.profile_picture
        let filename = "image"

        // Start of Stackoverflow magic
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        let file = new File([u8arr], filename, { type: mime })
        // End of Magic
        let form = new window.FormData()
        form.append("avatar", file)
        return this.$axios
            .post("/api/trainee/" + state.id + "/upload_profile", form)
            .then((res) => {
                commit("PROFILE_PICTURE", res.data.user.profile_picture)
            })
            .catch((err) => {
                console.error(err)
            })
    },

    setCurrentProfilePicture({ commit }, profile_picture) {
        commit("PROFILE_PICTURE", profile_picture)
    },

    async upload({ state, commit }, { data = null, profilePicture = null }) {
        if (data == null) {
            data = state
        }
        if (profilePicture) {
            data.profile_picture = profilePicture
        }
        await this.$axios
            .$put("/api/trainee/" + data.id, data)
            .then((res) => {
                commit("SET_TRAINEE", res.data)
            })
            .catch((error) => {
                console.error("Trainee STORE: ", error)
            })
        this.$auth.fetchUser()
    },
    resetTrainee({ commit }) {
        commit("RESET")
    },
}

export const getters = {
    highestGraduation: (state) => state.highest_graduation,
    primaryAddress: (state) => state.primary_address,
    onboardingLocationFilters: (state) => {
        return {
            filters: {
                longitude:
                    state.onboarding.longitude || state.interest.longitude,
                latitude: state.onboarding.latitude || state.interest.latitude,
            },
        }
    },
    aboutMe: (state) => {
        return {
            firstname: state.firstname,
            lastname: state.lastname,
            birthdate: state.birthdate,
            location:
                state.primary_address?.city ??
                state.primary_address?.postal_code ??
                "",
            school: state.school_base,
        }
    },
    contactMe: (state) => {
        return {
            mailAddress: state.temp_email,
            phoneNumber: state.phone_number,
        }
    },
    profilePic: (state) => {
        return { image: state.profile_picture }
    },
    traineeInit: (state) => state.isInit,
    firstname: (state) => state.firstname,
    school: (state) => state.school_base,
    birthdate: (state) => state.birthdate,
    notificationSettings: (state) => {
        return {
            allows_chat_notification: state.allows_chat_notification,
            allows_newsletter: state.allows_newsletter,
            allows_application_notification:
                state.allows_application_notification,
            allows_recommendations: state.allows_recommendations,
        }
    },
    interest: (state) => state.interest,

    /**
     * Profile
     * Working in 0-100 integers for percentage
     *
     * Returning Object with
     *  percentage: Number [0-100]
     *  nextCategory: category
     *  nextStep:
     *  */
    progressOf:
        (state, getters) =>
        (category = null) => {
            let values
            if (category) {
                values = Object.entries(getters[category])
            } else {
                const totalObject = {
                    ...getters.aboutMe,
                    ...getters.contactMe,
                    ...getters.hobbys,
                    ...getters.education,
                    ...getters.experience,
                    ...getters.profilePic,
                }
                delete totalObject.birthdate
                delete totalObject.phoneNumber
                values = Object.entries(totalObject)
            }
            const total = values.length
            const missing = values.reduce((filtered, option) => {
                if (!option[1] || option[1].length === 0) {
                    filtered.push(option[0])
                }
                return filtered
            }, [])
            return {
                percentage: Number(
                    ((total - missing.length) / total) * 100
                ).toFixed(0),
                missing,
            }
        },
}

export const mutations = {
    INIT(state) {
        state.isInit = true
    },
    SET_TRAINEE(state, trainee) {
        state.isInit = true
        // Against Nullpointers
        if (!trainee.primary_address) {
            trainee.primary_address = {}
        }
        for (const [key, value] of Object.entries(trainee)) {
            Vue.set(state, key, value)
        }
        // Object.assign(state, trainee)
    },
    ABOUT_ME(state, data) {
        state.firstname = data.firstname
        state.lastname = data.lastname
        state.birthdate = data.birthdate
        state.school_base = data.school
        state.school_base_id = data.school?.id
        if (/^\d+$/.test(data.location)) {
            state.primary_address.postal_code = data.location
        } else {
            state.primary_address.city = data.location
        }
    },
    CONTACT_ME(state, data) {
        state.phone_number = data.phoneNumber
        state.temp_email = data.mailAddress
    },
    PROFILE_PICTURE(state, data) {
        state.profile_picture = data
    },
    SET_NOTIFICATION_SETTINGS(state, trainee) {
        state.allows_chat_notification = trainee.allows_chat_notification
        state.allows_newsletter = trainee.allows_newsletter
        state.allows_application_notification =
            trainee.allows_application_notification
        state.allows_recommendations = trainee.allows_recommendations
    },
    UPDATE_ONBOARDING(state, data) {
        state.onboarding = { ...state.onboarding, ...data }
    },
    RESET(state) {
        let emptyState = {
            isInit: false,
            id: null,
            updated_at: "",
            created_at: "",
            gender: "",
            firstname: "",
            lastname: "",
            phone_number: null,
            primary_address: {},

            birthdate: null,
            temp_email: "",
            number_trialwork: 0,
            number_trialwork_missing: 0,
            referal_code: null,
            profile_picture: {},
            onboarding_completed: true,
            address: [],
            school_base: null,
        }
        Object.assign(state, emptyState)
    },
    SET_ONBOARDING_COMPLETED(state, data) {
        state.onboarding_completed = data
    },
}
