import traineeService from "@/services/trainee.service"
import tutorialService from "@/services/tutorial.service"
import { AUTH_DIALOG_STATE } from "@/enums/oabalytics"
import { PROFILE_COMPLETED } from "@/enums"
import { BUSINESS_PROFILE_COMPLETED } from "@/plugins/survey"
const ONBOARDING_SESSION_ID = "prepare_onboarding"

export const state = () => ({
    onboarding: false,
    isImpersonated: false,
    auth: {
        showModal: false,
        redirect: true,
    },
})

export const actions = {
    setOnboarding({ commit }, value) {
        commit("SET_ONBOARDING", value)
    },
    async completeOnboarding(_, trainee) {
        const traineeId = this.$auth.user.trainee.id
        await traineeService
            .update(traineeId, trainee)
            .catch((err) => console.warn(err))
        await this.$auth.fetchUser()
    },
    prepareOnboarding(_, value) {
        if (value) {
            sessionStorage.setItem(ONBOARDING_SESSION_ID, true)
        } else {
            sessionStorage.removeItem(ONBOARDING_SESSION_ID)
        }
    },
    async init({ dispatch, commit, rootState }) {
        const user = rootState.auth.user
        if (user?.trainee) {
            await Promise.all([
                dispatch("trainee/init", user.trainee.id, {
                    root: true,
                }),
                dispatch(
                    "rating/loadRatings",
                    {},
                    {
                        root: true,
                    }
                ),
            ])
        }
        if (!process.client) return
        if (this.$auth.$storage.getLocalStorage("isImpersonated")) {
            commit("SET_IMPERSONATED", true)
        }
        if (user?.business) {
            if (user.tutorial_steps?.includes(PROFILE_COMPLETED)) {
                /* Needs delay to have context available */
                setTimeout(() => {
                    this.$survey?.startTimer(BUSINESS_PROFILE_COMPLETED, 20)
                }, 1000)
            }
        }
    },
    login({ commit, dispatch }, { email, password, close = true }) {
        return new Promise((resolve, reject) => {
            this.$auth
                .loginWith("local", {
                    data: {
                        email: email,
                        password: password,
                    },
                })
                .then((response) => {
                    commit("SET_AUTH_MODAL", !close)
                    dispatch("initStores")
                    dispatch("prepareOnboarding", false)
                    commit(
                        "livestream/SET_SHOULD_LOGIN_TO_SEND_MESSAGE",
                        false,
                        { root: true }
                    )
                    resolve(response)
                })
                .catch((error) => {
                    console.warn(error)
                    reject(error)
                })
        })
    },
    loginWithPhoneNumber(
        { commit, dispatch },
        { phoneNumber, otp, close = true }
    ) {
        return new Promise((resolve, reject) => {
            this.$auth
                .loginWith("local", {
                    data: {
                        phone_number: phoneNumber,
                        otp: otp,
                    },
                })
                .then((response) => {
                    commit("SET_AUTH_MODAL", !close)
                    dispatch("initStores")
                    dispatch("prepareOnboarding", false)
                    commit(
                        "livestream/SET_SHOULD_LOGIN_TO_SEND_MESSAGE",
                        false,
                        { root: true }
                    )
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    initStores({ dispatch, rootState }) {
        const user = rootState.auth.user
        dispatch("chat/initialize", {}, { root: true })
        dispatch("navigation/updateUserType", null, { root: true })
        if (user.school) {
            dispatch("school/initUpdateListener", {}, { root: true })
        }
        if (user.trainee) {
            dispatch("trainee/init", user.trainee.id, {
                root: true,
            })
            dispatch("rating/processQueue", {}, { root: true })
            dispatch("rating/loadRatings", {}, { root: true })
        }
        if (user.business) {
            if (user.tutorial_steps?.includes(PROFILE_COMPLETED)) {
                this.$survey?.startTimer(BUSINESS_PROFILE_COMPLETED, 20)
            }
        }
    },
    async impersonate({ state }, userId) {
        const { token } = await this.$axios.$post("/api/auth/temporary_login", {
            user_id: userId,
        })
        if (!state.isImpersonated) {
            const current_token = this.$auth.$storage.getCookie("_token.local")
            this.$auth.$storage.setLocalStorage("isImpersonated", current_token)
            this.$auth.$storage.setLocalStorage(
                "impersonatedRoute",
                this.$router.currentRoute.fullPath
            )
        }
        this.$auth.$storage.setCookie("_token.local", `Bearer ${token}`)
        window.location.reload(true)
    },
    async depersonate({ state }) {
        this.$auth.$storage.setCookie("_token.local", false)
        const originalToken =
            this.$auth.$storage.getLocalStorage("isImpersonated")

        if (state.isImpersonated && originalToken) {
            this.$auth.$storage.setCookie("_token.local", originalToken)
        }
        this.$auth.$storage.removeLocalStorage("isImpersonated")
        this.$auth.$storage.removeLocalStorage("impersonatedRoute")
        window.location.reload(true)
    },
    logout({ dispatch, state }, redirect = true) {
        if (state.isImpersonated) {
            dispatch("depersonate")
            return
        }
        this.$auth.logout().then(() => {
            dispatch("resetStores")
            // re-generate session_id, since most probably another user takes over after logout
            this.$oabalytics.resetUser()
            if (redirect) this.app.router.push("/")
        })
    },
    resetStores({ dispatch }) {
        dispatch("chat/terminate", null, { root: true })
        dispatch("search/resetLike", null, { root: true })
        dispatch("business/resetLikes", null, { root: true })
        dispatch("trainee/resetTrainee", null, { root: true })
        dispatch("rating/reset", null, { root: true })
        dispatch("school/reset", null, { root: true })
        dispatch("navigation/updateUserType", null, { root: true })
    },
    registerLight(
        { commit, dispatch },
        {
            firstName,
            lastName,
            email,
            password,
            birthdate,
            phoneNumber,
            otp,
            newsletterConfirmed,
            registerByPhone,
        }
    ) {
        return new Promise((resolve, reject) => {
            this.$axios
                .post("/api/auth/register/trainee", {
                    email: email,
                    password: password,
                    firstname: firstName,
                    lastname: lastName,
                    birthdate: birthdate,
                    code: otp,
                    phone_number: phoneNumber,
                    newsletter_confirmed: newsletterConfirmed,
                    register_by_phone: registerByPhone,
                })
                .then(
                    (response) => {
                        if (sessionStorage.getItem(ONBOARDING_SESSION_ID)) {
                            commit("SET_ONBOARDING", true)
                            dispatch("prepareOnboarding", false)
                        }
                        resolve(response.data)
                    },
                    (error) => {
                        reject(error)
                    }
                )
        })
    },
    setTutorialProgress({ rootState }, value) {
        if (!rootState.auth.loggedIn) return
        tutorialService.storeTutorial(rootState.auth.user.id, value)
        const userToUpdate = { ...rootState.auth.user }
        userToUpdate.tutorial_steps = [value, ...userToUpdate.tutorial_steps]
        this.$auth.setUser(userToUpdate)
    },
    /**
     * @param {*} redirect
     * true | default - redirects to user-home/dashboard after login,
     * false          - stays on the same page,
     * string | route - redirects to the specified route
     */
    showAuth({ commit }, { redirect = true } = {}) {
        commit("SET_AUTH_MODAL", true)
        commit("SET_AUTH_REDIRECT", redirect)
        this.$oabalytics.trackEvent(AUTH_DIALOG_STATE, {
            state: true,
        })
    },
    closeAuth({ commit }) {
        commit("SET_AUTH_MODAL", false)
        this.$oabalytics.trackEvent(AUTH_DIALOG_STATE, {
            state: false,
        })
    },
}

export const mutations = {
    SET_ONBOARDING(state, value) {
        state.onboarding = value
    },
    SET_AUTH_MODAL(state, value) {
        state.auth.showModal = value
    },
    SET_AUTH_REDIRECT(state, value) {
        state.auth.redirect = value
    },
    SET_IMPERSONATED(state, value) {
        state.isImpersonated = value
    },
}

export const getters = {
    authRedirect: (state) => state.auth.redirect,
    authDialog: (state) => state.auth.showModal,
    onboarding: (state, _, rootState) =>
        state.onboarding &&
        rootState.auth.user &&
        !rootState.auth.user.business &&
        !rootState.auth.user.school &&
        !rootState.auth.user.region,
    isProfileComplete: (_1, _2, rootState) => {
        const business = rootState.auth.user?.business
        if (business) {
            return !!(
                business.name &&
                business.about_us &&
                business.main_images?.length > 4 &&
                business.public_contact_person &&
                business.public_contact_person.firstname &&
                business.public_contact_person.lastname &&
                business.public_contact_person.gender &&
                business.public_contact_person.profile_picture
            )
        }
        return false
    },
    isProfilePublic: (_1, _2, rootState) => {
        const business = rootState.auth.user?.business
        if (!business) return false
        return business.is_visible
    },
}
