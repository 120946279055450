import Vue from "vue"

const SLOW_THRESHOLD = 1.55 // mbps
const PAGE_LOAD_THRESHOLD = 5000 // ms

const data = Vue.observable({
    isSlow: null,
    downlink: 0,
    saveData: null,
})
let past = new Date()
let pageLoad

if (process.client) {
    past = new Date()
    pageLoad = new Promise((resolve) => {
        window.addEventListener("load", () => {
            const now = new Date()
            const diff = now - past
            resolve(diff)
        })
    })
    try {
        useBrowserApi()
    } catch (e) {
        usePageLoadSpeed()
    }
}

export default ({ app }, inject) => {
    app.mixins = app.mixins || []
    app.mixins.push({
        mounted() {
            if (process.client) {
                try {
                    navigator.connection.addEventListener(
                        "change",
                        useBrowserApi
                    )
                } catch (e) {
                    console.warn(
                        "Could not add EventListener for navigator.connection updates",
                        e
                    )
                }
            }
        },
    })
    inject("speed", data)
}

function useBrowserApi() {
    data.isSlow = navigator.connection.downlink < SLOW_THRESHOLD
    data.downlink = navigator.connection.downlink
    data.saveData = navigator.connection.saveData
}

async function usePageLoadSpeed() {
    const diff = await pageLoad
    data.isSlow = diff > PAGE_LOAD_THRESHOLD
    data.downlink = diff
    data.saveData = diff > PAGE_LOAD_THRESHOLD
}
