import Echo from "laravel-echo"

window.Pusher = require("pusher-js")
export default async function ({ $auth, $config }, inject) {
    if ($auth) {
        const options = {
            broadcaster: "pusher",
            wsHost: $config.socketWsHost || window?.location?.host,
            wssPort: $config.socketWssPort,
            wsPort: $config.socketWsPort,
            wsPath: $config.socketWsPath,
            cluster: "default",
            app_id: $config.socketAppId,
            key: $config.socketKey,
            forceTLS: $config.socketForceTLS,
            encrypted: true,
            enabledTransports: ["ws", "wss"],
        }
        options.auth = options.auth || {}
        options.auth.headers = {
            ...options.auth.headers,
            ...getHeaders($auth),
        }
        const echo = new Echo(options)

        $auth.$storage.watchState("loggedIn", (loggedIn) => {
            echo.options.auth.headers = {
                ...echo.options.auth.headers,
                ...getHeaders($auth),
            }

            if (loggedIn) {
                echo.connect()
            }

            if (!loggedIn && echo.connector) {
                echo.disconnect()
            }
        })

        inject("echo", echo)
    }
}

function getHeaders($auth) {
    const headers = {}

    if ($auth) {
        const strategy = $auth.strategy
        const tokenName = strategy.options.tokenName || "Authorization"
        const token = $auth.getToken(strategy.name)

        if (token) {
            headers[tokenName] = token
        }
    }

    return headers
}
