/* ui store contains some global mutations of the ui, which are not related to any component or model */
export const state = () => ({
    loadingSpinner: false,
    /* Using MessageHandler.vue for gobal component displaying */
    messageHandler: {
        component: null,
        props: null,
        options: null,
    },
    snackbarError: {
        active: false,
        message: "",
    },
})

export const actions = {
    /** Activates global message modal, requires imported path to component
     * component: {
     *      component: import( *component* )
     *      props: { *props* }
     * }
     */
    setGlobalComponent({ commit }, component) {
        commit("SET_GLOBAL_COMPONENT", component)
    },
    clearGlobalComponent({ commit }) {
        commit("CLEAR_GLOBAL_COMPONENT")
    },
    setGlobalComponentOptions({ commit }, options) {
        commit("SET_GLOBAL_COMPONENT_OPTIONS", options)
    },
    setGlobalSnackbarError({ commit }, message) {
        commit("SET_SNACKBAR_ERROR", message)
    },
    clearGlobalSnackbarError({ commit }) {
        commit("CLEAR_SNACKBAR_ERROR")
    },
}

export const mutations = {
    SET_LOADING_SPINNER(state, active) {
        state.loadingSpinner = active
    },
    SET_GLOBAL_COMPONENT(state, { component, props, options }) {
        _setPageScroll(true)
        state.messageHandler.component = component
        state.messageHandler.props = props
        state.messageHandler.options = options
    },
    SET_GLOBAL_COMPONENT_OPTIONS(state, options) {
        state.messageHandler.options = options
    },
    CLEAR_GLOBAL_COMPONENT(state) {
        _setPageScroll(false)
        state.messageHandler.component = null
        state.messageHandler.props = null
        state.messageHandler.options = null
    },
    SET_SNACKBAR_ERROR(state, message) {
        state.snackbarError.message = message
        state.snackbarError.active = true
    },
    CLEAR_SNACKBAR_ERROR(state) {
        state.snackbarError.message = null
        state.snackbarError.active = false
    },
}

export const getters = {
    loadingSpinner: (state) => state.loadingSpinner,
    messageHandlerComponent: (state) => state.messageHandler.component,
    messageHandlerComponentProps: (state) => state.messageHandler.props,
    messageHandlerOptions: (state) => state.messageHandler.options ?? {},
    snackbarErrorMessage: (state) => state.snackbarError.message,
    snackbarErrorActive: (state) => state.snackbarError.active,
}

function _setPageScroll(value) {
    document.querySelector("html").classList.toggle("overflow-hidden", value)
}
