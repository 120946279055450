export default function ({ $auth, redirect }) {
    let user = $auth.user

    if (
        !user ||
        user.email !== "admin@oabat.de" ||
        $auth.$storage.getUniversal("superadmin") !== true
    ) {
        redirect({ name: "index" })
    }
}
