export default {
    async getJobList(slug) {
        const { data } = await this.$axios.$get(`/api/job_lists/${slug}`)
        return data
    },
    async getJobLists() {
        const { data } = await this.$axios.$get("/api/job_lists")
        return data
    },
    async postJobList(list) {
        const { data } = await this.$axios.$post(`/api/job_lists`, list)
        return data
    },
    async putJobList(slug, list) {
        const { data } = await this.$axios.$put(`/api/job_lists/${slug}`, list)
        return data
    },
    async deleteJobList(slug) {
        const { data } = await this.$axios.$delete(`/api/job_lists/${slug}`)
        return data
    },
}
