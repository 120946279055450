
import Button from "@/components/base/Button"

export default {
    name: "ErrorLayout",
    components: { Button },
    props: {
        error: {
            type: Object,
            default: () => ({}),
        },
    },
    layout: "fullscreen",
}
