import storeService from "@/services/store.service"
import { routeToProduct } from "@/helpers/routing"
const BACKGROUNDS = {
    mail2chat: require("@/assets/images/feature/dispersion_illustration_card.jpg"),
    api_connection: require("@/assets/images/feature/fibres_illustration_card.jpg"),
    job: require("@/assets/images/school/signup/cards.png"),
}

const IMAGES = {
    mail2chat: require("@/assets/images/feature/checkout_chat_mail.svg"),
}

export const state = () => ({
    isInit: false,
    products: [],
})

export const actions = {
    async init({ state, commit }) {
        if (state.isInit) return
        const products = await storeService.getProducts()
        commit("SET_PRODUCTS", products)
        commit("SET_INIT", true)
    },
}

export const mutations = {
    SET_PRODUCTS(state, products) {
        state.products = products
    },
    SET_INIT(state, value) {
        state.isInit = value
    },
}

export const getters = {
    products: (state) =>
        state.products.map((product) => ({
            ...product,
            background: BACKGROUNDS[product.slug],
            image: IMAGES[product.slug],
            route: routeToProduct(product.slug),
        })),
    isProductBought: (_1, _2, rootState) => (product) => {
        if (!rootState.auth.user?.business) return
        return rootState.auth.user.business.subscriptions.some(
            (prod) => prod.product.slug == product
        )
    },
}
