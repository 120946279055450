export const ACCESS_BUSINESS_PROFILE = "accessBusinessProfile"
export const ACCESS_TRAINEE_PROFILE = "accessTraineeProfile"
export const CREATE_POST = "createPost"
export const LIKE_POST = "likePost"
export const CREATE_JOB = "createJob"
export const APPLY_FOR_JOB = "applyForJob"
export const ACCESS_SCHOOL_PROFILE = "accessSchoolProfile"
export const PUBLISH_BUSINESS = "publish_business"
export const PUBLISH_APPRENTICESHIP = "publish_apprenticeship"
export const PUBLISH_TRAINEESHIP = "publish_traineeship"
export const PUBLISH_NEWS = "publish_news"
export const ADMIN_LIVESTREAM_POLLS = "admin_livestream_polls"
export const SHOW_OWN_JOBS = "show_own_jobs"
export const INVITE_SCHOOL = "invite_school"
export const MANAGE_BUSINESS_SITES = "manage_business_sites"
export const JOB_VIDEO = "job_video"
export const ACTIVATE_JOB = "activate_job"
export const PUBLISH_REGULAR_JOBS = "publish_regular_jobs"
export const MAIL2CHAT = "mail2chat"
export const NEW_JOBS = "new_jobs"
export const STATISTICS = "statistics"
export const JOB_EXTERNAL_APPLICATION = "job_external_application"
export const JOB_AI = "job_ai"
export const JOB_CI = "job_ci"
export const JOB_EINBLICK = "job_einblick"
export const JOB_V3 = "job_v3"
export const JOBLIST_V2 = "joblist_v2"

export const PRO_PORTAL = "pro_portal"
