export default (context, inject) => {
    /** @param {Number} preferedSize File size from large (1) to small (3) */
    const getImage = ({ image, preferedSize = 3, preferedFormat = null }) => {
        let fileEnding, returnImage

        if (_supportsWebp()) {
            fileEnding = preferedFormat || "webp"
            returnImage = {
                src: require("@/assets/images/placeholder.webp"),
            }
        } else {
            fileEnding = preferedFormat || "jpg"
            returnImage = {
                src: require("@/assets/images/placeholder.jpg"),
            }
        }
        if (!image) return returnImage // Use Placeholder
        if (!image.urls && image.local) return { src: image.local } // Use local file
        if (image.urls) {
            const fileSuffix = `${preferedSize}.${fileEnding}`
            const placeholderSuffix = `p.${fileEnding}`
            returnImage.src = image.urls[fileSuffix]
            returnImage.placeholder = image.urls[placeholderSuffix]
        }
        return returnImage
    }

    const getOGImage = ({ image }) => {
        const returnImage = {
            src: require("@/static/logos/oabat_logo.jpg"),
            type: "image/jpeg",
        }
        if (!image) return returnImage
        if (image.urls) {
            const imageSize = "1"
            const fileEnding = "jpg"
            returnImage.src = image.urls[`${imageSize}.${fileEnding}`]
        }
        return returnImage
    }

    const getStaticImage = (image) => {
        return image
    }

    const _supportsWebp = () => {
        return context.$ua.browser() === "Chrome" && !context.$ua.isFromIos()
    }

    inject("getImage", getImage)
    inject("getOGImage", getOGImage)
    inject("getStaticImage", getStaticImage)
}
