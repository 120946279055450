export default async function ({ app }) {
    //init mixins if they doesn't exist yet
    app.mixins = app.mixins || []

    //Mixin that initializes the chat
    app.mixins.push({
        mounted() {
            app.store.dispatch("chat/initialize")
            app.store.dispatch("school/initUpdateListener")
        },
    })
}
