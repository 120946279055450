import Vue from "vue"
import { createLocalStorageEntity } from "@/helpers/localStorage"
import {
    getRates,
    removeRating,
    rateJob,
    LS_RATING_QUEUE,
    rateJobAnonymous,
} from "@/services/v2/rating.service"
const LS_ANONYMOUS_USER_KEY = "user_session_id"

export const state = () => ({
    /** Format: { job_id: rating } */
    ratings: {},
    /** Format: {job_id: ratingId} - only stored for deletion */
    ratingIds: {},
    ratingsCount: 0,
    newRatingIds: [],
    lastRating: null,
})

export const actions = {
    async loadRatings({ commit }) {
        const data = await this.$api.run(getRates, this.$auth.user.id).request
        commit("SET_RATINGS", data)
    },
    reset({ commit }) {
        commit("RESET")
    },
    resetNewRatings({ commit }) {
        commit("RESET_NEW_RATINGS")
    },
    async rateJob(
        { commit, dispatch, rootState, state },
        { job, value, bypassFirstLike }
    ) {
        const isNewRating = !state.ratings[job.id]
        commit("RATE_JOB", { id: job.id, value })
        if (this.$can.trainee()) {
            if (
                !rootState.auth.user.ever_liked_job &&
                !bypassFirstLike &&
                value > 50
            ) {
                dispatch("showFirstLiked", { job, value })
                return
            }
            if (isNewRating) commit("SET_LAST_RATING", { id: job.id, value })

            const result = await this.$api.run(
                rateJob,
                job.id,
                rootState.auth.user.id,
                value
            ).request
            commit("SET_RATING_ID", { job_id: job.id, rating_id: result.id })
        } else if (!rootState.auth.loggedIn) {
            this.$api.run(
                rateJobAnonymous,
                job.id,
                localStorage.getItem(LS_ANONYMOUS_USER_KEY),
                value
            )
            dispatch("showLoginToLike", { job, value })
        }
    },
    async removeRating({ commit, state }, id) {
        const ratingId = state.ratingIds[id]
        commit("REMOVE_RATING", id)
        if (this.$can.trainee()) {
            await this.$api.run(removeRating, ratingId).request
        }
    },
    async processQueue({ dispatch, rootState, rootGetters }) {
        if (!this.$can.trainee()) return
        if (!rootGetters["account/onboarding"]) {
            const lsQueue = createLocalStorageEntity(LS_RATING_QUEUE, true)
            const hasLikeInQueue = lsQueue
                .get()
                .some((entry) => entry.value > 50)
            if (!rootState.auth.user.ever_liked_job && hasLikeInQueue) {
                const { value, id, slug } = lsQueue.get()[0]
                dispatch("showFirstLiked", {
                    job: { id, slug },
                    value,
                    likeFunction: () => dispatch("processLSEntries"),
                })
                return
            }
        }
        dispatch("processLSEntries")
    },
    async processLSEntries({ dispatch }) {
        const lsQueue = createLocalStorageEntity(LS_RATING_QUEUE, true)
        for (const entry of lsQueue.get()) {
            if ("value" in entry && "id" in entry) {
                await dispatch("rateJob", {
                    value: entry.value,
                    job: { id: entry.id, slug: entry.slug },
                    bypassFirstLike: true,
                }).catch((err) => console.warn(err))
            }
        }
        lsQueue.clear()
        this.$auth.fetchUser()
    },
    async showLoginToLike({ dispatch }, { job, value }) {
        createLocalStorageEntity(LS_RATING_QUEUE, true).add({
            id: job.id,
            slug: job.slug,
            value,
        })
        setTimeout(() => {
            dispatch(
                "ui/setGlobalComponent",
                {
                    component: import("@/components/trainee/LoginToLike"),
                    options: {
                        noPadding: true,
                        transparent: true,
                    },
                    props: {
                        item: job,
                    },
                },
                { root: true }
            )
        }, 100)
    },
    async showFirstLiked(
        { dispatch, commit, rootState },
        { job, value, likeFunction }
    ) {
        const defaultLikeFunction = async () => {
            const result = await this.$api.run(
                rateJob,
                job.id,
                rootState.auth.user.id,
                value
            ).request
            commit("SET_RATING_ID", {
                job_id: job.id,
                rating_id: result.id,
            })
        }
        setTimeout(() => {
            dispatch(
                "ui/setGlobalComponent",
                {
                    component: import("@/components/trainee/FirstLiked"),
                    options: {
                        noPadding: true,
                        transparent: true,
                    },
                    props: {
                        item: job,
                        likeFunction: likeFunction || defaultLikeFunction,
                    },
                },
                { root: true }
            )
        }, 100)
    },
}

export const mutations = {
    RESET(state) {
        state.ratings = {}
        state.ratingIds = {}
        state.ratingsCount = 0
        state.newRatingIds = []
    },
    RESET_NEW_RATINGS(state) {
        state.newRatingIds = []
    },
    SET_LAST_RATING(state, { id, value }) {
        state.lastRating = { id, value }
    },
    RATE_JOB(state, { id, value }) {
        if (
            !state.newRatingIds.includes(id) &&
            !state.ratings[id] &&
            value > 50
        ) {
            state.newRatingIds.push(id)
        }

        Vue.set(state.ratings, id, value)
        state.ratingsCount = Object.keys(state.ratings).length
    },
    SET_RATING_ID(state, { job_id, rating_id }) {
        Vue.set(state.ratingIds, job_id, rating_id)
    },
    REMOVE_RATING(state, id) {
        Vue.delete(state.ratings, id)
        Vue.delete(state.ratingIds, id)
        state.ratingsCount = Object.keys(state.ratings).length
        const index = state.newRatingIds.indexOf(id)
        if (index > -1) state.newRatingIds.splice(index, 1)
    },
    SET_RATINGS(state, ratings) {
        state.rating = {}
        for (const rating of ratings) {
            Vue.set(state.ratings, rating.job_id, rating.rating)
            Vue.set(state.ratingIds, rating.job_id, rating.id)
        }
        state.ratingsCount = Object.keys(state.ratings).length
    },
}

export const getters = {
    getRating: (state) => (id) => state.ratings[id],
    ratingsCount: (state) => state.ratingsCount,
    newRatingsCount: (state) => state.newRatingIds.length,
    snackbar: (state) => state.snackbar,
    lastRating: (state) => state.lastRating,
}
