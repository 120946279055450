/**
 *
 * @param {String} context Context to identify where the request is coming from
 * @param {Object} content Values to be sent. Allows: email, name, message, phone, organization
 * @returns Axios Promise
 */
function sendContact(context, content) {
    if (!content) return Promise.reject("No content provided")
    return this.$axios.$post("/api/contact-us", {
        context,
        email: content.email,
        name: content.name,
        message: content.message,
        phone: content.phone,
        receiver: content.receiver || "oabat",
    })
}
export default {
    sendContact,
}
