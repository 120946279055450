const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['business'] = require('../middleware/business.js')
middleware['business'] = middleware['business'].default || middleware['business']

middleware['dashboard'] = require('../middleware/dashboard.js')
middleware['dashboard'] = middleware['dashboard'].default || middleware['dashboard']

middleware['hide-in-production'] = require('../middleware/hide-in-production.js')
middleware['hide-in-production'] = middleware['hide-in-production'].default || middleware['hide-in-production']

middleware['logged-in'] = require('../middleware/logged-in.js')
middleware['logged-in'] = middleware['logged-in'].default || middleware['logged-in']

middleware['logged-out'] = require('../middleware/logged-out.js')
middleware['logged-out'] = middleware['logged-out'].default || middleware['logged-out']

middleware['meta-provider'] = require('../middleware/meta-provider.js')
middleware['meta-provider'] = middleware['meta-provider'].default || middleware['meta-provider']

middleware['no-business'] = require('../middleware/no-business.js')
middleware['no-business'] = middleware['no-business'].default || middleware['no-business']

middleware['no-region'] = require('../middleware/no-region.js')
middleware['no-region'] = middleware['no-region'].default || middleware['no-region']

middleware['no-school'] = require('../middleware/no-school.js')
middleware['no-school'] = middleware['no-school'].default || middleware['no-school']

middleware['no-trainee'] = require('../middleware/no-trainee.js')
middleware['no-trainee'] = middleware['no-trainee'].default || middleware['no-trainee']

middleware['region'] = require('../middleware/region.js')
middleware['region'] = middleware['region'].default || middleware['region']

middleware['routing'] = require('../middleware/routing.js')
middleware['routing'] = middleware['routing'].default || middleware['routing']

middleware['school'] = require('../middleware/school.js')
middleware['school'] = middleware['school'].default || middleware['school']

middleware['superadmin'] = require('../middleware/superadmin.js')
middleware['superadmin'] = middleware['superadmin'].default || middleware['superadmin']

export default middleware
