import Vue from "vue"
import FloatingWindowClass from "@/components/livestream/FloatingWindow"
export default async function ({ app }) {
    app.mixins = app.mixins || []
    app.mixins.push({
        async mounted() {
            // Do not mount Floating Window on any iOS device (done via native iOS floating video)
            if (this.$ua.isFromIos()) {
                return
            }

            const FloatingWindow = Vue.component(
                "FloatingWindow",
                FloatingWindowClass
            )
            const Component = new FloatingWindow({ parent: this })
            let ele = document.getElementById("__nuxt")
            var node = document.createElement("div")
            node.setAttribute("id", "additionals")
            ele.appendChild(node)
            Component.$mount("#additionals")
        },
    })
}
