function getRegions() {
    return this.$axios.$get("/api/regions/").then((response) => {
        return response.data
    })
}
function getRegion(slug) {
    return this.$axios.$get("/api/regions/" + slug).then((response) => {
        return response.data
    })
}
function putRegion(slug, data) {
    return this.$axios.$put("/api/regions/" + slug, data).then((response) => {
        return response.data
    })
}

const regionService = {
    getRegions,
    getRegion,
    putRegion,
}
export default regionService
