import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_de90f7e4 from 'nuxt_plugin_plugin_de90f7e4' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_2c622fe8 from 'nuxt_plugin_sentryserver_2c622fe8' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_72ae8340 from 'nuxt_plugin_sentryclient_72ae8340' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_72722ac0 from 'nuxt_plugin_plugin_72722ac0' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_plugin_06ff8b14 from 'nuxt_plugin_plugin_06ff8b14' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_templatespluginade8f9b6_b9f79cd2 from 'nuxt_plugin_templatespluginade8f9b6_b9f79cd2' // Source: ./templates.plugin.ade8f9b6.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_edcca868 from 'nuxt_plugin_cookieuniversalnuxt_edcca868' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_workbox_5450d040 from 'nuxt_plugin_workbox_5450d040' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_70f5d753 from 'nuxt_plugin_metaplugin_70f5d753' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_759d7cc7 from 'nuxt_plugin_iconplugin_759d7cc7' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_5864d424 from 'nuxt_plugin_axios_5864d424' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_369061ed from 'nuxt_plugin_nuxtuseragent_369061ed' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_portalvue_29710eaf from 'nuxt_plugin_portalvue_29710eaf' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_moduleplugin794ebfcb_3578e88c from 'nuxt_plugin_moduleplugin794ebfcb_3578e88c' // Source: ./module.plugin.794ebfcb.mjs (mode: 'all')
import nuxt_plugin_router_68823c0b from 'nuxt_plugin_router_68823c0b' // Source: ./router.js (mode: 'all')
import nuxt_plugin_connectionSpeedpluginServer555822ae_f4ef1d00 from 'nuxt_plugin_connectionSpeedpluginServer555822ae_f4ef1d00' // Source: ./connectionSpeed.pluginServer.555822ae.js (mode: 'all')
import nuxt_plugin_connectionSpeedplugin4a6f3f1e_207213e0 from 'nuxt_plugin_connectionSpeedplugin4a6f3f1e_207213e0' // Source: ./connectionSpeed.plugin.4a6f3f1e.js (mode: 'client')
import nuxt_plugin_fullscreenMediaplugin2bfbbd51_485545ba from 'nuxt_plugin_fullscreenMediaplugin2bfbbd51_485545ba' // Source: ./fullscreenMedia.plugin.2bfbbd51.js (mode: 'client')
import nuxt_plugin_abTestingplugin2d9ea452_6750f520 from 'nuxt_plugin_abTestingplugin2d9ea452_6750f520' // Source: ./abTesting.plugin.2d9ea452.js (mode: 'all')
import nuxt_plugin_oabalyticspluginServere2fd9abc_7e1867b3 from 'nuxt_plugin_oabalyticspluginServere2fd9abc_7e1867b3' // Source: ./oabalytics.pluginServer.e2fd9abc.js (mode: 'all')
import nuxt_plugin_oabalyticsplugin2c297765_13d8520d from 'nuxt_plugin_oabalyticsplugin2c297765_13d8520d' // Source: ./oabalytics.plugin.2c297765.js (mode: 'client')
import nuxt_plugin_mergeOptions_27c73488 from 'nuxt_plugin_mergeOptions_27c73488' // Source: ../plugins/mergeOptions (mode: 'all')
import nuxt_plugin_axiosprovider_2192f65e from 'nuxt_plugin_axiosprovider_2192f65e' // Source: ../plugins/axios-provider (mode: 'all')
import nuxt_plugin_breakpoint_4e0af832 from 'nuxt_plugin_breakpoint_4e0af832' // Source: ../plugins/breakpoint (mode: 'all')
import nuxt_plugin_images_5d5ea524 from 'nuxt_plugin_images_5d5ea524' // Source: ../plugins/images (mode: 'all')
import nuxt_plugin_truncate_618992dc from 'nuxt_plugin_truncate_618992dc' // Source: ../plugins/truncate (mode: 'all')
import nuxt_plugin_iconInjector_56876d7a from 'nuxt_plugin_iconInjector_56876d7a' // Source: ../plugins/iconInjector (mode: 'all')
import nuxt_plugin_api_5e4622e4 from 'nuxt_plugin_api_5e4622e4' // Source: ../plugins/api (mode: 'all')
import nuxt_plugin_uid_5e466d1a from 'nuxt_plugin_uid_5e466d1a' // Source: ../plugins/uid (mode: 'all')
import nuxt_plugin_livestream_1643413c from 'nuxt_plugin_livestream_1643413c' // Source: ../plugins/livestream (mode: 'all')
import nuxt_plugin_ratingsnackbar_7fff04ff from 'nuxt_plugin_ratingsnackbar_7fff04ff' // Source: ../plugins/rating-snackbar (mode: 'client')
import nuxt_plugin_hotjar_607e9cb8 from 'nuxt_plugin_hotjar_607e9cb8' // Source: ../plugins/hotjar (mode: 'client')
import nuxt_plugin_chathook_64c7787c from 'nuxt_plugin_chathook_64c7787c' // Source: ../plugins/chat-hook (mode: 'client')
import nuxt_plugin_livestreamwindow_327f968a from 'nuxt_plugin_livestreamwindow_327f968a' // Source: ../plugins/livestream-window (mode: 'client')
import nuxt_plugin_notifications_36de4932 from 'nuxt_plugin_notifications_36de4932' // Source: ../plugins/notifications (mode: 'client')
import nuxt_plugin_messagehandler_40d3e564 from 'nuxt_plugin_messagehandler_40d3e564' // Source: ../plugins/message-handler (mode: 'client')
import nuxt_plugin_errorsnackbar_a3ed8fc0 from 'nuxt_plugin_errorsnackbar_a3ed8fc0' // Source: ../plugins/error-snackbar (mode: 'client')
import nuxt_plugin_plugin_531d3660 from 'nuxt_plugin_plugin_531d3660' // Source: ./auth/plugin.js (mode: 'all')
import nuxt_plugin_ability_c17e2e18 from 'nuxt_plugin_ability_c17e2e18' // Source: ../plugins/ability (mode: 'all')
import nuxt_plugin_auth_7f7561ce from 'nuxt_plugin_auth_7f7561ce' // Source: ../plugins/auth.js (mode: 'all')
import nuxt_plugin_navigation_4a5188a6 from 'nuxt_plugin_navigation_4a5188a6' // Source: ../plugins/navigation.js (mode: 'all')
import nuxt_plugin_socket_3b036b2e from 'nuxt_plugin_socket_3b036b2e' // Source: ../plugins/socket (mode: 'client')
import nuxt_plugin_nativepush_26ba35da from 'nuxt_plugin_nativepush_26ba35da' // Source: ../plugins/native-push (mode: 'client')
import nuxt_plugin_survey_3a4c5b60 from 'nuxt_plugin_survey_3a4c5b60' // Source: ../plugins/survey (mode: 'client')
import nuxt_plugin_metatracking_35c3946e from 'nuxt_plugin_metatracking_35c3946e' // Source: ../plugins/meta-tracking (mode: 'client')
import nuxt_plugin_meta_71ff89f0 from 'nuxt_plugin_meta_71ff89f0' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":(title) =>
    title.length > 55 ? title : `${title} | Oabat`,"title":"Ausbildungsplätze und Schülerpraktika in Deiner Region ","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=5"}],"link":[{"rel":"preconnect","href":"https:\u002F\u002Fuse.typekit.net","crossorigin":true},{"rel":"preconnect","href":"https:\u002F\u002Fcdn.jsdelivr.net","crossorigin":true},{"rel":"preconnect","href":"https:\u002F\u002Ffonts.gstatic.com","crossorigin":true}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_de90f7e4 === 'function') {
    await nuxt_plugin_plugin_de90f7e4(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_2c622fe8 === 'function') {
    await nuxt_plugin_sentryserver_2c622fe8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_72ae8340 === 'function') {
    await nuxt_plugin_sentryclient_72ae8340(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_72722ac0 === 'function') {
    await nuxt_plugin_plugin_72722ac0(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_06ff8b14 === 'function') {
    await nuxt_plugin_plugin_06ff8b14(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatespluginade8f9b6_b9f79cd2 === 'function') {
    await nuxt_plugin_templatespluginade8f9b6_b9f79cd2(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_edcca868 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_edcca868(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_5450d040 === 'function') {
    await nuxt_plugin_workbox_5450d040(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_70f5d753 === 'function') {
    await nuxt_plugin_metaplugin_70f5d753(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_759d7cc7 === 'function') {
    await nuxt_plugin_iconplugin_759d7cc7(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5864d424 === 'function') {
    await nuxt_plugin_axios_5864d424(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_369061ed === 'function') {
    await nuxt_plugin_nuxtuseragent_369061ed(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_29710eaf === 'function') {
    await nuxt_plugin_portalvue_29710eaf(app.context, inject)
  }

  if (typeof nuxt_plugin_moduleplugin794ebfcb_3578e88c === 'function') {
    await nuxt_plugin_moduleplugin794ebfcb_3578e88c(app.context, inject)
  }

  if (typeof nuxt_plugin_router_68823c0b === 'function') {
    await nuxt_plugin_router_68823c0b(app.context, inject)
  }

  if (typeof nuxt_plugin_connectionSpeedpluginServer555822ae_f4ef1d00 === 'function') {
    await nuxt_plugin_connectionSpeedpluginServer555822ae_f4ef1d00(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_connectionSpeedplugin4a6f3f1e_207213e0 === 'function') {
    await nuxt_plugin_connectionSpeedplugin4a6f3f1e_207213e0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fullscreenMediaplugin2bfbbd51_485545ba === 'function') {
    await nuxt_plugin_fullscreenMediaplugin2bfbbd51_485545ba(app.context, inject)
  }

  if (typeof nuxt_plugin_abTestingplugin2d9ea452_6750f520 === 'function') {
    await nuxt_plugin_abTestingplugin2d9ea452_6750f520(app.context, inject)
  }

  if (typeof nuxt_plugin_oabalyticspluginServere2fd9abc_7e1867b3 === 'function') {
    await nuxt_plugin_oabalyticspluginServere2fd9abc_7e1867b3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_oabalyticsplugin2c297765_13d8520d === 'function') {
    await nuxt_plugin_oabalyticsplugin2c297765_13d8520d(app.context, inject)
  }

  if (typeof nuxt_plugin_mergeOptions_27c73488 === 'function') {
    await nuxt_plugin_mergeOptions_27c73488(app.context, inject)
  }

  if (typeof nuxt_plugin_axiosprovider_2192f65e === 'function') {
    await nuxt_plugin_axiosprovider_2192f65e(app.context, inject)
  }

  if (typeof nuxt_plugin_breakpoint_4e0af832 === 'function') {
    await nuxt_plugin_breakpoint_4e0af832(app.context, inject)
  }

  if (typeof nuxt_plugin_images_5d5ea524 === 'function') {
    await nuxt_plugin_images_5d5ea524(app.context, inject)
  }

  if (typeof nuxt_plugin_truncate_618992dc === 'function') {
    await nuxt_plugin_truncate_618992dc(app.context, inject)
  }

  if (typeof nuxt_plugin_iconInjector_56876d7a === 'function') {
    await nuxt_plugin_iconInjector_56876d7a(app.context, inject)
  }

  if (typeof nuxt_plugin_api_5e4622e4 === 'function') {
    await nuxt_plugin_api_5e4622e4(app.context, inject)
  }

  if (typeof nuxt_plugin_uid_5e466d1a === 'function') {
    await nuxt_plugin_uid_5e466d1a(app.context, inject)
  }

  if (typeof nuxt_plugin_livestream_1643413c === 'function') {
    await nuxt_plugin_livestream_1643413c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ratingsnackbar_7fff04ff === 'function') {
    await nuxt_plugin_ratingsnackbar_7fff04ff(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hotjar_607e9cb8 === 'function') {
    await nuxt_plugin_hotjar_607e9cb8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_chathook_64c7787c === 'function') {
    await nuxt_plugin_chathook_64c7787c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_livestreamwindow_327f968a === 'function') {
    await nuxt_plugin_livestreamwindow_327f968a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_notifications_36de4932 === 'function') {
    await nuxt_plugin_notifications_36de4932(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_messagehandler_40d3e564 === 'function') {
    await nuxt_plugin_messagehandler_40d3e564(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_errorsnackbar_a3ed8fc0 === 'function') {
    await nuxt_plugin_errorsnackbar_a3ed8fc0(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_531d3660 === 'function') {
    await nuxt_plugin_plugin_531d3660(app.context, inject)
  }

  if (typeof nuxt_plugin_ability_c17e2e18 === 'function') {
    await nuxt_plugin_ability_c17e2e18(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7f7561ce === 'function') {
    await nuxt_plugin_auth_7f7561ce(app.context, inject)
  }

  if (typeof nuxt_plugin_navigation_4a5188a6 === 'function') {
    await nuxt_plugin_navigation_4a5188a6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_socket_3b036b2e === 'function') {
    await nuxt_plugin_socket_3b036b2e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nativepush_26ba35da === 'function') {
    await nuxt_plugin_nativepush_26ba35da(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_survey_3a4c5b60 === 'function') {
    await nuxt_plugin_survey_3a4c5b60(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_metatracking_35c3946e === 'function') {
    await nuxt_plugin_metatracking_35c3946e(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_71ff89f0 === 'function') {
    await nuxt_plugin_meta_71ff89f0(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
