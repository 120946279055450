import { v4 as uuidv4 } from "uuid"
import { COOKIE_LIFETIME_MEDIUM } from "@/enums/cookies"
const IS_DISABLED = false
const COOKIE_KEY = "ab.identifier"

export default ({ store, app }, inject) => {
    if (IS_DISABLED) return
    if (store.state.account.isImpersonated) return

    function getIdentifier() {
        let value = app.$cookies.get(COOKIE_KEY)
        if (!value) {
            value = uuidv4()
            app.$cookies.set(COOKIE_KEY, value, {
                  maxAge: COOKIE_LIFETIME_MEDIUM,
            })
        }
        return value
    }

    function reset() {
        app.$cookies.remove(COOKIE_KEY)
    }

    const isA = getIdentifier().charCodeAt(0) % 2 === 0
    inject("abTesting", {
        getIdentifier,
        reset,
        isA,
        isB: !isA,
    })
}
