/** Localstorage key for the first time user */
export const LS_FIRST_TIME_USER = "firstTimeUser"
export const LS_ARTICLE_EDITOR_CACHE = "articleEditorCache"

/**
 * @param {string} prefix Localstorage key
 * @param defaultType Default type for the value
 * @return {any} Value from localstorage
 */
export function getStorageData(
    prefix,
    defaultType = {},
    checkExpiration = false
) {
    try {
        let value = JSON.parse(localStorage.getItem(prefix))
        if (value?._expiry) {
            value = value.value
            if (checkExpiration && new Date().getTime() > value._expiry) {
                // If the item is expired, delete the item from storage
                // and return null
                localStorage.removeItem(prefix)
                return defaultType
            }
        }
        if (value !== null && typeof value !== typeof defaultType) {
            console.warn(
                `LSHandler: Value for entry ${prefix} is not of defaultType ${typeof defaultType}`,
                value
            )
        }
        return value ?? defaultType
    } catch (e) {
        console.warn(e)
    }
    return defaultType
}

/**
 * @param {string} prefix Localstorage key
 * @param {any} value Value to store
 * @param {number} expiration Expiration in milliseconds
 * */
export function setStorageData(prefix, value, expiration = null) {
    try {
        let innerValue = JSON.parse(JSON.stringify(value))
        if (expiration) {
            innerValue = {
                value,
                _expiry: Date.now() + expiration,
            }
        }
        localStorage.setItem(prefix, JSON.stringify(innerValue))
    } catch (e) {
        if (e.code === "22" || e.code === "1024") {
            // Clear if quota is full
            localStorage.removeItem(prefix)
        }
        console.warn(e)
    }
}

export function removeStorageData(prefix) {
    localStorage.removeItem(prefix)
}

export default {
    getStorageData,
    setStorageData,
    LS_FIRST_TIME_USER,
    LS_ARTICLE_EDITOR_CACHE,
}

/**
 * @param {string} LSkey Localstorage key
 * @param {boolean} isArray If true, value is an array, else an object
 * @returns {object} Object with get, set, add and delete methods for the given key
 */
export function createLocalStorageEntity(LSkey, isArray = false) {
    return {
        /** Optional key, otherwise all data */
        get(key = null) {
            _checkEnvironment()
            const data = getStorageData(LSkey, isArray ? [] : {})
            if (key === null) return data
            return data[key]
        },
        set(value) {
            _checkEnvironment()
            if (isArray && !Array.isArray(value))
                console.warn(
                    `LSHandler: Value for entry ${LSkey} is not an array`,
                    value
                )
            if (!isArray && typeof value !== "object")
                console.warn(
                    `LSHandler: Value for entry ${LSkey} is not an object`,
                    value
                )
            setStorageData(LSkey, value)
        },
        /**
         * key becomes value for array (push)
         * value becomes boolean if duplicates should be avoided
         *  */
        add(key, value) {
            _checkEnvironment()
            const data = this.get()
            if (isArray) {
                /* Duplicates check */
                if (value && data.includes(key)) return
                data.push(key)
            } else {
                data[key] = value
            }
            this.set(data)
        },
        /** Key becomes reference-match for array.
         * If function is provided, its used for index evaluation */
        delete(key) {
            _checkEnvironment()
            const data = this.get()
            if (!isArray) {
                delete data[key]
            } else {
                let index = -1
                if (typeof key === "function") {
                    index = data.findIndex(key)
                } else {
                    index = data.indexOf(key)
                }
                if (index !== -1) data.splice(index, 1)
            }
            this.set(data)
        },
        clear() {
            _checkEnvironment()
            localStorage.removeItem(LSkey)
        },
    }
}

function _checkEnvironment() {
    if (!process.client)
        throw new Error("Cannot use localstorageEntity on server")
}
