export default ({ app }, inject) => {
    /** USER ROLES **/
    function admin() {
        return app.$auth.loggedIn && app.$auth.user.email === "admin@oabat.de"
    }
    function support() {
        return (
            app.$auth.loggedIn &&
            (app.$auth.user.email === "admin@oabat.de" ||
                app.$auth.user.email === "hello@oabat.de")
        )
    }
    function adminPolls() {
        return (
            app.$auth.loggedIn &&
            app.$auth.user.business &&
            app.$auth.user.business.permissions &&
            app.$auth.user.business.permissions.includes(
                "admin_livestream_polls"
            )
        )
    }
    function permission(name) {
        return (
            app.$auth.loggedIn &&
            app.$auth.user.business &&
            app.$auth.user.business.permissions &&
            app.$auth.user.business.permissions.includes(name)
        )
    }
    function school() {
        return app.$auth.loggedIn && !!app.$auth.user.school
    }
    function business() {
        return app.$auth.loggedIn && !!app.$auth.user.business
    }
    function trainee() {
        return app.$auth.loggedIn && !!app.$auth.user.trainee
    }
    function region() {
        return app.$auth.loggedIn && !!app.$auth.user.region
    }

    /** PERMISSIONS **/
    function chat() {
        return trainee() || region() || !app.$auth.loggedIn
    }
    function editBusiness(businessToEdit) {
        return (
            businessToEdit &&
            business() &&
            app.$auth.user.business.slug === businessToEdit.slug
        )
    }
    function like() {
        return trainee() || !app.$auth.loggedIn
    }
    function sendInterest() {
        return trainee() || !app.$auth.loggedIn
    }
    function network() {
        return business() || school()
    }
    function editSchool(slug) {
        return school() && app.$auth.user.school.slug === slug
    }
    function editRegion(slug) {
        return region() && app.$auth.user.region.slug === slug
    }
    function editMaterials(channelSlug) {
        return (
            (region() && app.$auth.user.region.channel.slug === channelSlug) ||
            (school() && app.$auth.user.school.channel.slug === channelSlug)
        )
    }
    function editEvents(channelSlug) {
        return (
            (region() && app.$auth.user.region.channel.slug === channelSlug) ||
            (school() && app.$auth.user.school.channel.slug === channelSlug)
        )
    }
    function isPremium() {
        return hasSubscription(2)
    }
    function hasSubscription(subscriptionId) {
        if (!business()) return false
        const subscriptions = app.$auth.user.business.subscription_types
        return subscriptions.some(
            (subscription) => subscription.id === subscriptionId
        )
    }
    function showBusinessSites() {
        if (!business()) return false
        return app.$auth.user.business.business_sites.length > 1
    }
    const can = {
        school,
        business,
        trainee,
        admin,
        support,
        adminPolls,
        region,
        permission,
        sendInterest,
        editMaterials,
        chat,
        editBusiness,
        like,
        network,
        editSchool,
        editRegion,
        editEvents,
        isPremium,
        showBusinessSites,
        hasSubscription,
    }

    inject("can", can)
}
