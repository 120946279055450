export default {
    getImage(id) {
        return this.api.get(`/api/images/${id}`)
    },
    uploadImage(image) {
        return this.api.post("/api/images", image)
    },
    updateImage(image, id) {
        return this.api.put(`/api/images/${id}`, image)
    },
    deleteImage(id) {
        return this.api.del(`/api/images/${id}`)
    },
    /** Returns object containing api methods */
    asClass(file, isPrivate = false) {
        if (file instanceof OabatImage) return file
        return new OabatImage(file, isPrivate, this)
    },
}

function _toBase64(file) {
    if (file.type.includes("svg")) {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = function () {
                const resizedCanvas = document.createElement("canvas")
                const resizedContext = resizedCanvas.getContext("2d")
                resizedContext.drawImage(img, 0, 0, img.width, img.height)
                resolve(resizedCanvas.toDataURL("image/jpeg"))
            }
            img.onerror = (error) => reject(error)
            img.src = URL.createObjectURL(file)
        })
    }
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })
}

class OabatImage {
    constructor(image, isPrivate, api) {
        if (!image) return
        this.isImage = true
        this.type = "image"
        let _conversionPromise
        this.request = null
        this.setPromise = (value) => (_conversionPromise = value) // Private value
        this.getPromise = () => _conversionPromise // Private value
        this.getApi = () => api // Private value
        this.getPrivate = () => image.is_private ?? isPrivate
        this.setSource(image)
    }
    async create() {
        this.cancel()
        if (!this.id) {
            await this.getPromise()
            this.request = this.getApi().uploadImage({
                image: this.local,
                public: !this.getPrivate(),
            })

            const response = await this.request.request
            this.setSource(response)
        }
    }
    async update(image) {
        this.cancel()
        this.setSource(image)
        if (!this.id) {
            this.create()
        } else {
            await this.getPromise()
            this.request = this.getApi().updateImage(
                { image: this.local },
                this.id
            )
            const response = await this.request.request
            this.setSource(response)
        }
    }
    delete() {
        this.cancel()
        if (this.id) {
            this.getApi().deleteImage(this.id)
        }
    }
    cancel() {
        this.request?.cancel()
    }
    setSource(image) {
        this.request = this.request ?? null
        this.local = this.local ?? null
        this.uid = image?.uid ?? this.uid
        this.id = image?.id ?? this.id
        this.file_name = image?.file_name
        this.file_path = image?.file_path
        this.urls = image?.urls
        if (typeof image === "string") {
            this.local = image
        }
        if (typeof image === "object" && "type" in image && "size" in image) {
            this.setPromise(
                _toBase64(image).then((base64) => (this.local = base64))
            )
        }
    }
    toJSON() {
        return {
            id: this.id,
            file_name: this.file_name,
            file_path: this.file_path,
            is_private: this.getPrivate(),
        }
    }
}
