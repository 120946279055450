import jobFairService from "@/services/jobFair.service"
import searchService from "@/services/search.service"
import schoolService from "@/services/school.service"
import eventService from "@/services/event.service"

export const state = () => ({
    isLoaded: false,
    jobFairs: [],
    school: null,
    events: [],
    posts: [],
    totalPosts: 0,
})

export const actions = {
    async initialize({ commit, state }, { trainee, force }) {
        if (state.isLoaded && !force) {
            return
        }
        const searchData = {
            category: "Neuigkeiten",
            channel: "oabat",
            filters: {
                latitude: trainee.primary_address.lat,
                longitude: trainee.primary_address.lng,
            },
            limit: 20,
        }
        const jobFairs = await jobFairService.getJobFairs()
        const postsResult = await searchService.performSearch(searchData)
        let school = null
        if (trainee.school) {
            school = await schoolService.getSchool(trainee.school.slug)
        }
        const { upcoming } = await eventService.getEvents("oabat")
        commit("SET_POSTS", postsResult.result.data)
        commit("SET_TOTAL_POSTS", postsResult.result.total)
        commit("SET_EVENTS", upcoming)
        commit("SET_JOBFAIRS", jobFairs)
        commit("SET_SCHOOL", school)
        commit("SET_IS_LOADED")
    },
}

export const mutations = {
    SET_ONBOARDING(state, value) {
        state.onboarding = value
    },
    SET_EVENTS(state, value) {
        state.events = value
    },
    SET_POSTS(state, value) {
        state.posts = value
    },
    SET_TOTAL_POSTS(state, value) {
        state.totalPosts = value
    },
    SET_JOBFAIRS(state, value) {
        state.jobFairs = value
    },
    SET_SCHOOL(state, value) {
        state.school = value
    },
    SET_IS_LOADED(state) {
        state.isLoaded = true
    },
}

export const getters = {
    school: (state) => state.school,
    posts: (state) => state.posts,
    totalPosts: (state) => state.totalPosts,
    events: (state) => state.events,
    jobFairs: (state) => state.jobFairs,
    isLoaded: (state) => state.isLoaded,
}
