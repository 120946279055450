function createEvent(event) {
    /**
        'title'      => ['string', 'max:255', 'required'],
        'text'       => ['string', 'required', 'max:5000'],
        'start_at'   => ['date', 'required'],
        'end_at'     => ['date', 'nullable'],
        'image_id'   => ['exists:images,id', 'nullable'],
        'channel_id' => ['exists:channels,id', 'required'],
     */
    return this.$axios
        .$post("/api/events", {
            ...event,
        })
        .then((response) => {
            return response.data
        })
}

function updateEvent(slug, event) {
    /**
        'title'    => ['string', 'max:255'],
        'text'     => ['string', 'max:5000'],
        'start_at' => ['date'],
        'end_at'   => ['date', 'nullable'],
        'image_id' => ['exists:images,id', 'nullable'],
     */
    return this.$axios
        .$put("/api/events/" + slug, {
            ...event,
        })
        .then((response) => {
            return response.data
        })
}

function getEvent(slug) {
    return this.$axios.$get("/api/events/" + slug).then((response) => {
        return response.data
    })
}

function getEvents(channel) {
    if (!channel) {
        return []
    }
    return this.$axios
        .$get("/api/events", {
            params: {
                channel: channel,
            },
        })
        .then(({ data }) => {
            let past = []
            let upcoming = []
            for (let event of data) {
                if (new Date(event.start_at) < new Date()) {
                    past.push(event)
                } else {
                    upcoming.push(event)
                }
            }
            return { past, upcoming }
        })
}

const eventService = {
    createEvent,
    updateEvent,
    getEvent,
    getEvents,
}

export default eventService
