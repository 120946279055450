export const state = () => ({
    jobs: [],
    jobTypes: [],
    displayedJob: null,
    displayedMinimalJob: null,
    displayType: null,
    bonuses: [],
    createApprenticeship: false,
    createTraineeship: false,
    usedAttributes: [],
    usedAdvantages: [],
    graduations: [
        { label: "Kein Schulabschluss erforderlich", value: "none" },
        { label: "Mittelschulabschluss", value: "main" },
        { label: "Qualifizierender Hauptschulabschluss", value: "quali" },
        { label: "Mittlerer Schulabschluss", value: "mittel" },
        { label: "Fachabitur", value: "fos" },
        { label: "Abitur", value: "abi" },
        { label: "Bachelor", value: "bachelor" },
        { label: "Master", value: "master" },
        { label: "Diplom", value: "diploma" },
    ],
    crafts: [
        {
            label: "IT & Technik",
            value: "it",
            img: require("@/assets/images/categories/it.jpg"),
            webpImg: "/images/it.webp",
        },
        {
            label: "Handwerk",
            value: "craft",
            img: require("@/assets/images/categories/handwerk.jpg"),
            webpImg: "/images/handwerk.webp",
        },
        {
            label: "Verwaltung & Büro",
            value: "office",
            img: require("@/assets/images/categories/unternehmen.jpg"),
            webpImg: "/images/unternehmen.webp",
        },
        {
            label: "Menschen helfen",
            value: "social",
            img: require("@/assets/images/categories/social.jpg"),
            webpImg: "/images/social.webp",
        },
        {
            label: "Verkauf & Handel",
            value: "sales",
            img: require("@/assets/images/categories/sales.jpg"),
            webpImg: "/images/sales.webp",
        },
        {
            label: "Kunst & Unterhaltung",
            value: "art",
            img: require("@/assets/images/categories/art.jpg"),
            webpImg: "/images/art.webp",
        },
    ],
    selectedTrainer: {
        id: null,
        business_id: null,
        name: null,
        description: null,
        image: null,
    },
})

export const getters = {
    //  somehow all these getters are called right away on application start
    displayedMinimalJob: (state) => state.displayedMinimalJob,
    displayedJob: (state) => state.displayedJob,
    crafts: (state) => (state.crafts ? state.crafts : []),
    craft: (state) => (craftValue) => {
        return state.crafts.find((element) => element.value === craftValue)
    },
}

export const actions = {
    async getJobBySlug({ commit }, { slug }) {
        let job = null

        job = await this.$axios
            .$get("/api/v2/jobs/" + slug)
            .then((res) => res.data)

        commit("SET_DISPLAYED_JOB", {
            job: job,
            displayType: "display",
        })
    },
    delete({ commit }, { slug }) {
        return new Promise((resolve, reject) => {
            this.$axios
                .delete("/api/jobs/" + slug)
                .then(() => {
                    commit("RESET_DISPLAYED_JOB")
                    resolve()
                })
                .catch(() => {
                    reject()
                })
        })
    },
    loadJob({ commit }, jobID) {
        this.$axios
            .get("/api/job/" + jobID)
            .then((response) => {
                commit("SET_CURRENT_JOB", response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    },
    setRequested({ commit }, value) {
        commit("SET_ALREADY_REQUESTED", value)
    },
}

export const mutations = {
    SET_USED_ATTRIBUTES(state, attributes) {
        state.usedAttributes = attributes
    },
    SET_USED_ADVANTAGES(state, advantages) {
        state.usedAdvantages = advantages
    },
    SET_DISPLAYED_JOB(state, { job, displayType }) {
        if (job && job.type_id === 3) {
            if (job.content_blocks?.length > 0) {
                const legacyData = job.content_blocks.find(
                    (el) => el.component === "legacy"
                )
                if (legacyData) {
                    job = { ...job, ...legacyData.data }
                }
            }
        }
        state.displayedJob = job
        state.displayType = displayType
    },
    SET_DISPLAYED_MINIMAL_JOB(state, job) {
        state.displayedMinimalJob = job
    },
    RESET_DISPLAYED_JOB(state) {
        state.displayedJob = null
        state.displayType = null
    },
    SET_CURRENT_JOB(state, job) {
        state.jobCreateEdit = job.data
        state.displayedJob = job.data
        if (job.type_id === 1) {
            state.createApprenticeship = true
            state.createTraineeship = false
        } else if (job.type_id === 2) {
            state.createApprenticeship = false
            state.createTraineeship = true
        }
    },
}
