import Vue from "vue"
import ErrorSnackbarComponent from "@/components/global/ErrorSnackbar"
export default async function ({ app }) {
    app.mixins = app.mixins || []
    app.mixins.push({
        mounted() {
            const ErrorSnackbar = Vue.component(
                "ErrorSnackbar",
                ErrorSnackbarComponent
            )
            const SnackbarComponent = new ErrorSnackbar({ parent: this })
            let element = document.getElementById("__nuxt")
            let elementNode = document.createElement("div")
            elementNode.setAttribute("id", "errorSnackbar")
            element.appendChild(elementNode)
            SnackbarComponent.$mount("#errorSnackbar")
        },
    })
}
