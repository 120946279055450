
import { close } from "@/assets/icons/js"
import FixedDialog from "@/components/base/modal/FixedDialog"
export default {
    name: "Modal",
    emits: ["close", "back"],
    components: {
        FixedDialog,
        Button: () => import("@/components/base/Button"),
    },
    model: {
        prop: "dialog",
        event: "close",
    },
    props: {
        /** Used to activate the modal & overlay */
        dialog: {
            type: Boolean,
            default: false,
        },
        /** Classes assigned to content card */
        contentClass: {
            type: String,
            default: "",
        },
        /** Sets modal fullscreen */
        fullscreen: {
            type: Boolean,
            default: false,
        },
        /** Adds close button */
        hideCloseBtn: {
            type: Boolean,
            default: false,
        },
        /** Centers the header. ATTENTION: Allows overflow below close button!
         * Not needed if hideCloseBtn is true
         */
        centerHeader: {
            type: Boolean,
            default: false,
        },
        /** Dark variant */
        dark: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            close,
            scrollPositionY: null,
        }
    },
    computed: {
        model: {
            get() {
                return this.dialog
            },
            set(value) {
                this.$emit("close", value)
            },
        },
        /* Needs to return pre-created class string */
        modalClasses() {
            let modalClasses = "oabat-modal"
            if (this.fullscreen) modalClasses += " oabat-modal--fullscreen"
            return modalClasses
        },
        /* Needs to return pre-created class string */
        overlayClasses() {
            let overlayClasses = "oabat-overlay"
            if (this.fullscreen) overlayClasses += "oabat-overlay--fullscreen"
            return overlayClasses
        },
        iconColor() {
            return this.dark ? "white" : "black"
        },
    },
    watch: {
        model(val) {
            if (this.$ua.isFromIos() && process.client) {
                if (val) {
                    document.documentElement.style.setProperty(
                        "--window-inner-height",
                        `${window.innerHeight}px`
                    )
                    this.scrollPositionY = window.scrollY
                    document.documentElement.classList.add("is-locked")
                } else {
                    document.documentElement.classList.remove("is-locked")
                    // restore scroll position
                    if (this.scrollPositionY) {
                        window.scrollTo(0, this.scrollPositionY)
                    }
                }
            }
        },
    },
    beforeDestroy() {
        if (this.$ua.isFromIos() && process.client) {
            document.documentElement.classList.remove("is-locked")
            if (this.scrollPositionY) {
                window.scrollTo(0, this.scrollPositionY)
            }
        }
    },
}
