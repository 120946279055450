export const state = () => ({
    layout: "main-layout",
    webpSupported: process.browser
        ? localStorage.getItem("webpSupported")
        : null || null,
    currentPosts: [],
    initialPostIDs: [],
})

export const mutations = {
    SET_WEBP_SUPPORT(state, payload) {
        state.webpSupported = payload
        localStorage.setItem("webpSupported", payload)
    },
    SET_LAYOUT(state, payload) {
        state.layout = payload
    },
    UPDATE_CURRENT_POSTS(state, data) {
        state.currentPosts = data.posts
    },
}

export const getters = {
    webpSupported: (state) => {
        return state.webpSupported == "true" || state.webpSupported == true
    },
    layout(state) {
        return state.layout
    },
    posts: (state) =>
        state.currentPosts.length > 0 ? state.currentPosts : null,
}

export const actions = {
    async checkForWebpSupport({ commit, state }) {
        if (state.webpSupported == null) {
            // If browser doesn't have createImageBitmap, we can't use webp.
            if (!self.createImageBitmap) {
                commit("SET_WEBP_SUPPORT", false)
                return
            }

            // Base64 representation of a white point image
            const webpData =
                "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA="
            // Retrieve the Image in Blob Format
            const blob = await fetch(webpData).then((r) => r.blob())
            // If the createImageBitmap method succeeds, return true, otherwise false
            await createImageBitmap(blob)
                .then(() => commit("SET_WEBP_SUPPORT", true))
                .catch(() => commit("SET_WEBP_SUPPORT", false))
        }
    },
}
