const defaultSearchRouting = {
    getRouting(currentRoute) {
        return [
            {
                name: "Alles",
                route: {
                    name: "ausbildung-locationSlug",
                    params: { locationSlug: currentRoute.params.locationSlug },
                },
            },
            {
                name: "Ausbildung",
                route: {
                    name: "ausbildung-locationSlug",
                    params: { locationSlug: currentRoute.params.locationSlug },
                },
            },
            {
                name: "Praktikum",
                route: {
                    name: "ausbildung-locationSlug",
                    params: { locationSlug: currentRoute.params.locationSlug },
                    query: { praktikum: null },
                },
            },
            {
                name: "Unternehmen",
                route: {
                    name: "unternehmen-locationSlug",
                    params: { locationSlug: currentRoute.params.locationSlug },
                },
            },
            {
                name: "Neuigkeiten",
                route: {
                    name: "news-locationSlug",
                    params: { locationSlug: currentRoute.params.locationSlug },
                },
            },
        ]
    },
}
export default defaultSearchRouting
