export default {
    /**
     * Paginated query
     * Allows filtering for user_id, rating, type
     * @param {Object} params
     * @returns paginated results
     */
    get(params) {
        return this.$axios.$get("/api/v2/reviews", {
            params,
        })
    },
    /**
     *
     * @param {Number} rating from 1 to 10 (half steps)
     * @param {String} message users message
     * @param {String} type context information
     * @returns
     */
    post(rating, message, type) {
        return this.$axios.$post(`/api/v2/reviews`, { rating, message, type })
    },
}
