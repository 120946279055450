import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ae6c64d8 = () => interopDefault(import('../modules/review/pages/ReviewPage.vue' /* webpackChunkName: "" */))
const _35f471a0 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _6933b2e2 = () => interopDefault(import('../pages/abmelden.vue' /* webpackChunkName: "pages/abmelden" */))
const _4078d792 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _349e5458 = () => interopDefault(import('../pages/admin/artikel.vue' /* webpackChunkName: "pages/admin/artikel" */))
const _be0c3530 = () => interopDefault(import('../pages/admin/betriebe.vue' /* webpackChunkName: "pages/admin/betriebe" */))
const _01ccde3c = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _0cc53f80 = () => interopDefault(import('../pages/admin/joblists.vue' /* webpackChunkName: "pages/admin/joblists" */))
const _8e80583e = () => interopDefault(import('../pages/admin/kampagne/index.vue' /* webpackChunkName: "pages/admin/kampagne/index" */))
const _08dc40ec = () => interopDefault(import('../pages/admin/konversationen.vue' /* webpackChunkName: "pages/admin/konversationen" */))
const _5b8b8caf = () => interopDefault(import('../pages/admin/livestreams.vue' /* webpackChunkName: "pages/admin/livestreams" */))
const _671aa4ae = () => interopDefault(import('../pages/admin/messen.vue' /* webpackChunkName: "pages/admin/messen" */))
const _0d666fce = () => interopDefault(import('../pages/admin/oabalytics/index.vue' /* webpackChunkName: "pages/admin/oabalytics/index" */))
const _6223d4b6 = () => interopDefault(import('../pages/admin/regionen.vue' /* webpackChunkName: "pages/admin/regionen" */))
const _2dd31740 = () => interopDefault(import('../pages/admin/schulen.vue' /* webpackChunkName: "pages/admin/schulen" */))
const _75dd2a82 = () => interopDefault(import('../pages/admin/uebersicht.vue' /* webpackChunkName: "pages/admin/uebersicht" */))
const _a2aa028a = () => interopDefault(import('../pages/admin/umfragen.vue' /* webpackChunkName: "pages/admin/umfragen" */))
const _12bdbff3 = () => interopDefault(import('../pages/admin/event/erstellen.vue' /* webpackChunkName: "pages/admin/event/erstellen" */))
const _26acf1b4 = () => interopDefault(import('../pages/admin/kampagne/bewerben/_mediaId.vue' /* webpackChunkName: "pages/admin/kampagne/bewerben/_mediaId" */))
const _591b49d4 = () => interopDefault(import('../pages/admin/kampagne/erstellen/_templateId.vue' /* webpackChunkName: "pages/admin/kampagne/erstellen/_templateId" */))
const _ab91861c = () => interopDefault(import('../pages/admin/oabalytics/users/_id/index.vue' /* webpackChunkName: "pages/admin/oabalytics/users/_id/index" */))
const _5ea7a7c0 = () => interopDefault(import('../pages/admin/oabalytics/users/_id/detailed.vue' /* webpackChunkName: "pages/admin/oabalytics/users/_id/detailed" */))
const _f681221a = () => interopDefault(import('../pages/admin/livestream/_livestream/index.vue' /* webpackChunkName: "pages/admin/livestream/_livestream/index" */))
const _0f4d4f72 = () => interopDefault(import('../pages/admin/livestream/_livestream/chat.vue' /* webpackChunkName: "pages/admin/livestream/_livestream/chat" */))
const _24fda3c8 = () => interopDefault(import('../pages/admin/livestream/_livestream/events.vue' /* webpackChunkName: "pages/admin/livestream/_livestream/events" */))
const _8fe44834 = () => interopDefault(import('../pages/admin/livestream/_livestream/timer.vue' /* webpackChunkName: "pages/admin/livestream/_livestream/timer" */))
const _08180ae7 = () => interopDefault(import('../pages/agb/index.vue' /* webpackChunkName: "pages/agb/index" */))
const _0bfc3878 = () => interopDefault(import('../pages/agb-messe.vue' /* webpackChunkName: "pages/agb-messe" */))
const _6890345f = () => interopDefault(import('../pages/app-datenschutz.vue' /* webpackChunkName: "pages/app-datenschutz" */))
const _5120ffd0 = () => interopDefault(import('../pages/betrieb/index.vue' /* webpackChunkName: "pages/betrieb/index" */))
const _8aa329aa = () => interopDefault(import('../pages/betriebe/index.vue' /* webpackChunkName: "pages/betriebe/index" */))
const _7dd1c220 = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _4157f330 = () => interopDefault(import('../pages/einstellungen/index.vue' /* webpackChunkName: "pages/einstellungen/index" */))
const _98df4c8c = () => interopDefault(import('../pages/favoriten.vue' /* webpackChunkName: "pages/favoriten" */))
const _1f7fe443 = () => interopDefault(import('../pages/idee.vue' /* webpackChunkName: "pages/idee" */))
const _1ff52ebc = () => interopDefault(import('../pages/impressum/index.vue' /* webpackChunkName: "pages/impressum/index" */))
const _5cec67ea = () => interopDefault(import('../pages/kontakt/index.vue' /* webpackChunkName: "pages/kontakt/index" */))
const _3b125362 = () => interopDefault(import('../pages/nutzungsbedingungen/index.vue' /* webpackChunkName: "pages/nutzungsbedingungen/index" */))
const _ad70b570 = () => interopDefault(import('../pages/nutzungsbedingungen-fuer-bildungseinrichtungen/index.vue' /* webpackChunkName: "pages/nutzungsbedingungen-fuer-bildungseinrichtungen/index" */))
const _09ac5014 = () => interopDefault(import('../pages/passwort-zuruecksetzen.vue' /* webpackChunkName: "pages/passwort-zuruecksetzen" */))
const _ee92b582 = () => interopDefault(import('../pages/region/index.vue' /* webpackChunkName: "pages/region/index" */))
const _73748d61 = () => interopDefault(import('../pages/schule/index.vue' /* webpackChunkName: "pages/schule/index" */))
const _386b734c = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _527d4678 = () => interopDefault(import('../pages/testing.vue' /* webpackChunkName: "pages/testing" */))
const _968e1a7e = () => interopDefault(import('../pages/verify.vue' /* webpackChunkName: "pages/verify" */))
const _4500a454 = () => interopDefault(import('../pages/arbeitgeber/einladung.vue' /* webpackChunkName: "pages/arbeitgeber/einladung" */))
const _3343289f = () => interopDefault(import('../pages/arbeitgeber/registrierung.vue' /* webpackChunkName: "pages/arbeitgeber/registrierung" */))
const _1c73395e = () => interopDefault(import('../pages/artikel/vorschau.vue' /* webpackChunkName: "pages/artikel/vorschau" */))
const _07a8523c = () => interopDefault(import('../pages/betrieb/abonnements.vue' /* webpackChunkName: "pages/betrieb/abonnements" */))
const _63f9beef = () => interopDefault(import('../pages/betrieb/benutzer.vue' /* webpackChunkName: "pages/betrieb/benutzer" */))
const _0ad4617c = () => interopDefault(import('../pages/betrieb/channels.vue' /* webpackChunkName: "pages/betrieb/channels" */))
const _0c812f9c = () => interopDefault(import('../pages/betrieb/einblick/index.vue' /* webpackChunkName: "pages/betrieb/einblick/index" */))
const _7a05299d = () => interopDefault(import('../pages/betrieb/problem.vue' /* webpackChunkName: "pages/betrieb/problem" */))
const _22c84068 = () => interopDefault(import('../pages/betrieb/standorte.vue' /* webpackChunkName: "pages/betrieb/standorte" */))
const _7d1698bc = () => interopDefault(import('../pages/betrieb/store/index.vue' /* webpackChunkName: "pages/betrieb/store/index" */))
const _168bd18f = () => interopDefault(import('../pages/betriebe/schulnetzwerk/index.vue' /* webpackChunkName: "pages/betriebe/schulnetzwerk/index" */))
const _1ee2be26 = () => interopDefault(import('../pages/customdomain/artikelvorschau.vue' /* webpackChunkName: "pages/customdomain/artikelvorschau" */))
const _30fb4e2c = () => interopDefault(import('../pages/embed/businesses.vue' /* webpackChunkName: "pages/embed/businesses" */))
const _c45fa2d8 = () => interopDefault(import('../pages/embed/jobs.vue' /* webpackChunkName: "pages/embed/jobs" */))
const _7de06d26 = () => interopDefault(import('../pages/i/benachrichtigungen/index.vue' /* webpackChunkName: "pages/i/benachrichtigungen/index" */))
const _813d5f12 = () => interopDefault(import('../pages/i/dokumente/index.vue' /* webpackChunkName: "pages/i/dokumente/index" */))
const _b48348e8 = () => interopDefault(import('../pages/i/password/index.vue' /* webpackChunkName: "pages/i/password/index" */))
const _217ce72e = () => interopDefault(import('../pages/i/profile/index.vue' /* webpackChunkName: "pages/i/profile/index" */))
const _0984a51b = () => interopDefault(import('../pages/intern/components.vue' /* webpackChunkName: "pages/intern/components" */))
const _2875c14e = () => interopDefault(import('../pages/intern/css.vue' /* webpackChunkName: "pages/intern/css" */))
const _76e56e25 = () => interopDefault(import('../pages/intern/icons.vue' /* webpackChunkName: "pages/intern/icons" */))
const _4b58de5c = () => interopDefault(import('../pages/intern/parallax.vue' /* webpackChunkName: "pages/intern/parallax" */))
const _205a4973 = () => interopDefault(import('../pages/region/altmuehlfranken/index.vue' /* webpackChunkName: "pages/region/altmuehlfranken/index" */))
const _060378fa = () => interopDefault(import('../pages/region/betriebe.vue' /* webpackChunkName: "pages/region/betriebe" */))
const _34da17b0 = () => interopDefault(import('../pages/region/channel.vue' /* webpackChunkName: "pages/region/channel" */))
const _b5e3c0ac = () => interopDefault(import('../pages/region/einstellungen.vue' /* webpackChunkName: "pages/region/einstellungen" */))
const _e11fb15c = () => interopDefault(import('../pages/region/freyung-grafenau/index.vue' /* webpackChunkName: "pages/region/freyung-grafenau/index" */))
const _3df8f9b6 = () => interopDefault(import('../pages/region/hauzenberg/index.vue' /* webpackChunkName: "pages/region/hauzenberg/index" */))
const _0833d762 = () => interopDefault(import('../pages/region/hinzufuegen.vue' /* webpackChunkName: "pages/region/hinzufuegen" */))
const _0185c094 = () => interopDefault(import('../pages/region/inn-salzach/index.vue' /* webpackChunkName: "pages/region/inn-salzach/index" */))
const _4f05e945 = () => interopDefault(import('../pages/region/landshut/index.vue' /* webpackChunkName: "pages/region/landshut/index" */))
const _bfafe548 = () => interopDefault(import('../pages/region/lernen-vor-ort-muhldorf-speeddating.vue' /* webpackChunkName: "pages/region/lernen-vor-ort-muhldorf-speeddating" */))
const _dad88d36 = () => interopDefault(import('../pages/region/lkr-r.vue' /* webpackChunkName: "pages/region/lkr-r" */))
const _44aaaf2a = () => interopDefault(import('../pages/region/passau/index.vue' /* webpackChunkName: "pages/region/passau/index" */))
const _8cbafc4e = () => interopDefault(import('../pages/region/plattform/index.vue' /* webpackChunkName: "pages/region/plattform/index" */))
const _c7306d78 = () => interopDefault(import('../pages/region/registrierung/index.vue' /* webpackChunkName: "pages/region/registrierung/index" */))
const _390d09c5 = () => interopDefault(import('../pages/region/schulen.vue' /* webpackChunkName: "pages/region/schulen" */))
const _387ac5cc = () => interopDefault(import('../pages/schule/einstellungen.vue' /* webpackChunkName: "pages/schule/einstellungen" */))
const _9d430c28 = () => interopDefault(import('../pages/schule/firmennetzwerk.vue' /* webpackChunkName: "pages/schule/firmennetzwerk" */))
const _5b545e14 = () => interopDefault(import('../pages/schule/nutzerverwaltung.vue' /* webpackChunkName: "pages/schule/nutzerverwaltung" */))
const _114564ba = () => interopDefault(import('../pages/schule/registrierung.vue' /* webpackChunkName: "pages/schule/registrierung" */))
const _ccea94e2 = () => interopDefault(import('../pages/social/login.vue' /* webpackChunkName: "pages/social/login" */))
const _3d62f820 = () => interopDefault(import('../pages/statistiken/oabat/index.vue' /* webpackChunkName: "pages/statistiken/oabat/index" */))
const _2825a82c = () => interopDefault(import('../pages/betrieb/job/erstellen.vue' /* webpackChunkName: "pages/betrieb/job/erstellen" */))
const _24679f17 = () => interopDefault(import('../pages/betrieb/job/vorschau.vue' /* webpackChunkName: "pages/betrieb/job/vorschau" */))
const _0f7ee5c6 = () => interopDefault(import('../pages/betrieb/store/feature.vue' /* webpackChunkName: "pages/betrieb/store/feature" */))
const _7bc60cbc = () => interopDefault(import('../pages/betrieb/store/feature/api_connection.vue' /* webpackChunkName: "pages/betrieb/store/feature/api_connection" */))
const _cc9ed3ac = () => interopDefault(import('../pages/betrieb/store/feature/mail2chat.vue' /* webpackChunkName: "pages/betrieb/store/feature/mail2chat" */))
const _038601e4 = () => interopDefault(import('../pages/betrieb/store/feature/premium.vue' /* webpackChunkName: "pages/betrieb/store/feature/premium" */))
const _58384582 = () => interopDefault(import('../pages/betrieb/store/kasse.vue' /* webpackChunkName: "pages/betrieb/store/kasse" */))
const _f48a5bea = () => interopDefault(import('../pages/customdomain/artikel/erstellen.vue' /* webpackChunkName: "pages/customdomain/artikel/erstellen" */))
const _6e5916d4 = () => interopDefault(import('../pages/i/profile/editieren/index.vue' /* webpackChunkName: "pages/i/profile/editieren/index" */))
const _55316675 = () => interopDefault(import('../pages/region/inn-salzach/anmeldung.vue' /* webpackChunkName: "pages/region/inn-salzach/anmeldung" */))
const _cd665ef8 = () => interopDefault(import('../pages/region/landshut/anmeldung.vue' /* webpackChunkName: "pages/region/landshut/anmeldung" */))
const _88dce6ac = () => interopDefault(import('../pages/region/passau/anmeldung.vue' /* webpackChunkName: "pages/region/passau/anmeldung" */))
const _788aabc2 = () => interopDefault(import('../pages/betrieb/einblick/iframe/_id.vue' /* webpackChunkName: "pages/betrieb/einblick/iframe/_id" */))
const _5f4875d6 = () => interopDefault(import('../pages/customdomain/artikel/_slug.vue' /* webpackChunkName: "pages/customdomain/artikel/_slug" */))
const _069ededd = () => interopDefault(import('../pages/schule/suche/_locationSlug.vue' /* webpackChunkName: "pages/schule/suche/_locationSlug" */))
const _35aa7c98 = () => interopDefault(import('../pages/betrieb/job/_slug/bearbeiten.vue' /* webpackChunkName: "pages/betrieb/job/_slug/bearbeiten" */))
const _1fe89470 = () => interopDefault(import('../pages/ausbildung/_locationSlug.vue' /* webpackChunkName: "pages/ausbildung/_locationSlug" */))
const _26583a11 = () => interopDefault(import('../pages/auswahl/_jobListSlug.vue' /* webpackChunkName: "pages/auswahl/_jobListSlug" */))
const _88d868e6 = () => interopDefault(import('../pages/beitrag/_postSlug.vue' /* webpackChunkName: "pages/beitrag/_postSlug" */))
const _b79fcbb4 = () => interopDefault(import('../pages/betriebe/_slug/index.vue' /* webpackChunkName: "pages/betriebe/_slug/index" */))
const _3ab326c3 = () => interopDefault(import('../pages/event/_eventSlug.vue' /* webpackChunkName: "pages/event/_eventSlug" */))
const _1446ec33 = () => interopDefault(import('../pages/livestream/_livestream/index.vue' /* webpackChunkName: "pages/livestream/_livestream/index" */))
const _097f250c = () => interopDefault(import('../pages/praktikum/_locationSlug.vue' /* webpackChunkName: "pages/praktikum/_locationSlug" */))
const _17a1753a = () => interopDefault(import('../pages/region/_slug/index.vue' /* webpackChunkName: "pages/region/_slug/index" */))
const _26358848 = () => interopDefault(import('../pages/schule/_slug/index.vue' /* webpackChunkName: "pages/schule/_slug/index" */))
const _01a04e76 = () => interopDefault(import('../pages/unternehmen/_locationSlug.vue' /* webpackChunkName: "pages/unternehmen/_locationSlug" */))
const _277907e2 = () => interopDefault(import('../pages/betriebe/_slug/azubis/index.vue' /* webpackChunkName: "pages/betriebe/_slug/azubis/index" */))
const _68b81d67 = () => interopDefault(import('../pages/betriebe/_slug/bearbeiten.vue' /* webpackChunkName: "pages/betriebe/_slug/bearbeiten" */))
const _d9b1a362 = () => interopDefault(import('../pages/betriebe/_slug/profilbesucher.vue' /* webpackChunkName: "pages/betriebe/_slug/profilbesucher" */))
const _32fde2b3 = () => interopDefault(import('../pages/livestream/_livestream/admin/index.vue' /* webpackChunkName: "pages/livestream/_livestream/admin/index" */))
const _f56fe742 = () => interopDefault(import('../pages/livestream/_livestream/suche.vue' /* webpackChunkName: "pages/livestream/_livestream/suche" */))
const _62655166 = () => interopDefault(import('../pages/region/_slug/anmeldung_creator.vue' /* webpackChunkName: "pages/region/_slug/anmeldung_creator" */))
const _01ce4d46 = () => interopDefault(import('../pages/region/_slug/arbeitgeber/index.vue' /* webpackChunkName: "pages/region/_slug/arbeitgeber/index" */))
const _19e816b8 = () => interopDefault(import('../pages/region/_slug/ausbildung.vue' /* webpackChunkName: "pages/region/_slug/ausbildung" */))
const _cf9a98dc = () => interopDefault(import('../pages/region/_slug/ausbildung_embed.vue' /* webpackChunkName: "pages/region/_slug/ausbildung_embed" */))
const _6dfea1ca = () => interopDefault(import('../pages/region/_slug/news.vue' /* webpackChunkName: "pages/region/_slug/news" */))
const _6625d02c = () => interopDefault(import('../pages/region/_slug/praktikum.vue' /* webpackChunkName: "pages/region/_slug/praktikum" */))
const _8ef87200 = () => interopDefault(import('../pages/region/_slug/schulen.vue' /* webpackChunkName: "pages/region/_slug/schulen" */))
const _3e4e1275 = () => interopDefault(import('../pages/region/_slug/unternehmen.vue' /* webpackChunkName: "pages/region/_slug/unternehmen" */))
const _322ba4e2 = () => interopDefault(import('../pages/region/_slug/unternehmen_embed.vue' /* webpackChunkName: "pages/region/_slug/unternehmen_embed" */))
const _0bdc3ce8 = () => interopDefault(import('../pages/schule/_slug/minibat.vue' /* webpackChunkName: "pages/schule/_slug/minibat" */))
const _21f60faa = () => interopDefault(import('../pages/schule/_slug/schwarzes-brett.vue' /* webpackChunkName: "pages/schule/_slug/schwarzes-brett" */))
const _e9f8c1b2 = () => interopDefault(import('../pages/betriebe/_slug/jobs/uebersicht.vue' /* webpackChunkName: "pages/betriebe/_slug/jobs/uebersicht" */))
const _6f48267e = () => interopDefault(import('../pages/betriebe/_slug/jobs/uebersicht_embed.vue' /* webpackChunkName: "pages/betriebe/_slug/jobs/uebersicht_embed" */))
const _dac526f2 = () => interopDefault(import('../pages/livestream/_livestream/admin/chat.vue' /* webpackChunkName: "pages/livestream/_livestream/admin/chat" */))
const _3e8da808 = () => interopDefault(import('../pages/livestream/_livestream/admin/events.vue' /* webpackChunkName: "pages/livestream/_livestream/admin/events" */))
const _145cf2c4 = () => interopDefault(import('../pages/region/_slug/arbeitgeber/events.vue' /* webpackChunkName: "pages/region/_slug/arbeitgeber/events" */))
const _5cfa93b9 = () => interopDefault(import('../pages/betriebe/_slug/jobs/_jobSlug/index.vue' /* webpackChunkName: "pages/betriebe/_slug/jobs/_jobSlug/index" */))
const _b91e52da = () => interopDefault(import('../pages/betriebe/_slug/jobs/_jobSlug/card_embed.vue' /* webpackChunkName: "pages/betriebe/_slug/jobs/_jobSlug/card_embed" */))
const _6b84dee9 = () => interopDefault(import('../pages/betriebe/_slug/jobs/_jobSlug/praktikum.vue' /* webpackChunkName: "pages/betriebe/_slug/jobs/_jobSlug/praktikum" */))
const _6668db30 = () => interopDefault(import('../pages/betriebe/_slug/_siteslug/index.vue' /* webpackChunkName: "pages/betriebe/_slug/_siteslug/index" */))
const _2f58b88d = () => interopDefault(import('../pages/customdomain/_.vue' /* webpackChunkName: "pages/customdomain/_" */))
const _a3577f8c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b5a01d72 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/betriebe/:businessSlug/jobs/:jobSlug/review",
    component: _ae6c64d8,
    name: "betriebe-businessSlug-jobs-jobSlug-review"
  }, {
    path: "/404",
    component: _35f471a0,
    name: "404"
  }, {
    path: "/abmelden",
    component: _6933b2e2,
    name: "abmelden"
  }, {
    path: "/admin",
    component: _4078d792,
    name: "admin",
    children: [{
      path: "artikel",
      component: _349e5458,
      name: "admin-artikel"
    }, {
      path: "betriebe",
      component: _be0c3530,
      name: "admin-betriebe"
    }, {
      path: "dashboard",
      component: _01ccde3c,
      name: "admin-dashboard"
    }, {
      path: "joblists",
      component: _0cc53f80,
      name: "admin-joblists"
    }, {
      path: "kampagne",
      component: _8e80583e,
      name: "admin-kampagne"
    }, {
      path: "konversationen",
      component: _08dc40ec,
      name: "admin-konversationen"
    }, {
      path: "livestreams",
      component: _5b8b8caf,
      name: "admin-livestreams"
    }, {
      path: "messen",
      component: _671aa4ae,
      name: "admin-messen"
    }, {
      path: "oabalytics",
      component: _0d666fce,
      name: "admin-oabalytics"
    }, {
      path: "regionen",
      component: _6223d4b6,
      name: "admin-regionen"
    }, {
      path: "schulen",
      component: _2dd31740,
      name: "admin-schulen"
    }, {
      path: "uebersicht",
      component: _75dd2a82,
      name: "admin-uebersicht"
    }, {
      path: "umfragen",
      component: _a2aa028a,
      name: "admin-umfragen"
    }, {
      path: "event/erstellen",
      component: _12bdbff3,
      name: "admin-event-erstellen"
    }, {
      path: "kampagne/bewerben/:mediaId?",
      component: _26acf1b4,
      name: "admin-kampagne-bewerben-mediaId"
    }, {
      path: "kampagne/erstellen/:templateId?",
      component: _591b49d4,
      name: "admin-kampagne-erstellen-templateId"
    }, {
      path: "oabalytics/users/:id",
      component: _ab91861c,
      name: "admin-oabalytics-users-id"
    }, {
      path: "oabalytics/users/:id?/detailed",
      component: _5ea7a7c0,
      name: "admin-oabalytics-users-id-detailed"
    }, {
      path: "livestream/:livestream",
      component: _f681221a,
      name: "admin-livestream-livestream"
    }, {
      path: "livestream/:livestream?/chat",
      component: _0f4d4f72,
      name: "admin-livestream-livestream-chat"
    }, {
      path: "livestream/:livestream?/events",
      component: _24fda3c8,
      name: "admin-livestream-livestream-events"
    }, {
      path: "livestream/:livestream?/timer",
      component: _8fe44834,
      name: "admin-livestream-livestream-timer"
    }]
  }, {
    path: "/agb",
    component: _08180ae7,
    name: "agb"
  }, {
    path: "/agb-messe",
    component: _0bfc3878,
    name: "agb-messe"
  }, {
    path: "/app-datenschutz",
    component: _6890345f,
    name: "app-datenschutz"
  }, {
    path: "/betrieb",
    component: _5120ffd0,
    name: "betrieb"
  }, {
    path: "/betriebe",
    component: _8aa329aa,
    name: "betriebe"
  }, {
    path: "/chat",
    component: _7dd1c220,
    name: "chat"
  }, {
    path: "/einstellungen",
    component: _4157f330,
    name: "einstellungen"
  }, {
    path: "/favoriten",
    component: _98df4c8c,
    name: "favoriten"
  }, {
    path: "/idee",
    component: _1f7fe443,
    name: "idee"
  }, {
    path: "/impressum",
    component: _1ff52ebc,
    name: "impressum"
  }, {
    path: "/kontakt",
    component: _5cec67ea,
    name: "kontakt"
  }, {
    path: "/nutzungsbedingungen",
    component: _3b125362,
    name: "nutzungsbedingungen"
  }, {
    path: "/nutzungsbedingungen-fuer-bildungseinrichtungen",
    component: _ad70b570,
    name: "nutzungsbedingungen-fuer-bildungseinrichtungen"
  }, {
    path: "/passwort-zuruecksetzen",
    component: _09ac5014,
    name: "passwort-zuruecksetzen"
  }, {
    path: "/region",
    component: _ee92b582,
    name: "region"
  }, {
    path: "/schule",
    component: _73748d61,
    name: "schule"
  }, {
    path: "/test",
    component: _386b734c,
    name: "test"
  }, {
    path: "/testing",
    component: _527d4678,
    name: "testing"
  }, {
    path: "/verify",
    component: _968e1a7e,
    name: "verify"
  }, {
    path: "/arbeitgeber/einladung",
    component: _4500a454,
    name: "arbeitgeber-einladung"
  }, {
    path: "/arbeitgeber/registrierung",
    component: _3343289f,
    name: "arbeitgeber-registrierung"
  }, {
    path: "/artikel/vorschau",
    component: _1c73395e,
    name: "artikel-vorschau"
  }, {
    path: "/betrieb/abonnements",
    component: _07a8523c,
    name: "betrieb-abonnements"
  }, {
    path: "/betrieb/benutzer",
    component: _63f9beef,
    name: "betrieb-benutzer"
  }, {
    path: "/betrieb/channels",
    component: _0ad4617c,
    name: "betrieb-channels"
  }, {
    path: "/betrieb/einblick",
    component: _0c812f9c,
    name: "betrieb-einblick"
  }, {
    path: "/betrieb/problem",
    component: _7a05299d,
    name: "betrieb-problem"
  }, {
    path: "/betrieb/standorte",
    component: _22c84068,
    name: "betrieb-standorte"
  }, {
    path: "/betrieb/store",
    component: _7d1698bc,
    name: "betrieb-store"
  }, {
    path: "/betriebe/schulnetzwerk",
    component: _168bd18f,
    name: "betriebe-schulnetzwerk"
  }, {
    path: "/customdomain/artikelvorschau",
    component: _1ee2be26,
    name: "customdomain-artikelvorschau"
  }, {
    path: "/embed/businesses",
    component: _30fb4e2c,
    name: "embed-businesses"
  }, {
    path: "/embed/jobs",
    component: _c45fa2d8,
    name: "embed-jobs"
  }, {
    path: "/i/benachrichtigungen",
    component: _7de06d26,
    name: "i-benachrichtigungen"
  }, {
    path: "/i/dokumente",
    component: _813d5f12,
    name: "i-dokumente"
  }, {
    path: "/i/password",
    component: _b48348e8,
    name: "i-password"
  }, {
    path: "/i/profile",
    component: _217ce72e,
    name: "i-profile"
  }, {
    path: "/intern/components",
    component: _0984a51b,
    name: "intern-components"
  }, {
    path: "/intern/css",
    component: _2875c14e,
    name: "intern-css"
  }, {
    path: "/intern/icons",
    component: _76e56e25,
    name: "intern-icons"
  }, {
    path: "/intern/parallax",
    component: _4b58de5c,
    name: "intern-parallax"
  }, {
    path: "/region/altmuehlfranken",
    component: _205a4973,
    name: "region-altmuehlfranken"
  }, {
    path: "/region/betriebe",
    component: _060378fa,
    name: "region-betriebe"
  }, {
    path: "/region/channel",
    component: _34da17b0,
    name: "region-channel"
  }, {
    path: "/region/einstellungen",
    component: _b5e3c0ac,
    name: "region-einstellungen"
  }, {
    path: "/region/freyung-grafenau",
    component: _e11fb15c,
    name: "region-freyung-grafenau"
  }, {
    path: "/region/hauzenberg",
    component: _3df8f9b6,
    name: "region-hauzenberg"
  }, {
    path: "/region/hinzufuegen",
    component: _0833d762,
    name: "region-hinzufuegen"
  }, {
    path: "/region/inn-salzach",
    component: _0185c094,
    name: "region-inn-salzach"
  }, {
    path: "/region/landshut",
    component: _4f05e945,
    name: "region-landshut"
  }, {
    path: "/region/lernen-vor-ort-muhldorf-speeddating",
    component: _bfafe548,
    name: "region-lernen-vor-ort-muhldorf-speeddating"
  }, {
    path: "/region/lkr-r",
    component: _dad88d36,
    name: "region-lkr-r"
  }, {
    path: "/region/passau",
    component: _44aaaf2a,
    name: "region-passau"
  }, {
    path: "/region/plattform",
    component: _8cbafc4e,
    name: "region-plattform"
  }, {
    path: "/region/registrierung",
    component: _c7306d78,
    name: "region-registrierung"
  }, {
    path: "/region/schulen",
    component: _390d09c5,
    name: "region-schulen"
  }, {
    path: "/schule/einstellungen",
    component: _387ac5cc,
    name: "schule-einstellungen"
  }, {
    path: "/schule/firmennetzwerk",
    component: _9d430c28,
    name: "schule-firmennetzwerk"
  }, {
    path: "/schule/nutzerverwaltung",
    component: _5b545e14,
    name: "schule-nutzerverwaltung"
  }, {
    path: "/schule/registrierung",
    component: _114564ba,
    name: "schule-registrierung"
  }, {
    path: "/social/login",
    component: _ccea94e2,
    name: "social-login"
  }, {
    path: "/statistiken/oabat",
    component: _3d62f820,
    name: "statistiken-oabat"
  }, {
    path: "/betrieb/job/erstellen",
    component: _2825a82c,
    name: "betrieb-job-erstellen"
  }, {
    path: "/betrieb/job/vorschau",
    component: _24679f17,
    name: "betrieb-job-vorschau"
  }, {
    path: "/betrieb/store/feature",
    component: _0f7ee5c6,
    name: "betrieb-store-feature",
    children: [{
      path: "api_connection",
      component: _7bc60cbc,
      name: "betrieb-store-feature-api_connection"
    }, {
      path: "mail2chat",
      component: _cc9ed3ac,
      name: "betrieb-store-feature-mail2chat"
    }, {
      path: "premium",
      component: _038601e4,
      name: "betrieb-store-feature-premium"
    }]
  }, {
    path: "/betrieb/store/kasse",
    component: _58384582,
    name: "betrieb-store-kasse"
  }, {
    path: "/customdomain/artikel/erstellen",
    component: _f48a5bea,
    name: "customdomain-artikel-erstellen"
  }, {
    path: "/i/profile/editieren",
    component: _6e5916d4,
    name: "i-profile-editieren"
  }, {
    path: "/region/inn-salzach/anmeldung",
    component: _55316675,
    name: "region-inn-salzach-anmeldung"
  }, {
    path: "/region/landshut/anmeldung",
    component: _cd665ef8,
    name: "region-landshut-anmeldung"
  }, {
    path: "/region/passau/anmeldung",
    component: _88dce6ac,
    name: "region-passau-anmeldung"
  }, {
    path: "/betrieb/einblick/iframe/:id?",
    component: _788aabc2,
    name: "betrieb-einblick-iframe-id"
  }, {
    path: "/customdomain/artikel/:slug?",
    component: _5f4875d6,
    name: "customdomain-artikel-slug"
  }, {
    path: "/schule/suche/:locationSlug?",
    component: _069ededd,
    name: "schule-suche-locationSlug"
  }, {
    path: "/betrieb/job/:slug?/bearbeiten",
    component: _35aa7c98,
    name: "betrieb-job-slug-bearbeiten"
  }, {
    path: "/ausbildung/:locationSlug?",
    component: _1fe89470,
    name: "ausbildung-locationSlug"
  }, {
    path: "/auswahl/:jobListSlug?",
    component: _26583a11,
    name: "auswahl-jobListSlug"
  }, {
    path: "/beitrag/:postSlug?",
    component: _88d868e6,
    name: "beitrag-postSlug"
  }, {
    path: "/betriebe/:slug",
    component: _b79fcbb4,
    name: "betriebe-slug"
  }, {
    path: "/event/:eventSlug?",
    component: _3ab326c3,
    name: "event-eventSlug"
  }, {
    path: "/livestream/:livestream",
    component: _1446ec33,
    name: "livestream-livestream"
  }, {
    path: "/praktikum/:locationSlug?",
    component: _097f250c,
    name: "praktikum-locationSlug"
  }, {
    path: "/region/:slug",
    component: _17a1753a,
    name: "region-slug"
  }, {
    path: "/schule/:slug",
    component: _26358848,
    name: "schule-slug"
  }, {
    path: "/unternehmen/:locationSlug?",
    component: _01a04e76,
    name: "unternehmen-locationSlug"
  }, {
    path: "/betriebe/:slug/azubis",
    component: _277907e2,
    name: "betriebe-slug-azubis"
  }, {
    path: "/betriebe/:slug/bearbeiten",
    component: _68b81d67,
    name: "betriebe-slug-bearbeiten"
  }, {
    path: "/betriebe/:slug/profilbesucher",
    component: _d9b1a362,
    name: "betriebe-slug-profilbesucher"
  }, {
    path: "/livestream/:livestream?/admin",
    component: _32fde2b3,
    name: "livestream-livestream-admin"
  }, {
    path: "/livestream/:livestream?/suche",
    component: _f56fe742,
    name: "livestream-livestream-suche"
  }, {
    path: "/region/:slug/anmeldung_creator",
    component: _62655166,
    name: "region-slug-anmeldung_creator"
  }, {
    path: "/region/:slug/arbeitgeber",
    component: _01ce4d46,
    name: "region-slug-arbeitgeber"
  }, {
    path: "/region/:slug/ausbildung",
    component: _19e816b8,
    name: "region-slug-ausbildung"
  }, {
    path: "/region/:slug/ausbildung_embed",
    component: _cf9a98dc,
    name: "region-slug-ausbildung_embed"
  }, {
    path: "/region/:slug/news",
    component: _6dfea1ca,
    name: "region-slug-news"
  }, {
    path: "/region/:slug/praktikum",
    component: _6625d02c,
    name: "region-slug-praktikum"
  }, {
    path: "/region/:slug/schulen",
    component: _8ef87200,
    name: "region-slug-schulen"
  }, {
    path: "/region/:slug/unternehmen",
    component: _3e4e1275,
    name: "region-slug-unternehmen"
  }, {
    path: "/region/:slug/unternehmen_embed",
    component: _322ba4e2,
    name: "region-slug-unternehmen_embed"
  }, {
    path: "/schule/:slug/minibat",
    component: _0bdc3ce8,
    name: "schule-slug-minibat"
  }, {
    path: "/schule/:slug/schwarzes-brett",
    component: _21f60faa,
    name: "schule-slug-schwarzes-brett"
  }, {
    path: "/betriebe/:slug/jobs/uebersicht",
    component: _e9f8c1b2,
    name: "betriebe-slug-jobs-uebersicht"
  }, {
    path: "/betriebe/:slug/jobs/uebersicht_embed",
    component: _6f48267e,
    name: "betriebe-slug-jobs-uebersicht_embed"
  }, {
    path: "/livestream/:livestream?/admin/chat",
    component: _dac526f2,
    name: "livestream-livestream-admin-chat"
  }, {
    path: "/livestream/:livestream?/admin/events",
    component: _3e8da808,
    name: "livestream-livestream-admin-events"
  }, {
    path: "/region/:slug/arbeitgeber/events",
    component: _145cf2c4,
    name: "region-slug-arbeitgeber-events"
  }, {
    path: "/betriebe/:slug/jobs/:jobSlug",
    component: _5cfa93b9,
    name: "betriebe-slug-jobs-jobSlug"
  }, {
    path: "/betriebe/:slug/jobs/:jobSlug?/card_embed",
    component: _b91e52da,
    name: "betriebe-slug-jobs-jobSlug-card_embed"
  }, {
    path: "/betriebe/:slug/jobs/:jobSlug?/praktikum",
    component: _6b84dee9,
    name: "betriebe-slug-jobs-jobSlug-praktikum"
  }, {
    path: "/betriebe/:slug/:siteslug",
    component: _6668db30,
    name: "betriebe-slug-siteslug"
  }, {
    path: "/customdomain/*",
    component: _2f58b88d,
    name: "customdomain-all"
  }, {
    path: "/",
    component: _a3577f8c,
    name: "index"
  }, {
    path: "/*",
    component: _b5a01d72,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
