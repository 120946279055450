import { JOB_V3 } from "@/enums/permissions"

export default function ({ $auth, redirect, route }) {
    let user = $auth.user
    if (
        !user ||
        !user.business ||
        (!route.params.slugAsJob &&
            route.params.slug &&
            route.params.slug !== user.business.slug)
    ) {
        redirect({ name: "index" })
    }

    // New creator permission check (job creating)
    if (route.name === "betrieb-job-erstellen" && !route.params.trustworthy) {
        if (
            !user.business.permissions.includes(JOB_V3) &&
            route.params.type !== 3
        )
            redirect({
                name: "betriebe-slug-jobs-erstellen",
                params: { slug: user.business.slug },
                query: { type: route.params.type, ...route.query },
            })
    }

    // New creator permission check (job editing)
    if (
        route.name === "betrieb-job-slug-bearbeiten" &&
        !route.params.trustworthy
    ) {
        const jobSlug = route.params.slug
        const job = user.business.jobs.find((job) => job.slug === jobSlug)
        if (job.type_id !== 3 && job.version < 2)
            redirect({
                jobSlug: job.slug,
                slug: user.business.slug,
            })
    }
}
