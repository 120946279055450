import {
    mdiCompassOutline,
    mdiAccountMultiple,
    mdiTabletDashboard,
    mdiShieldCrown,
    mdiSchoolOutline,
} from "@mdi/js"
import {
    home,
    education,
    message,
    job,
    connect,
    icon_channel,
    icon_manage,
    einblickpro,
} from "@/assets/icons/js"
import {
    routeToSchoolDashboard,
    routeToSchool,
    routeToSchoolNetwork,
    routeToSettings,
    routeToRegionDashboard,
    routeToRegionPlattform,
    routeToRegionChannel,
    routeToRegionSchools,
    routeToRegionBusinesses,
    routeToBusinessAdminJobs,
    routeToBusinessNetwork,
    routeToBusinessProfileVisitors,
    routeToOabatDashboard,
    routeToBulletinBoard,
    routeToBusinessLocations,
    routeToBusinessUsers,
    routeToEditProfile,
    routeToBusinessChannels,
    routeToAdminBusinesses,
    routeToEinBlick,
} from "@/helpers/routing"
import { routeToDashboard } from "@/helpers/routing"
import { PROFILE_COMPLETED, JOBS_COMPLETED } from "@/enums"

export const state = () => ({
    sidebar: null,
    userType: "",
})
export const mutations = {
    UPDATE_SIDEBAR(state, payload) {
        state.sidebar = payload
    },
    SET_USERTYPE(state, type) {
        state.userType = type
    },
}
export const getters = {
    /*
    icon: String, [icon file name from '/assets/icons/v2.1']
    name: String,
    badge?: Number,
    link?: String | Object | Function(): String | Object [function is run inside the Sidebar.vue component with 'this' context]
    handler?: Function(): void, [function is run inside the Sidebar.vue component with 'this' context]
    */
    menuItems: (state, getters, rootState, rootGetters) => {
        return navigations[state.userType + "MenuItems"](
            state,
            getters,
            rootState,
            rootGetters
        )
    },
    actions: (state, getters, rootState, rootGetters) =>
        navigations[state.userType + "Actions"](
            state,
            getters,
            rootState,
            rootGetters
        ),
}
export const actions = {
    updateSidebar({ commit }, payload) {
        commit("UPDATE_SIDEBAR", payload)
    },
    updateUserType({ commit, rootState }, user = null) {
        let type = ""
        if (user || rootState.auth?.user) {
            type =
                user?.business ?? rootState.auth.user.business
                    ? "business"
                    : type
            type = user?.school ?? rootState.auth.user.school ? "school" : type
            type = user?.region ?? rootState.auth.user.region ? "region" : type
        }
        commit("SET_USERTYPE", type)
    },
}

// eslint-disable-next-line no-unused-vars
const navigations = {
    MenuItems: () => [],
    Actions: () => [],
    schoolMenuItems: (_1, _2, _3, rootGetters) => [
        {
            icon: home,
            name: "Startseite",
            link: function () {
                return routeToSchoolDashboard()
            },
            exact: true,
        },
        {
            icon: education,
            name: "Schulprofil",
            link: function () {
                return routeToSchool(rootGetters["school/displayedSchool"].slug)
            },
            exact: true,
        },
        {
            icon: message,
            name: "Nachrichten",
            badge: rootGetters["chat/getTotalUnreadMessages"],
            link: "/chat",
        },
        {
            icon: job,
            name: "Schwarzes Brett",
            link: function () {
                return routeToBulletinBoard(
                    rootGetters["school/displayedSchool"].slug
                )
            },
        },
        {
            icon: connect,
            name: "Firmennetzwerk",
            link: function () {
                return routeToSchoolNetwork()
            },
            exact: true,
        },
    ],
    schoolActions: () => [
        {
            name: "Einstellungen",
            link: function () {
                return routeToSettings(this.$auth.user)
            },
        },
        {
            name: "Logout",
            handler: function () {
                this.$store.dispatch("account/logout")
            },
        },
    ],
    regionMenuItems: (_1, _2, rootState, rootGetters) => [
        {
            icon: home,
            name: "Startseite",
            link: function () {
                return routeToRegionDashboard()
            },
            exact: true,
        },
        {
            icon: message,
            name: "Nachrichten",
            badge: rootGetters["chat/getTotalUnreadMessages"],
            link: "/chat",
        },
        {
            icon: icon_manage,
            name: "Mitgliederverwaltung",
            nameShort: "Mitglieder",
            link: function () {
                return routeToRegionBusinesses(rootState.auth.user?.region)
            },
            exact: true,
        },

        {
            icon: icon_channel,
            name: "Mein Channel",
            nameShort: "Channel",
            link: function () {
                return routeToRegionChannel()
            },
            exact: true,
        },
        {
            icon: mdiCompassOutline,
            name: "Channelinhalte",
            nameShort: "Inhalte",
            link: function () {
                return routeToRegionPlattform()
            },
            exact: true,
        },
        {
            icon: mdiSchoolOutline,
            name: "Schulverwaltung",
            nameShort: "Schulen",
            link: function () {
                return routeToRegionSchools(rootState.auth.user?.region)
            },
            exact: true,
        },
    ],
    regionActions: () => [
        {
            name: "Einstellungen",
            link: function () {
                return routeToSettings(this.$auth.user)
            },
        },
        {
            name: "Logout",
            handler: function () {
                this.$store.dispatch("account/logout")
            },
        },
    ],
    businessMenuItems: (_1, _2, rootState, rootGetters) => {
        const tutorial = rootState.auth.user.tutorial_steps ?? []
        const items = [
            {
                icon: require("@/assets/icons/v2.1/icon_home.svg"),
                name: "Startseite",
                link: function () {
                    return routeToDashboard(rootState.auth.user)
                },
                exact: true,
            },
            {
                icon: require("@/assets/icons/v2.1/icon_message.svg"),
                name: "Nachrichten",
                badge: rootGetters["chat/getTotalUnreadMessages"],
                link: "/chat",
            },
            {
                icon: require("@/assets/icons/v2.1/icon_pen_white.svg"),
                name: "Profil bearbeiten",
                link: function () {
                    return routeToEditProfile(rootState.auth.user)
                },
            },
            {
                icon: require("@/assets/icons/v2.1/icon_job.svg"),
                name: "Unsere Berufe",
                link: function () {
                    return routeToBusinessAdminJobs(rootState.auth.user)
                },
            },

            // TODO: german version of the "Channels" ??
            {
                icon: icon_channel,
                name: "Channels",
                link: function () {
                    return routeToBusinessChannels(rootState.auth.user)
                },
            },
        ]
        if (tutorial.includes(PROFILE_COMPLETED)) {
            items.push({
                icon: require("@/assets/icons/v2.1/icon_school.svg"),
                name: "Schulnetzwerk",
                link: function () {
                    if (this.$can.business()) {
                        return routeToBusinessNetwork()
                    }
                    return "/"
                },
            })
        }
        if (rootState.auth.user.business?.is_job_fair_participant) {
            items.push({
                icon: mdiAccountMultiple,
                name: "Profilbesucher",
                link: function () {
                    return routeToBusinessProfileVisitors(
                        rootState.auth.user?.business
                    )
                },
            })
        }
        if (rootState.auth.user.business?.has_pro_portal) {
            items.push({
                icon: einblickpro,
                name: "EinBlick.pro",
                link: function () {
                    return routeToEinBlick()
                },
            })
        }

        /** Identical to $can.admin() */
        if (rootState.auth.user.email === "admin@oabat.de")
            items.push(
                {
                    icon: mdiTabletDashboard,
                    name: "Dashboard",
                    link: function () {
                        return routeToOabatDashboard()
                    },
                },
                {
                    icon: mdiShieldCrown,
                    name: "Admin",
                    link: function () {
                        return routeToAdminBusinesses()
                    },
                }
            )
        return items
    },
    businessActions: (_1, _2, rootState) => {
        const tutorial = rootState.auth.user.tutorial_steps ?? []

        const items = [
            {
                name: "Einstellungen",
                link: function () {
                    return routeToSettings(rootState.auth.user)
                },
            },
            {
                name: "Logout",
                handler: function () {
                    this.$store.dispatch("account/logout")
                },
            },
        ]

        if (tutorial.includes(JOBS_COMPLETED)) {
            items.unshift(
                {
                    name: "Standorte",
                    link: routeToBusinessLocations,
                },
                {
                    name: "Benutzerverwaltung",
                    link: routeToBusinessUsers,
                }
            )
        }

        return items
    },
}
