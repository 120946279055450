import Vue from "vue"

Vue.config.optionMergeStrategies.fetch = function (toVal, fromVal) {
    return function mergedFetchMethod() {
        if (fromVal) {
            fromVal.call(this)
        }
        if (toVal) {
            toVal.call(this)
        }
    }
}
