export default function ({ store, route, redirect }) {
    if (process.server && Object.keys(route.query).length > 0) {
        if (route.query.allowlogin === "true") {
            return redirect(
                store.state.auth.loggedIn
                    ? route.path
                    : `/?login=true&to=${route.path}`
            )
        }
        if (route.query.login === "true") {
            if (!store.state.auth.loggedIn) {
                store.dispatch("account/showAuth")
            }
        }
        if (store.state.auth.loggedIn && route.query.to) {
            redirect(route.query.to)
        }
    }
}
