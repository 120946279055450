import Vue from "vue"
// import FullscreenMedia from "./components/FullscreenMedia.vue" not working, so:
import FullscreenMediaComponent from "@/modules/fullscreenMedia/components/FullscreenMedia.vue"

const IS_DISABLED = false

    let fullscreenMedia = null

export default ({ app }, inject) => {
    function open(media, options) {
        if (IS_DISABLED) return
        fullscreenMedia?.open(media, options)
    }

    function close() {
        if (IS_DISABLED) return
        fullscreenMedia?.close()
    }

    inject("fullscreenMedia", {
        open,
        close,
    })
    if (IS_DISABLED) return
    app.mixins = app.mixins || []
    app.mixins.push({
        async mounted() {
            const FullscreenMedia = Vue.component(
                "FullscreenMedia",
                FullscreenMediaComponent
            )
            const FullscreenMediaCreated = new FullscreenMedia({ parent: this })
            let element = document.getElementById("__nuxt")
            let elementNode = document.createElement("div")
            elementNode.setAttribute("id", "fullscreenMedia")
            element.appendChild(elementNode)
            FullscreenMediaCreated.$mount("#fullscreenMedia")
            fullscreenMedia = FullscreenMediaCreated
        },
    })
}
