function getAddressForCoordinates(latitude, longitude) {
    return this.$axios
        .$get("/api/location/address_for_location", {
            params: {
                latitude,
                longitude,
            },
        })
        .then(({ data }) => data)
}

const locationService = {
    getAddressForCoordinates,
}

export default locationService
