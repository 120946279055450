function getBusinessesPaginated(
    searchTerm,
    channelSlug,
    subscriptionType,
    page = 1
) {
    if (searchTerm === "") {
        searchTerm = undefined
    }
    return this.$axios.$get("/api/v2/admin/business", {
        params: {
            search: searchTerm,
            channel: channelSlug,
            subscription_type: subscriptionType,
            page,
        },
    })
}

function getChannels(params = {}) {
    return this.$axios
        .$get("/api/channels", { params })
        .then(({ data }) => data)
}

function getSubscriptionTypes() {
    return this.$axios.$get("/api/subscription_types").then(({ data }) => data)
}

function getDummySubscription(productId, businessSlug) {
    return this.$axios
        .$get(`/api/v2/admin/subscriptions/${productId}/${businessSlug}/dummy`)
        .then(({ data }) => data)
}

function getInvoices(params) {
    return this.$axios
        .$get("/api/crm/invoices/", { params })
        .then(({ data }) => data)
}

function addSubscriptionType(slug, subscriptionId) {
    return this.$axios
        .$post(`/api/business/${slug}/subscription/${subscriptionId}`)
        .then((r) => r.data)
}
function toggleBusinessSiteChannelVisibility(
    businessSiteSlug,
    isActive,
    channelSlug
) {
    return this.$axios
        .$post(
            "/api/business_site/" +
                businessSiteSlug +
                "/channel/" +
                channelSlug,
            {
                is_active: isActive,
            }
        )
        .then((r) => r?.data)
}

function removeBusinessSubscriptionType(businessSlug, subscriptionId) {
    return this.$axios
        .$delete(`/api/business/${businessSlug}/subscription/${subscriptionId}`)
        .then((r) => r.data)
}

function addSchoolRegion(schoolSlug, regionSlug) {
    return this.$axios
        .$post(`/api/v2/school/${schoolSlug}/region/${regionSlug}`)
        .then((r) => r.data)
}

function removeSchoolRegion(schoolSlug, regionSlug) {
    return this.$axios
        .$delete(`/api/v2/school/${schoolSlug}/region/${regionSlug}`)
        .then((r) => r.data)
}

function getDashboardStatisticData(params) {
    return this.$axios
        .get("/api/statistics", {
            params,
        })
        .then((r) => r.data)
}

function clearSearchCache() {
    return this.$axios
        .$post("/api/admin/cache/clear", {
            tags: ["search"],
        })
        .then((r) => r.data)
}

const adminService = {
    getBusinessesPaginated,
    getChannels,
    getSubscriptionTypes,
    getDummySubscription,
    getInvoices,
    addSubscriptionType,
    toggleBusinessSiteChannelVisibility,
    removeBusinessSubscriptionType,
    addSchoolRegion,
    removeSchoolRegion,
    getDashboardStatisticData,
    clearSearchCache,
}
export default adminService
