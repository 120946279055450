export const STATUS_REJECTED = "rejected"
export const STATUS_APPROVED = "approved"
export const STATUS_PENDING = "pending"

export function updateReview(id, status, comment) {
    return this.put(`/api/v2/job_reviews/${id}`, {
        status,
        comment,
    })
}

export function getReview(id) {
    return this.get(`/api/v2/job_reviews/${id}`)
}
