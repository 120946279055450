import {
    COOKIE_LIFETIME_MEDIUM,
    COOKIE_PREVIOUS_SEARCH_ROUTE,
} from "@/enums/cookies"

export const state = () => ({
    previousRoute: null,
    previousSearchRoute: null,
    history: [],
    skipNextRoute: false,
})

export const getters = {
    previousRoute: (state) => state.previousRoute,
    previousSearchRoute: (state) => state.previousSearchRoute,
}

export const actions = {
    async addPreviousRoute({ state, commit, dispatch }, { from, to }) {
        if (state.skipNextRoute) {
            commit("SKIP_NEXT_ROUTE", false)
            return
        }
        if (
            state.from == null ||
            (from.path && from.name && state.from.path !== from.path)
        ) {
            let params = from?.params ? { ...from.params } : {}
            let query = from?.query ? { ...from.query } : {}
            let route = {
                name: from?.name,
                path: from?.path,
                params: params,
                query: query,
            }
            if (
                route.name &&
                route.name.includes("locationSlug") &&
                !route.name.includes("schule")
            ) {
                dispatch("addPreviousSearchRoute", route)
            }
            if (from?.path === to.path) {
                if (state.previousRoute) {
                    commit("RESET_PREVIOUS_ROUTE", route)
                }
            } else {
                commit("SET_PREVIOUS_ROUTE", route)
            }
        }
    },
    async addPreviousSearchRoute({ commit }, route) {
        commit("SET_PREVIOUS_SEARCH_ROUTE", route)
        if (process.browser) {
            try {
                this.$cookies.set(COOKIE_PREVIOUS_SEARCH_ROUTE, route.path, {
                    maxAge: COOKIE_LIFETIME_MEDIUM,
                })
            } catch (exception) {
                console.warn("Routing: Could not store previousSearchRoute")
            }
        }
    },
    clearRoute({ commit }) {
        commit("CLEAR_ROUTE")
    },
    skipNextRoute({ commit }) {
        commit("SKIP_NEXT_ROUTE", true)
    },
    back({ state, commit, dispatch }) {
        if (state.previousRoute) {
            commit("BACK")
            dispatch("skipNextRoute")
            this.$router.back()
            return true
        }
        return false
    },
}

export const mutations = {
    RESET_PREVIOUS_ROUTE(state, previousRoute) {
        state.previousRoute = previousRoute
        state.history.pop()
        state.history.push(previousRoute)
    },
    SET_PREVIOUS_ROUTE(state, previousRoute) {
        state.previousRoute = previousRoute
        state.history.push(previousRoute)
        if (state.history.length > 20) {
            state.history.shift()
        }
    },
    SET_PREVIOUS_SEARCH_ROUTE(state, previousSearchRoute) {
        state.previousSearchRoute = previousSearchRoute
    },
    CLEAR_ROUTE(state) {
        state.previousRoute = null
        state.history = []
    },
    SKIP_NEXT_ROUTE(state, value) {
        state.skipNextRoute = value
    },
    BACK(state) {
        state.history.pop()
        state.previousRoute = state.history[state.history.length - 1]
    },
}
