function getTutorial(userId) {
    return this.$axios
        .$get(`/api/user/${userId}/tutorials`)
        .then(({ data }) => data)
}

function storeTutorial(userId, tutorialStep) {
    return this.$axios
        .$post(`/api/user/${userId}/tutorials`, {
            tutorial_step: tutorialStep,
        })
        .then(({ data }) => data)
}

function deleteTutorial(userId, tutorialStep) {
    return this.$axios
        .$delete(`/api/user/${userId}/tutorials`, {
            params: {
                tutorial_step: tutorialStep,
            },
        })
        .then(({ data }) => data)
}

export default {
    storeTutorial,
    deleteTutorial,
    getTutorial,
}
