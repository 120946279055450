/** slugParam defines scope of posts to get: [region OR business]: slug */
function getPosts(slugParam) {
    return this.$axios
        .$get("/api/posts", {
            params: slugParam,
        })
        .then((response) => response.data)
}

function getPost(slug) {
    return this.$axios
        .$get("/api/posts/" + slug)
        .then((response) => response.data)
}

function editPost(post) {
    return this.$axios
        .$put("/api/posts/" + post.slug, { post })
        .then((response) => response.data)
}

function createPost(post) {
    return this.$axios
        .$post("/api/posts", { post })
        .then((response) => response.data)
}

function deletePost(post) {
    return this.$axios.$delete("/api/posts/" + post.slug)
}

const postService = {
    getPosts,
    getPost,
    editPost,
    createPost,
    deletePost,
}

export default postService
