
import Button from "@/components/base/Button"
import { mapGetters } from "vuex"
import { mdiClose } from "@mdi/js"
export default {
    name: "ErrorSnackbar",
    components: { Button },
    data() {
        return {
            mdiClose: mdiClose,
        }
    },
    computed: {
        ...mapGetters("ui", ["snackbarErrorMessage", "snackbarErrorActive"]),
    },
    methods: {
        onClose() {
            this.$store.dispatch("ui/clearGlobalSnackbarError")
        },
    },
}
