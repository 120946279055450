import livestreamService from "@/services/livestream.service"
import md5 from "js-md5"
import {
    EVENT_LIVESTREAM_ALL_USER_MESSAGES_DELETED,
    EVENT_LIVESTREAM_LIVESTREAM_POLL_UPDATED,
    EVENT_LIVESTREAM_MESSAGE_DELETED,
    EVENT_LIVESTREAM_NEW_LIVESTREAM_EVENT,
    EVENT_LIVESTREAM_NEW_MESSAGE,
    EVENT_LIVESTREAM_UPDATED,
    EVENT_LIVESTREAM_VIEWER_COUNT_CHANGED,
} from "@/enums/events"

function generateHashedID(nickname) {
    const dateString = new Date().toISOString()
    return md5(`${nickname}${dateString}`)
}

export const state = () => ({
    activeSocket: false,
    inWindow: false,
    id: null,
    isLive: false,
    isSecure: false,
    verifyPassword: null,
    videoUrl: null,
    thumbnailUrl: null,
    viewers: 0,
    lowerBound: 0,
    planned_start: null,
    planned_end: null,
    messages: [],
    title: "",
    description: "",
    slots: [],
    nickname: "",
    messageThreshold: 0 /** in seconds */,
    messagesInThreshold: {} /** storage for early message delete */,
    selfMessageThreshold: 0 /** in seconds */,
    volume: 0.5,
    muted: true,
    userID: "",
    updateTimer: null,
    mentionableCompanies: [],
    currentBusiness: null,
    nextBusiness: null,
    chatIsPublic: true,
    shouldLoginToSendMessages: false,
    shouldLoginToVote: false,
    emojis: [],
    agenda: [],
    polls: [],
    appearances: [],
    likeCount: 0,
})

export const getters = {
    chatIsPublic: (state) => state.chatIsPublic,
    getCompaniesForMentioning: (state) => {
        return state.mentionableCompanies
    },
    getCompanyFromSlug: (_, getters) => (slug) => {
        const company = getters.getCompaniesForMentioning.find(
            (company) => company.value === slug
        )
        if (!company) return { value: slug, text: slug }
        return company
    },
    currentBusiness: (state) => state.currentBusiness,
    nextBusiness: (state) => state.nextBusiness,
    isActive: (state) => state.activeSocket,
    id: (state) => state.id,
    messageThreshold: (state) => state.messageThreshold,
    selfMessageThreshold: (state) => state.selfMessageThreshold,
    shouldLoginToSendMessages: (state) => state.shouldLoginToSendMessages,
    shouldLoginToVote: (state) => state.shouldLoginToVote,
    nickname: (state) => state.nickname,
    start: (state) => state.planned_start,
    videoUrl: (state) => (state.isLive ? state.videoUrl : ""),
    thumbnailUrl: (state) => state.thumbnailUrl,
    end: (state) => state.planned_end,
    isLive: (state) => state.isLive,
    viewers: (state) => state.viewers,
    lowerBound: (state) => state.lowerBound,
    inWindow: (state) => state.inWindow && state.isLive,
    volume: (state) => state.volume,
    muted: (state) => state.muted,
    emojis: (state) => state.emojis,
    agenda: (state) => state.agenda,
    isSecure: (state) => state.isSecure,
    verifyPassword: (state) => state.verifyPassword,
    description: (state) => state.description,
    title: (state) => state.title,
    likeCount: (state) => state.likeCount,
    slots: (state) => {
        return [...state.slots].sort((a, b) => a.from - b.from)
    },
    activeSlot: (state) => {
        let active = state.slots.filter(
            (slot) => slot.from <= new Date() && slot.to > new Date()
        )
        if (active) {
            return active[0]
        }
        return {}
    },
    messages: (state) => {
        return [...state.messages].sort((a, b) =>
            a.id < b.id ? -1 : b.id < a.id ? 1 : 0
        )
    },
    polls: (state) => state.polls,
    appearances: (state) => state.appearances,
    hasAppearances: ({ appearances }) =>
        Array.isArray(appearances) && appearances.length > 0,
    hasPolls: ({ polls }) => Array.isArray(polls) && polls.length > 0,
    hasAgenda: ({ agenda }) => Array.isArray(agenda) && agenda.length > 0,
}

export const actions = {
    async terminate({ commit }) {
        commit("CLEAR")
        commit("STOP_UPDATE_TIMER")
        this.$echo.leave("livestream")
        console.info("Livestream: Socket disconnected")
    },
    async initialize({ state, commit, dispatch }, id) {
        if (!state.nickname) {
            if (process.client) {
                let nickname = localStorage.getItem("livestream-nickname")
                if (nickname) {
                    commit("SET_NICKNAME", nickname)
                    let userID = localStorage.getItem("livestream-user-id")
                    if (userID) {
                        commit("SET_USER_ID", userID)
                    } else {
                        const hashedID = generateHashedID(nickname)
                        commit("SET_USER_ID", hashedID)
                    }
                }
            }
        }
        if (state.id !== id) {
            commit("CLEAR")
            if (state.emojis.length == 0) {
                dispatch("fetchEmojis")
            }
            let response = await this.$axios.$get("api/livestreams/" + id)
            let slots = []
            let companies = []
            for (let i = 0; i < response.data.appearances.length; i++) {
                let slot = {
                    from: new Date(response.data.appearances[i].from),
                    to: new Date(response.data.appearances[i].to),
                    companies: [response.data.appearances[i].business],
                }
                slots.push(slot)
                for (let company of slot.companies) {
                    companies.push({
                        text: company.name,
                        value: company.slug,
                    })
                }
            }
            companies.push({
                text: "Oabat",
                value: "oabat",
            })

            const livestream = response.data
            livestream.agenda.sort((a1, a2) => {
                return new Date(a1.from) - new Date(a2.from)
            })
            commit("INITIALIZE", {
                id: id,
                livestream: livestream,
                slots: slots,
                verifyPassword: await dispatch("getPasswordLocalStorage", id),
                mentionableCompanies: companies,
            })
            commit("CALCULATE_CURRENT_BUSINESS")
            dispatch("initializeUpdateTimer")
            dispatch("connectToLivestreamChannel")
            try {
                Notification.requestPermission() /** TODO: New Streamslot notifications */
            } catch (e) {
                console.info(
                    "Livestream: Could not request Notification permission"
                )
            }
        }
    },
    async fetchEmojis({ commit }) {
        let response = await this.$axios
            .get("api/livestream/emojis")
            .catch((err) => {
                console.error(err)
            })
        commit("SET_EMOJIS", response.data.data)
    },
    initializeUpdateTimer({ state, commit }) {
        if (state.updateTimer) {
            clearInterval(state.updateTimer)
        }
        let newTimer = setInterval(() => {
            commit("CALCULATE_CURRENT_BUSINESS")
        }, 1000 * 15) // Update every 15 seconds
        commit("SET_UPDATE_TIMER", newTimer)
    },
    async setNickname({ commit }, name) {
        commit("SET_NICKNAME", name)
        localStorage.setItem("livestream-nickname", name)
        const hashedID = generateHashedID(name)
        localStorage.setItem("livestream-user-id", hashedID)
        commit("SET_USER_ID", hashedID)
    },
    async connectToLivestreamChannel({ state, commit, dispatch }) {
        if (!state.id) {
            return false
        }
        if (!state.activeSocket) {
            console.info(
                "Livestream: Connecting to Chat Socket.0 ID: " + state.id
            )
            this.$echo
                .private("livestream." + state.id)
                .listen(EVENT_LIVESTREAM_NEW_MESSAGE, (payload) => {
                    dispatch("receivedMessage", payload.message)
                })
                .listen(EVENT_LIVESTREAM_UPDATED, (payload) => {
                    commit("UPDATE_LIVESTREAM", payload.livestream)
                })
                .listen(EVENT_LIVESTREAM_VIEWER_COUNT_CHANGED, (payload) => {
                    commit("SET_VIEWERS", payload.viewers)
                    commit("SET_LOWER_BOUND", payload.lower_bound)
                })
                .listen(EVENT_LIVESTREAM_MESSAGE_DELETED, (payload) => {
                    commit("DELETE_MESSAGE", { id: payload.message_id })
                })
                .listen(
                    EVENT_LIVESTREAM_ALL_USER_MESSAGES_DELETED,
                    (payload) => {
                        commit("DELETE_ALL_USER_MESSAGES", payload.user_id)
                    }
                )
                .listen(
                    EVENT_LIVESTREAM_LIVESTREAM_POLL_UPDATED,
                    ({ livestreamPoll }) => {
                        commit("UPDATE_POLL", livestreamPoll)
                    }
                )
                .listen(EVENT_LIVESTREAM_NEW_LIVESTREAM_EVENT, () => {
                    commit("ADD_LIKE_COUNT")
                })
            commit("ACTIVE_SOCKET", true)
            return true
        }
        return false
    },
    async sendMessage({ state, commit }, { text }) {
        await this.$axios
            .$post(`/api/livestreams/${state.id}/messages`, {
                text,
                nickname: state.nickname,
                chat_user_id: state.userID,
            })
            .then((res) => {
                commit("ADD_MESSAGE", res.message)
                return true
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    commit("SET_SHOULD_LOGIN_TO_SEND_MESSAGE", true)
                } else {
                    throw new Error(error)
                }
            })
    },
    async sendEvent({ state }, { type, id = state.id }) {
        return await this.$axios
            .$post(
                `/api/livestreams/${id}/events`,
                {
                    type: type,
                    chat_user_id: state.userID,
                },
                { progress: false }
            )
            .catch((e) => console.warn(e))
    },
    async deleteMessage({ commit }, message) {
        await this.$axios.delete(
            `/api/livestreams/${message.livestream_id}/messages/${message.id}`
        )
        commit("DELETE_MESSAGE", message)
        commit("DELETE_MESSAGE_IN_THRESHOLD", message)
    },
    async deleteAllMessagesFromUser(_, message) {
        const userId = message.user_id || message.chat_user_id
        await this.$axios.delete(
            `/api/livestreams/${message.livestream_id}/messages/user/${userId}`
        )
    },
    async blockUser({ dispatch }, message) {
        let requestData = {}
        if (message.user_id) {
            requestData.user_id = message.user_id
        }
        if (message.chat_user_id) {
            requestData.chat_user_id = message.chat_user_id
        }
        await this.$axios.post(
            `/api/livestreams/${message.livestream_id}/blocks/`,
            requestData
        )
        dispatch("deleteAllMessagesFromUser", message)
    },
    async receivedMessage({ state, commit }, message) {
        if (
            message.noThreshold ||
            state.messageThreshold === 0 ||
            this.$can.admin()
        ) {
            commit("ADD_MESSAGE", message)
        } else {
            setTimeout(() => {
                commit("ADD_MESSAGE", message)
                commit("DELETE_MESSAGE_IN_THRESHOLD", message)
            }, state.messageThreshold * 1000)
        }
    },
    async closeWindow({ commit }, value) {
        if (!this.$ua.isFromIos() || value) {
            commit("SET_IN_WINDOW", value)
        }
    },
    async sendLivestreamUpdateRequest({ commit, state }, data) {
        await this.$axios
            .$put(`/api/livestreams/${state.id}`, data)
            .then((res) => commit("UPDATE_LIVESTREAM", res.livestream))
    },
    setVolume({ commit }, value) {
        commit("SET_VOLUME", value)
    },
    async verifyLogin({ state, commit, dispatch }, password) {
        return await livestreamService
            .verifyLogin(state.id, password)
            .then(() => {
                dispatch("setPasswordLocalStorage", password)
                commit("SET_VERIFY_PASSWORD", password)
                return true
            })
            .catch((e) => {
                console.error(e)
                return false
            })
    },
    setPasswordLocalStorage({ state }, password) {
        let values = JSON.parse(localStorage.getItem("livestream_login")) ?? {}
        values["id_" + state.id] = password
        localStorage.setItem("livestream_login", JSON.stringify(values))
    },
    getPasswordLocalStorage({ state }, id = state.id) {
        const values = JSON.parse(localStorage.getItem("livestream_login"))
        if (!values) return ""
        return values["id_" + id]
    },
    removePasswordLocalStorage({ state, commit }, id = state.id) {
        const values = JSON.parse(localStorage.getItem("livestream_login"))
        if (!values) return
        values["id_" + id] = undefined
        commit("SET_VERIFY_PASSWORD", "")
        localStorage.setItem("livestream_login", JSON.stringify(values))
    },
}

export const mutations = {
    SET_SHOULD_LOGIN_TO_SEND_MESSAGE: (state, value) =>
        (state.shouldLoginToSendMessages = value),
    SET_SHOULD_LOGIN_TO_VOTE: (state, value) =>
        (state.shouldLoginToVote = value),
    SET_VIEWERS: (state, value) => (state.viewers = value),
    SET_LOWER_BOUND: (state, value) => (state.lowerBound = value),
    SET_EMOJIS: (state, value) => (state.emojis = Object.freeze(value)),
    SET_NICKNAME: (state, value) => (state.nickname = value),
    SET_IN_WINDOW: (state, value) => (state.inWindow = value),
    ACTIVE_SOCKET: (state, value) => (state.activeSocket = value),
    SET_VOLUME: (state, value) => (state.volume = value),
    SET_MUTED: (state, value) => (state.muted = value),
    SET_USER_ID: (state, value) => (state.userID = value),
    SET_UPDATE_TIMER: (state, timer) => (state.updateTimer = timer),
    SET_VERIFY_PASSWORD: (state, password) => (state.verifyPassword = password),
    ADD_MESSAGE_TO_THRESHOLD: (state, id, timeout) =>
        (state.messagesInThreshold[id] = timeout),
    STOP_UPDATE_TIMER: (state) => {
        if (state.updateTimer) clearInterval(state.updateTimer)
    },
    DELETE_ALL_USER_MESSAGES(state, userID) {
        // since userID can be both string or int, use weak comparison != here
        state.messages = state.messages.filter(
            (message) =>
                message.user_id != userID && message.chat_user_id != userID
        )
    },
    CALCULATE_CURRENT_BUSINESS(state) {
        if (!state.slots) {
            state.currentBusiness = null
            state.nextBusiness = null
            return
        }

        const slots = [...state.slots].sort((a, b) => {
            return new Date(a.from) - new Date(b.from)
        })
        const prevAppearances = slots.filter((appearance) => {
            return (
                new Date(appearance.from) <
                new Date(new Date().getTime() + 60000) // 60s offset - shows business 1 minute before it starts
            )
        })
        if (prevAppearances.length > 0) {
            state.currentBusiness =
                prevAppearances[prevAppearances.length - 1].companies[0]
        }
        const nextAppearances = slots.filter((appearance) => {
            return (
                new Date(appearance.from) >
                new Date(new Date().getTime() + 60000) // 60s offset - shows business 1 minute before it starts
            )
        })
        if (nextAppearances.length > 0) {
            state.nextBusiness = nextAppearances[0].companies[0]
        }
        if (state.currentBusiness) {
            let mentionableCompanies = state.mentionableCompanies.filter(
                (company) => company.value !== state.currentBusiness.slug
            )
            mentionableCompanies.unshift({
                text: state.currentBusiness.name,
                value: state.currentBusiness.slug,
            })
            state.mentionableCompanies = mentionableCompanies
        }
    },
    ADD_LIKE_COUNT(state) {
        state.likeCount++
    },
    ADD_MESSAGE(state, message) {
        if (state.messages.every((el) => el.id !== message.id)) {
            if (state.messages.length >= 50) {
                state.messages.shift()
            }
            state.messages.push(message)
        }
    },
    DELETE_MESSAGE(state, message) {
        state.messages = state.messages.filter((msg) => msg.id !== message.id)
    },
    DELETE_MESSAGE_IN_THRESHOLD(state, message) {
        let msg = state.messagesInThreshold[message.id]
        if (msg) {
            clearTimeout(msg)
        }
        delete state.messagesInThreshold[message.id]
    },
    UPDATE_POLL(state, poll) {
        if (!poll || (!poll.id && poll.id !== 0)) return
        const index = state.polls.findIndex(({ id }) => id === poll.id)
        if (index === -1) state.polls.push(poll)
        else state.polls.splice(index, 1, poll)
        state.polls.sort((a, b) => b.is_active - a.is_active)
    },
    CLEAR(state) {
        state.id = null
        state.slots = []
        state.message = []
        state.planned_start = null
        state.planned_end = null
        state.viewers = 0
        state.isLive = false
        state.isSecure = false
        state.verifyPassword = null
        state.description = ""
        state.title = ""
    },
    UPDATE_LIVESTREAM(state, livestream) {
        state.isLive = livestream.is_live
        state.isSecure = !!livestream.is_secure
        state.shouldLoginToVote = !!livestream.account_only_vote
        state.planned_end = new Date(livestream.planned_end)
        state.planned_start = new Date(livestream.planned_start)
        state.thumbnailUrl = livestream.thumbnail_url
        state.description = livestream.description
        state.title = livestream.title
        state.videoUrl = livestream.video_url
        state.viewers = livestream.viewers
        state.messageThreshold = livestream.message_threshold
        state.selfMessageThreshold = livestream.self_message_threshold
        state.chatIsPublic = livestream.is_chat_public
        state.polls = livestream.polls.sort((a, b) => b.is_active - a.is_active)
        state.appearances = livestream.appearances
    },
    INITIALIZE(
        state,
        { id, livestream, slots, verifyPassword, mentionableCompanies }
    ) {
        state.id = id
        state.isLive = livestream.is_live
        state.isSecure = !!livestream.is_secure
        state.shouldLoginToVote = !!livestream.account_only_vote
        state.viewers = livestream.viewers
        state.planned_start = new Date(livestream.planned_start)
        state.planned_end = new Date(livestream.planned_end)
        state.slots = slots
        state.videoUrl = livestream.video_url
        state.thumbnailUrl = livestream.thumbnail_url
        state.messages = []
        state.messageThreshold = livestream.message_threshold
        state.chatIsPublic = livestream.is_chat_public
        state.mentionableCompanies = mentionableCompanies
        state.agenda = livestream.agenda
        state.description = livestream.description
        state.title = livestream.title
        state.polls = livestream.polls.sort((a, b) => b.is_active - a.is_active)
        state.appearances = livestream.appearances
        state.verifyPassword = verifyPassword
    },
}
